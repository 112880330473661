import React, {useEffect, useState} from "react";
import Spinner from "../common/Spinner";
import SessionManager from "../../utils/SessionManager";
import {EnvidObjectResult} from "../../fetchapi";
import DiffContent from "./DiffContent";


export interface DiffViewerContainerProps {
  sharedId: string;
  showDifference: boolean;
  customName?: string;
}

function DiffViewerContainer(props: DiffViewerContainerProps): React.ReactElement {

  const [isLoading, setIsLoading] = useState(true);

  const [oldObject, setOldObject] = useState<EnvidObjectResult>();
  const [newObject, setNewObject] = useState<EnvidObjectResult>();

  const ebaseDs = SessionManager.shared().getEbaseDS();

  useEffect(() => {

    ebaseDs.getPublishedVersionComparison(props.sharedId).then(result => {
      setOldObject(result.oldObject);
      setNewObject(result.newObject);
      setIsLoading(false);


    }).catch(err => {
      setIsLoading(false);
    })

  },[])

  return (
    <div>
      {isLoading ? <div><Spinner/></div> :
        <div>
          <DiffContent oldObject={oldObject} newObject={newObject!} showDifference={props.showDifference} customName={props.customName}/>
        </div>}
    </div>
  )


}

export default DiffViewerContainer;
