import React, {ReactElement, useState} from "react";
import EButton from "./EButton";
import DiffModal from "../DiffViewer/DiffModal";

interface CompareButtonProps {
    targetObj: any;
    customName?: string;
}

export const CompareTrigger:React.FC<CompareButtonProps> = (props: CompareButtonProps): ReactElement | null  => {
    const [showDiff, setShowDiff] = useState<boolean>(false);

    let compareButton = <EButton
        className="btn btn-outline-secondary"
        style={{fontSize: '13px'}} role="button" href="#"
        onClick={() => setShowDiff(true)}>
        Compare Versions
    </EButton>;

    let compareVersionsAllowed: boolean =
        // (props.targetObj?.versionDetails?.state && props.targetObj.versionDetails.state === "Working") &&
        (props.targetObj?.deleteState == null);

    return <>
        {compareVersionsAllowed ? compareButton : null}
        <DiffModal
            show={showDiff}
            sharedId={props.targetObj.sharedId}
            onHide={() => setShowDiff(false)}
            customName={props.customName}/>
    </>;
}