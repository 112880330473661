import React from 'react'
import ProjectView from '../../views/ProjectView'
import { connect } from 'react-redux'
import sitedata from '../../utils/sitedata'
import SessionManager from "../../utils/SessionManager";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{

    //console.log("project container mapStateToProps");
    //console.log(state);

    return {
        currentObject: state.projects.currentObject,
        list: state.projects.list,
        siteData:state.session.siteData,
        changes : state.projects.changes,
        isLoading: state.projects.isLoading,
        isStatusLoading:state.projects.isStatusLoading,
        readOnly:(!sitedata.checkWriteAccess(state.session.siteData,"project")),
        project_status: state.projects.project_status,
        dimensions:state.session.dimensions,
    }
};

const selectTab = function(props,tabKey){

    // console.log("-------");
    // console.log("select tab: "+tabKey,props);
    // console.log("select tab params tabname: " +props.params.tabName);
    // console.log("-------");

    SessionManager.shared().goto("/projects/project/"+sitedata.getParams(props).objectId+"/"+tabKey);
};


const editReport = function (props) {
    SessionManager.shared().goto("/report-defn/" + sitedata.getParams(props).objectId+"/summary/0");
};

const editControlsSummaryReport = function (props) {
    SessionManager.shared().goto("/report-controlssummary/" + sitedata.getParams(props).objectId+"/summary/0");
};

const editCompliance = function (props) {
    SessionManager.shared().goto("/compliance/" + sitedata.getParams(props).objectId+'/home/0');
};

const editStakeholder = function (props) {
    SessionManager.shared().goto("/stakeholder/" + sitedata.getParams(props).objectId+"/home/0");
};

const mapDispatchToProps = (dispatch, ownProps) =>
{

    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {

        selectTab: (key) => selectTab(ownProps,key),
        editReport: () => editReport(ownProps),
        editControlsSummaryReport: () => editControlsSummaryReport(ownProps),
        editCompliance: () => editCompliance(ownProps),
        editStakeholder: () => editStakeholder(ownProps),
    }
};


class ProjectTabView extends React.Component{
    render() {


        return  <div>
            {React.cloneElement(<ProjectView/>, this.props)}
        </div>

    }
    componentWillMount()
    {
        // console.log("ProjectTabView component will mount ",this.props);
        // console.log(this.props);
        // console.log(this.props.params.objectId);
        // console.log("-----------------------------------");
        let params = sitedata.getParams(this.props);
        this.props.loadProjectStatus(params.objectId);

    }

};



const ProjectContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectTabView);

export default ProjectContainer
