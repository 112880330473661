import React from 'react';
import EmailTemplateView from "../../views/EmailTemplateView";
import EbaseDS from "../../utils/EbaseDS";
import SessionManager from "../../utils/SessionManager";
import {NotifyTemplateResult} from "../../fetchapi";
import {Spinner} from "react-bootstrap";

interface EmailTemplateContainerState {
    templates: NotifyTemplateResult[];
    isLoading: boolean;
}

interface EmailTemplateContainerProps {

}

class EmailTemplateContainer extends React.Component<EmailTemplateContainerProps, EmailTemplateContainerState> {
    constructor(props: any) {
        super(props);

        this.state = {
            templates: [],
            isLoading: false,
        }
        this.loadEmailTemplates = this.loadEmailTemplates.bind(this);
        this.setEmailTemplates = this.setEmailTemplates.bind(this);
    }

    componentDidMount() {
        this.loadEmailTemplates();
    }

    loadEmailTemplates() {
        const ebaseDs: EbaseDS = SessionManager.shared().getEbaseDS();

        ebaseDs.loadEmailTemplates().then((result: NotifyTemplateResult[]) => {
            this.setState({templates: result});
        }).catch(reason => {
            console.log(reason);
        })
    };

     setEmailTemplates(code: string, template: NotifyTemplateResult) {
        const ebaseDs: EbaseDS = SessionManager.shared().getEbaseDS();
        this.setState({isLoading: true});
        ebaseDs.setEmailTemplates(code, template).then(result => {
            this.setState({isLoading: false});
            this.loadEmailTemplates();
            console.log(result);
        }).catch(reason => {
            this.setState({isLoading: false});
            console.log(reason);
        })
    }


    render() {
        return (
            this.state.isLoading ? <Spinner /> :
            <EmailTemplateView
                templates={this.state.templates}
                setTemplates={this.setEmailTemplates}
            ></EmailTemplateView>
        );
    }
};


export default EmailTemplateContainer