/**
 * Created by lewellyn on 13/5/19.
 */
import React from 'react';
import {
    loadBranchDone, loadBranchStart,
    loadSummaryObjectDone,
    loadSummaryObjectStart,
    loadTreeDone,
    loadTreeStart
} from "../../redux/actions/actions";
import ebase from "../../utils/ajax";
import DataSummaryTreeView from "../../views/DataSummaryTreeView";
import {connect} from "react-redux";
import SessionManager from "../../utils/SessionManager";


/**
 * @param {EbaseAppState} state - The current state.
 */
    const mapStateToProps = (state, ownProps) =>{

    return {

        isLoading: state.summary.isLoading,
        children: state.summary.children,
        objects: state.summary.objects,
        loadState: state.summary.loadState,
        selectedIsLoading:state.summary.selectedIsLoading,
        selectedData:state.summary.selectedData,

    };
};

    // const getParent = (child_id,structure) => {
    //
    //     console.log("get parent for: '"+child_id+"'");
    //     // console.log(structure);
    //
    //     let keys = Object.keys(structure);
    //
    //     for (var i=0; i<keys.length; i++){
    //
    //         let k = keys[i];
    //
    //         let v = structure[k];
    //
    //
    //         if (v.join().includes(child_id)) {
    //             // console.log("k: " + k +" has "+child_id);
    //             //console.log("v: " + v);
    //
    //             let parent = k.split("::").slice(-1).toString();
    //
    //             console.log("parent: '" + parent+"'");
    //             console.log(typeof parent);
    //
    //             console.log("child: '"+child_id+"'");
    //             console.log(typeof child_id);
    //
    //             console.log("parent == child_id: "+ parent === child_id);
    //
    //
    //
    //             if (parent !== child_id){
    //                 return parent;
    //             }
    //         }
    //
    //     }
    //
    //     return null;
    //
    // };

    const mapDispatchToProps = (dispatch, ownProps) =>{
        ownProps.showLoadError = SessionManager.shared().showLoadError;
        return {


            loadSummary : (theSummaryType) => {


                if (theSummaryType === 'impactsummary') {
                    theSummaryType = 'ImpactSummary';
                } else if (theSummaryType === 'regionsummary') {
                    theSummaryType = 'RegionSummary';
                } else if (theSummaryType === 'receptorsummary') {
                    theSummaryType = 'ReceptorSummary';
                } else {
                    theSummaryType = null;
                }

                // console.log("DataSummaryTreeContainer:: load summary - "+theSummaryType);

                dispatch(loadTreeStart());
                ebase.ajax({
                    url:"/ebase/summary/read-summary-datasummary",
                    data:{objectId : null, summaryType:theSummaryType},
                    success:(result) => {
                        if (result.success) {
                            dispatch(loadTreeDone(result.data));
                        } else {
                            ownProps.showLoadError("Error Loading Summary", result);
                        }
                    },
                    error :(result) => {
                        ownProps.showLoadError("Error Loading Summary", result);
                    }

                });
            },

            loadObject : (object,theSummaryType,parent) => {
                if (!object) {
                    return;
                }
                let object_id = object.sharedId;
                let displayed_object_id = (object.displayed_object_id == null ) ? object.sharedId : object.displayed_object_id;

                dispatch(loadSummaryObjectStart(object_id, displayed_object_id));

                //if there's a parent object send object id up as <parent_id>::<object_id>
                //used to get link info
                var objectInfo = displayed_object_id;
                if(parent !== null && parent !== undefined){
                    objectInfo = parent.sharedId + "::"+displayed_object_id;
                }

                ebase.ajax({
                    url:"/ebase/summary/read-summary-object",
                    data:{objectId : objectInfo,summaryType:theSummaryType},
                    success:(result) => {
                        if (result.success) {
                            // console.log("load success: ");
                            // console.log(result);
                            dispatch(loadSummaryObjectDone(displayed_object_id, result.data));
                        } else {
                            ownProps.showLoadError("Error Loading Object", result);
                        }
                    },
                    error :(result) => {
                        ownProps.showLoadError("Error Loading Object", result);
                    }
                });
            },
            loadBranch : (parent,theSummaryType) => {
                dispatch(loadBranchStart(parent));
                ebase.ajax({
                    url:"/ebase/summary/read-summary-datasummary",
                    data:{objectId : parent, summaryType:theSummaryType},
                    success:(result) => {
                        if (result.success) {
                            dispatch(loadBranchDone(parent,result.data));
                        } else {
                            ownProps.showLoadError("Error Loading Summary", result);
                        }
                    },
                    error :(result) => {
                        ownProps.showLoadError("Error Loading Summary", result);
                    }

                });

            }
        };
    };

class DataSmryTreeView extends React.Component{

    render() {

        return (

            <div>
                {React.cloneElement(<DataSummaryTreeView/>,this.props)}
            </div>
        );
    }

    componentWillMount()
    {
        // console.log("DataSmryTreeView:: component will mount");

        let path = this.props.location.pathname.replace("/","");

        this.props.loadSummary(path);


    }

};




const DataSummaryTreeContainer = connect(mapStateToProps, mapDispatchToProps)(DataSmryTreeView);
export default DataSummaryTreeContainer
