/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserEvent } from './UserEvent';
import {
    UserEventFromJSON,
    UserEventFromJSONTyped,
    UserEventToJSON,
} from './UserEvent';

/**
 * 
 * @export
 * @interface VersionDetailsSmallResult
 */
export interface VersionDetailsSmallResult {
    /**
     * 
     * @type {string}
     * @memberof VersionDetailsSmallResult
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionDetailsSmallResult
     */
    datasetId?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionDetailsSmallResult
     */
    version?: string;
    /**
     * 
     * @type {number}
     * @memberof VersionDetailsSmallResult
     */
    internalVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof VersionDetailsSmallResult
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionDetailsSmallResult
     */
    state?: VersionDetailsSmallResultStateEnum;
    /**
     * 
     * @type {UserEvent}
     * @memberof VersionDetailsSmallResult
     */
    userEvent?: UserEvent;
    /**
     * 
     * @type {number}
     * @memberof VersionDetailsSmallResult
     */
    numPublishedObjects?: number;
}


/**
 * @export
 */
export const VersionDetailsSmallResultStateEnum = {
    Working: 'Working',
    Published: 'Published',
    Historical: 'Historical',
    Deleted: 'Deleted'
} as const;
export type VersionDetailsSmallResultStateEnum = typeof VersionDetailsSmallResultStateEnum[keyof typeof VersionDetailsSmallResultStateEnum];


/**
 * Check if a given object implements the VersionDetailsSmallResult interface.
 */
export function instanceOfVersionDetailsSmallResult(value: object): value is VersionDetailsSmallResult {
    return true;
}

export function VersionDetailsSmallResultFromJSON(json: any): VersionDetailsSmallResult {
    return VersionDetailsSmallResultFromJSONTyped(json, false);
}

export function VersionDetailsSmallResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionDetailsSmallResult {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'datasetId': json['datasetId'] == null ? undefined : json['datasetId'],
        'version': json['version'] == null ? undefined : json['version'],
        'internalVersion': json['internalVersion'] == null ? undefined : json['internalVersion'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'state': json['state'] == null ? undefined : json['state'],
        'userEvent': json['userEvent'] == null ? undefined : UserEventFromJSON(json['userEvent']),
        'numPublishedObjects': json['numPublishedObjects'] == null ? undefined : json['numPublishedObjects'],
    };
}

export function VersionDetailsSmallResultToJSON(value?: VersionDetailsSmallResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'datasetId': value['datasetId'],
        'version': value['version'],
        'internalVersion': value['internalVersion'],
        'notes': value['notes'],
        'state': value['state'],
        'userEvent': UserEventToJSON(value['userEvent']),
        'numPublishedObjects': value['numPublishedObjects'],
    };
}

