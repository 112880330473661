/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnvidObjectResult } from './EnvidObjectResult';
import {
    EnvidObjectResultFromJSON,
    EnvidObjectResultFromJSONTyped,
    EnvidObjectResultToJSON,
} from './EnvidObjectResult';

/**
 * 
 * @export
 * @interface CollatedObjectSummary
 */
export interface CollatedObjectSummary {
    /**
     * 
     * @type {EnvidObjectResult}
     * @memberof CollatedObjectSummary
     */
    oldObject?: EnvidObjectResult;
    /**
     * 
     * @type {EnvidObjectResult}
     * @memberof CollatedObjectSummary
     */
    newObject?: EnvidObjectResult;
}

/**
 * Check if a given object implements the CollatedObjectSummary interface.
 */
export function instanceOfCollatedObjectSummary(value: object): value is CollatedObjectSummary {
    return true;
}

export function CollatedObjectSummaryFromJSON(json: any): CollatedObjectSummary {
    return CollatedObjectSummaryFromJSONTyped(json, false);
}

export function CollatedObjectSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollatedObjectSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'oldObject': json['oldObject'] == null ? undefined : EnvidObjectResultFromJSON(json['oldObject']),
        'newObject': json['newObject'] == null ? undefined : EnvidObjectResultFromJSON(json['newObject']),
    };
}

export function CollatedObjectSummaryToJSON(value?: CollatedObjectSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'oldObject': EnvidObjectResultToJSON(value['oldObject']),
        'newObject': EnvidObjectResultToJSON(value['newObject']),
    };
}

