/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ReportTaskResultObjectLists } from './ReportTaskResultObjectLists';
import {
    ReportTaskResultObjectListsFromJSON,
    ReportTaskResultObjectListsFromJSONTyped,
    ReportTaskResultObjectListsToJSON,
} from './ReportTaskResultObjectLists';

/**
 * 
 * @export
 * @interface ProjectObjectsResult
 */
export interface ProjectObjectsResult {
    /**
     * 
     * @type {ReportTaskResultObjectLists}
     * @memberof ProjectObjectsResult
     */
    objectLists?: ReportTaskResultObjectLists;
}

/**
 * Check if a given object implements the ProjectObjectsResult interface.
 */
export function instanceOfProjectObjectsResult(value: object): value is ProjectObjectsResult {
    return true;
}

export function ProjectObjectsResultFromJSON(json: any): ProjectObjectsResult {
    return ProjectObjectsResultFromJSONTyped(json, false);
}

export function ProjectObjectsResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectObjectsResult {
    if (json == null) {
        return json;
    }
    return {
        
        'objectLists': json['objectLists'] == null ? undefined : ReportTaskResultObjectListsFromJSON(json['objectLists']),
    };
}

export function ProjectObjectsResultToJSON(value?: ProjectObjectsResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'objectLists': ReportTaskResultObjectListsToJSON(value['objectLists']),
    };
}

