import envid from "../../utils/envid";

/**
 * @param {SearchReducerState} state - The current state.
 * @param {DispatchAction} action - The action object.
 * @returns {SearchReducerState} The new state after applying the action.
 */
export default function search(state = {
                                   search: {term: "", type: "", page: 0, max: 50, publish: []},
                                   results: {status: "Most Recently Updated", data: null, more: true, searching: false},
                                   savedStatus: "unchanged",
                                   currentObject: null,
                                    changeTime:null,
                                    linkInfo:null,
                               },
                               action) {

    switch (action.type) {
        case "SEARCH_RESET": {
            return {...state, search: {term: "", type: "", page: 0, max: 50, publish: []}, results: {status: "Most Recently Updated", data: null, more: true, searching: false}};
        }

        case "SEARCH_SET_CRITERIA": {
            return {...state, search: action.data};
        }

        case "SEARCH_START": {

            let nextResult = state.results;
            nextResult.searching = true;

            return {...state,search: action.data, results: nextResult};
        }

        case "SEARCH_UPDATE_CRITERIA_FIELD": {
            let crit = Object.assign({}, state.search);
            crit[action.data.field] = action.data.value;
            return {...state, search: crit, test: action.data.value};
        }

        case "SEARCH_UPDATE_RESULTS": {
            // console.log("SEARCH_UPDATE_RESULTS:: ", action);
            let finalTotal = action.data.total;
            let t = action.t;

            let searchString;
            const data = action.data.results;
            const size = data.length;

            let search = state.search;

            let searchResults = state.results.data;

            if (search.page === 0) {
                searchResults = data;
            } else {
                const startIdx = search.page * search.max;
                if (!searchResults) {
                    searchResults = [];
                }
                searchResults = searchResults.slice(0, startIdx).concat(data);
            }

            const more = data.length > 0;
            searchString = "Showing ";
            if (!more) {
                searchString += searchResults.length + " ";
            } else {
                searchString += searchResults.length +" of " + finalTotal + " ";
            }

            if (search.type) {
                searchString += searchResults.length === 1 ?
                    envid.getObjectName(search.type) :
                    envid.getObjectPluralName(search.type);

                if (search.term != null && search.term.length > 0) {
                    searchString += " " + t('with') + ": '" + search.term + "'";
                }
            } else if (search.term != null && search.term.length > 0) {
                searchString += t("Object" + (size === 1 ? '' : 's')) + " " + t('with') + ": '" + search.term + "'";
            } else {
                searchString += t("Most Recently Updated");
            }
            if (search.publish != null && search.publish.length > 0){
                let pubInfo = search.publish.join(",");
                if (search.publish.length === 1){
                    pubInfo = " " + t('with') + " " + t("Publish State")+" "+pubInfo;
                }else{
                    pubInfo = " " + t('with') + " " + t("Publish States")+" "+pubInfo;
                }
                searchString = searchString + pubInfo;
            }

            return {...state, results: {status: searchString, data: searchResults, more, searching: false}}

        }

        case "SEARCH_SET_CURRENT_OBJECT":
        {
            return {...state,currentObject: action.data,changeTime: null,savedStatus: "unchanged",linkInfo: null}
        }

        case "UPDATE_SAVED_STATUS":
        {
            return {...state,savedStatus: action.data}
        }

        case "UPDATE_OBJECT":
        {
            let object = action.data.object;
            let moreState = action.data.moreState;
            if (state.currentObject != null && state.currentObject.object != null)
            {
                if (state.currentObject.object.sharedId !== object.object.sharedId)
                {
                    console.log("Not updating object because it is not the same object");
                    //return;
                }
            }
            {
                let more = (moreState==null) ? {} : moreState;

                return {...state,currentObject: object,changeTime: new Date().getTime(),more};
            }
        }

        // action data will contain a EnvidObjectResult.
        // find it in the results list update it.
        case "SEARCH_UPDATE_CHECKOUT_CHANGED":
        {
            if (!!state.results.data){

                const obj = action.data.object;
                const next = [...state.results.data];
                next.forEach((item) => {
                    if (item.sharedId === obj.sharedId){
                        item.checkoutUserId = obj.checkoutUserId;
                        item.checkoutUsername = obj.checkoutUserName;
                        item.checkoutUserDisplayName = obj.checkoutUserDisplayName;
                    }
                });

                const nextResults = {...state.results, data: next};
                if (!!state.currentObject && state.currentObject.object.sharedId === obj.sharedId){
                    const nextCurrent = {...state.currentObject};
                    nextCurrent.object.checkoutUserId = obj.checkoutUserId;
                    nextCurrent.object.checkoutUsername = obj.checkoutUserName;
                    nextCurrent.object.checkoutUserDisplayName = obj.checkoutUserDisplayName;

                    return {...state, results: nextResults, currentObject : nextCurrent};
                }

                return {...state, results: {...state.results, data: next}};
            }
            return state;
        }

        default:{}

    }

    return state;
}
