import React from 'react';
import {Table, Modal,ModalBody,ModalHeader,ModalTitle,ModalFooter} from 'react-bootstrap'
import Spinner from "../components/common/Spinner"
import EButton from "../components/common/EButton"
import {withTranslation} from "react-i18next";


class DataReferences extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {showModal:false, addingNew:true, refString: '', searchString: ''};

        this.onRemoveClick = this.onRemoveClick.bind(this);
        this.onAddClick = this.onAddClick.bind(this); //add new or add existing
        this.onCloseClick = this.onCloseClick.bind(this); //close in modal
        this.onAddReference = this.onAddReference.bind(this);
        this.referenceTextChanged = this.referenceTextChanged.bind(this);
        this.filterTextChanged = this.filterTextChanged.bind(this);
        // console.log(' DataReferences::', this.props);
    }

    onAddClick(doNewAdd){
        if(!doNewAdd){
            this.props.doGetAllReferences();
        }
        this.setState({showModal:true, addingNew:doNewAdd});
    }

    onAddReference(ref_id, reference){
        // console.log("referencesview:: add reference: "+reference);
        // console.log(' onAddReference', ref_id, reference);


        this.props.doAdd(ref_id,reference);

        this.setState({showModal:false, refString:''});


    }

    onCloseClick(){
        this.setState({showModal:false});
    }

    onRemoveClick(key){
        // console.log("remove: "+key);
        this.props.doRemove(key);
    }

    referenceTextChanged(event){
        this.setState({refString:event.target.value});
    }

    filterTextChanged(event){
        this.props.doFilterReferences(event.target.value);
    }


    render() {

        const {t} = this.props;

        if (this.props.loadingReferences) {
            return (
                <Spinner />
            )
        }

        // console.log('  data references ', this.props);
        // console.log("allReferences: ",this.props.allReferences);

         // console.log("currentObject.object.references: "+JSON.stringify(this.props.currentObject.object.references));
         // console.log("currentObject.references: "+JSON.stringify(this.props.currentObject.references));

        const refids = this.props.refIds;
        const refs = this.props.objectReferences;


        //create an array of {refid: reference string} objects
        var values = [];
        for(var i=0; i<refids.length;i++){
            let content = refs[refids[i]]?.reference;
            if(content != null) {
                var r = {};
                r[refids[i]] = refs[refids[i]].reference;
                values.push(r);
            }else{
                //console.log("DataReferences:: null reference name " + refids[i], refs[refids[i]])
            }
        }


        //sort that array alphabetically by the reference string
        var sorted = values.sort(function (a, b) {
            var keya = Object.keys(a)[0];
            var keyb = Object.keys(b)[0];

            // console.log("keya: "+keya + ' ' + a[keya]);
            // console.log("keyb: "+keyb + ' ' + b[keyb]);

            return a[keya].localeCompare(b[keyb]);
        });

        //get the reference ids from the sorted array of {refid: reference string} objects
        var sortedRefids = [];
        for(var i=0; i<sorted.length;i++){
            var key = Object.keys(sorted[i])[0];
            sortedRefids.push(key);
        }

        let rows = <tr><td>{t("No References")}</td></tr>;

        if(sortedRefids.length > 0) {

             rows = sortedRefids.map((refid) =>
                <tr key={refid}>
                    <td>{refs[refid].reference}</td>
                    <td className="project-actions">
                        <EButton isTable={true} disabled={this.props.disabled} type="remove" onClick={()=>this.onRemoveClick(refid)}>{t("Remove")}</EButton>
                    </td>
                </tr>
            );
        }


        //<td className="project-status"><span className={"label label-" + (res.published?"primary":"warning")}>{(res.published?"Published":"Unpublished")}</span></td>

        let modalBody = <div></div>;
        let searchInput = <></>
        //the filter input was shelved. Were thinking about changing it
        //the add reference ui to use different component instead of modal popup.
        // let filterInput = (
        //     <div className="input-group" style={{width:'80%',paddingBottom:10, align:'center'}}>
        //         <input type="text" placeholder="Filter" className="input form-control" value={this.props.filterString} onChange={this.filterTextChanged}/>
        //     </div>
        //
        // );

        if(this.state.addingNew) {
            modalBody = (
                <div className="input-group">
                    <input type="text" placeholder={t("Reference")} className="input form-control" value={this.state.refString} onChange={this.referenceTextChanged}/>
                    <span className="input-group-btn">
                        <EButton disabled={this.state.refString == null || this.state.refString.length === 0 || this.props.disabled} type="add" onClick={()=>this.onAddReference(null,this.state.refString)}>{t("Add")}</EButton>
                    </span>
                </div>

            );

        }else{

            if(this.props.loadingReferences){
                modalBody = <Spinner/>;
            }else {

                let allRefs = this.props.allReferences;
                if(this.props.filterString != null && this.props.filterString !== ""){
                    allRefs = this.props.filteredReferences;
                }
                //console.log("allrefs: "+ JSON.stringify(allRefs));



                if (allRefs.length === 0) {
                    modalBody = <Table>
                        <tbody>
                        <tr>
                            <td>{t("No references")}</td>
                        </tr>
                        </tbody>
                    </Table>
                } else {

                    let uniqueRefs = [];

                    allRefs.map((ref) => {
                        if (!refids.includes(ref.sharedId)) {
                            uniqueRefs.push(ref);
                        }

                    });

                     //console.log("uniqueRefs: "+ JSON.stringify(uniqueRefs[0],null,"  ")+ " "+uniqueRefs.length);

                    uniqueRefs.sort(function (a, b) {
                        //var keya = Object.keys(a)[0];
                        //var keyb = Object.keys(b)[0];

                        // console.log("keya: "+keya);
                        // console.log("a[name]: " + a["name"]);
                        // console.log("keyb: "+keyb);

                        return a["name"].toLowerCase().localeCompare(b["name"].toLowerCase());
                    })
                    uniqueRefs = uniqueRefs.filter((row) => {
                            return row["name"].includes(this.state.searchString);
                        }
                    );

                     //console.log("uniqueRefsSorted: "+ JSON.stringify(uniqueRefsSorted,null,"  "));
                    searchInput = (<div className="input-group w-auto">
                            <input
                            className="input form-control w-auto"
                            type="text"
                            placeholder="Search..."
                            value={this.state.searchString}
                            onChange={(e) => this.setState({searchString: e.target.value})}
                            />
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => this.setState({searchString: ""})}>
                                {t('Reset')}
                            </button>
                    </div>)

                    if (uniqueRefs.length === 0) {

                        modalBody = <Table>
                            <tbody>
                            <tr>
                                <td>{t("No references")}</td>
                            </tr>
                            </tbody>
                        </Table>
                    } else {

                        modalBody = (
                            <Table className="table">
                                <tbody>
                                {uniqueRefs.map((ref) =>
                                    <tr key={ref.sharedId}>
                                    <td>{ref.name}</td>
                                        <td className="project-actions">
                                            <EButton type="add"
                                                     disabled={this.props.disabled}
                                                     onClick={() => this.onAddReference(ref.sharedId, ref.name)}>{t("Add")}</EButton>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        );
                    }
                }
            }


        }

        let modal = (

            <Modal show={this.state.showModal} onHide={this.onCloseClick} size="xl">
                <ModalHeader closeButton className="pe-4">
                    <ModalTitle className="d-flex justify-content-between w-100 p-w-md">
                        <div className="eb-div-ctr">
                            {this.state.addingNew ? t("Add New Reference") : t("Add Existing Reference")}
                        </div>
                        {searchInput}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {modalBody}
                </ModalBody>
                <ModalFooter>
                    <EButton isTable={true} type="cancel" onClick={this.onCloseClick}>{t("Cancel")}</EButton>
                </ModalFooter>

            </Modal>

        );


        return (
            <div className="ibox">
                {modal}
                <div className="ibox-title">
                    <div className="row">
                        <div className="col-sm-4">
                            <EButton type="add" disabled={this.props.disabled} onClick={()=>this.onAddClick(false)}>
                                {t("Add Existing")}
                            </EButton>
                            &nbsp;
                            <EButton type="add" disabled={this.props.disabled} onClick={()=>this.onAddClick(true)}>
                                {t("Add New")}
                            </EButton>
                        </div>
                    </div>
                </div>
                <div className="ibox-content" style={this.props.style}>
                    <div className="row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8">
                            <Table className="table">
                                <tbody>
                                {rows}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(DataReferences)
