/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EbaseResultMapProjectActionTypeProjectActionStatus
 */
export interface EbaseResultMapProjectActionTypeProjectActionStatus {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultMapProjectActionTypeProjectActionStatus
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultMapProjectActionTypeProjectActionStatus
     */
    error?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EbaseResultMapProjectActionTypeProjectActionStatus
     */
    data?: { [key: string]: string; };
}


/**
 * @export
 */
export const EbaseResultMapProjectActionTypeProjectActionStatusDataEnum = {
    New: 'NEW',
    Inprogress: 'INPROGRESS',
    Complete: 'COMPLETE',
    Unavailable: 'UNAVAILABLE'
} as const;
export type EbaseResultMapProjectActionTypeProjectActionStatusDataEnum = typeof EbaseResultMapProjectActionTypeProjectActionStatusDataEnum[keyof typeof EbaseResultMapProjectActionTypeProjectActionStatusDataEnum];


/**
 * Check if a given object implements the EbaseResultMapProjectActionTypeProjectActionStatus interface.
 */
export function instanceOfEbaseResultMapProjectActionTypeProjectActionStatus(value: object): value is EbaseResultMapProjectActionTypeProjectActionStatus {
    return true;
}

export function EbaseResultMapProjectActionTypeProjectActionStatusFromJSON(json: any): EbaseResultMapProjectActionTypeProjectActionStatus {
    return EbaseResultMapProjectActionTypeProjectActionStatusFromJSONTyped(json, false);
}

export function EbaseResultMapProjectActionTypeProjectActionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultMapProjectActionTypeProjectActionStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : json['data'],
    };
}

export function EbaseResultMapProjectActionTypeProjectActionStatusToJSON(value?: EbaseResultMapProjectActionTypeProjectActionStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': value['data'],
    };
}

