/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EbaseResultString
 */
export interface EbaseResultString {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultString
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultString
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultString
     */
    data?: string;
}

/**
 * Check if a given object implements the EbaseResultString interface.
 */
export function instanceOfEbaseResultString(value: object): value is EbaseResultString {
    return true;
}

export function EbaseResultStringFromJSON(json: any): EbaseResultString {
    return EbaseResultStringFromJSONTyped(json, false);
}

export function EbaseResultStringFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultString {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : json['data'],
    };
}

export function EbaseResultStringToJSON(value?: EbaseResultString | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': value['data'],
    };
}

