import React, {ReactNode} from "react";
import {withTranslation} from "react-i18next";
import EbasePanel from "../components/common/EbasePanel";
import EBCommonTable, {ColumnDef} from "../components/common/EBCommonTable";
import {AuditEventPublic} from "../fetchapi";
import envid from "../utils/envid";

interface LibraryAuditViewProps {
    logs: AuditEventPublic[];
}


class LibraryAuditView extends React.Component<LibraryAuditViewProps> {

    constructor(props: any) {
        super(props);
    }

    renderTableHeader (defs: ColumnDef[]): React.ReactElement {
        return (
            <tr>
                {defs.map((def, index) => (
                    <th style={(['use', 'default', 'builtIn'].includes(def.key) ? {textAlign: 'center'} : {})}
                        key={index}>{def.display}</th>
                ))}
            </tr>
        );
    }

    renderTemplateRow(log: AuditEventPublic): ReactNode {
        return (
            <tr key={log.id}>
                <td>{log.userEvent?.firstName + " " + log.userEvent?.lastName}</td>
                <td><div className="font-monospace text-nowrap text">{log.userEvent?.eventDate?.toLocaleString()}</div></td>
                <td>{log.eventType && envid.getLogIcon(log.eventType)}&nbsp;{log.description}</td>
                <td>{log.version}</td>
            </tr>
        )
    }

    render() {
        const headings: ColumnDef[] = [
            {key: 'user', display: 'User'},
            {key: 'date', display: 'Date'},
            {key: 'event', display: 'Event'},
            {key: 'version', display: 'Library Version'},
        ];

        return (
            <EBCommonTable
                rowData={this.props.logs ?? []}
                columnDefinitions={headings}
                renderHeader={this.renderTableHeader}
                renderRow={(item: AuditEventPublic, defs: ColumnDef[]) => this.renderTemplateRow(item)}
            ></EBCommonTable>);
    }
}

export default withTranslation()(LibraryAuditView)
