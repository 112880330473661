import React from 'react';
import auth from "../../utils/auth"
import Login from "./Login"
import LiteLogin from "./LiteLogin"
import { connect } from 'react-redux';
import SessionManager from "../../utils/SessionManager";

class LoginContainer extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {isLoaded:false, error:null};
        this.doneCheckSession = this.doneCheckSession.bind(this);
        this.doneLogin = this.doneLogin.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.doneSessionAccess = this.doneSessionAccess.bind(this);
    }

    doneCheckSession(user)
    {
        if (user == null) {
            this.setState({isLoaded: true});
        } else {
            this.props.onLoginSuccess(user);
        }
    }

    doneLogin(initData, error)
    {
        if (initData == null)
        {
            console.log("Login Finished1 - " + error)
            this.setState({error:error});
        }
        else
        {
            // console.log("LoginContainer:: Login Finished");
            // console.log(initData);

            this.props.onLoginSuccess(initData);
        }
    }

    doLogin(email, password)
    {
        this.setState({error:null});
        SessionManager.shared().authenticate(email,password);

    }


    doneSessionAccess(initData){

        // console.log("Done session Access");
        // console.log(initData);
        // console.log("---");
        if (initData == null)
        {
            console.log("Login Finished1 - Not Initialised");
            this.setState({error:"Not Initialised"});
        }
        else {
            //this.props.onLoginSuccess(initData);
            this.props.onHandleAccessSuccess(initData);
        }
    }

    componentDidMount() {
        //auth.checkSession(this.doneCheckSession);
    }

    render()
    {
        // console.log("LoginContainer:: render",this.props);

        if (auth.getIsLite()){

            return React.cloneElement(<LiteLogin onLogin={this.doLogin} error={this.state.error}/>, this.props);

            // return <LiteLogin onLogin={this.doLogin} onLoginSuccess={this.props.onLoginSuccess} error={this.state.error}/>
        }

        return (

            React.cloneElement(<Login onLogin={this.doLogin} error={this.state.error}/>, this.props)

            // <Login onLogin={this.doLogin} onLoginSuccess={this.props.onLoginSuccess} error={this.state.error}/>
        );
    }

    // render()
    // {
    //     let isLoaded = this.state.isLoaded;
    //     if (this.props.siteData != null)
    //     {
    //             return (
    //                 <div style={{height:"100%", overflow:"hidden"}}>
    //                 {this.props.children}
    //                 </div>
    //                 )
    //     }
    //     else if (!isLoaded && this.props.siteData != null)
    //     {
    //         return this.props.loadingComponent;
    //     }
    //     else
    //     {
    //         if (this.props.isLite){
    //             return <LiteLogin onLogin={this.doLogin} onLoginSuccess={this.props.onLoginSuccess} error={this.state.error}/>
    //         }
    //
    //         return (
    //             <Login onLogin={this.doLogin} onLoginSuccess={this.props.onLoginSuccess} error={this.state.error}/>
    //         )
    //     }
    // }

}

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) => {

    return {
        isLoading: state.session.isLoading,
        isAuthenticated: state.session.isAuthenticated,
        error: state.session.error,
        siteData:state.session.siteData,
    }

};

const mapDispatchToProps = (dispatch, ownProps) => {

    return {
        doLogin: (username, password) => {
            // console.log("LoginContainer:: doLogin:");
            SessionManager.shared().authenticate(username, password);
        }
    };
};



export default connect(mapStateToProps,mapDispatchToProps)(LoginContainer);
