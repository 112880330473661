/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvidLink
 */
export interface EnvidLink {
    /**
     * 
     * @type {string}
     * @memberof EnvidLink
     */
    leftType?: EnvidLinkLeftTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvidLink
     */
    rightType?: EnvidLinkRightTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvidLink
     */
    leftObjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidLink
     */
    rightObjectId?: string;
}


/**
 * @export
 */
export const EnvidLinkLeftTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type EnvidLinkLeftTypeEnum = typeof EnvidLinkLeftTypeEnum[keyof typeof EnvidLinkLeftTypeEnum];

/**
 * @export
 */
export const EnvidLinkRightTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type EnvidLinkRightTypeEnum = typeof EnvidLinkRightTypeEnum[keyof typeof EnvidLinkRightTypeEnum];


/**
 * Check if a given object implements the EnvidLink interface.
 */
export function instanceOfEnvidLink(value: object): value is EnvidLink {
    return true;
}

export function EnvidLinkFromJSON(json: any): EnvidLink {
    return EnvidLinkFromJSONTyped(json, false);
}

export function EnvidLinkFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvidLink {
    if (json == null) {
        return json;
    }
    return {
        
        'leftType': json['left_type'] == null ? undefined : json['left_type'],
        'rightType': json['right_type'] == null ? undefined : json['right_type'],
        'leftObjectId': json['left_object_id'] == null ? undefined : json['left_object_id'],
        'rightObjectId': json['right_object_id'] == null ? undefined : json['right_object_id'],
    };
}

export function EnvidLinkToJSON(value?: EnvidLink | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'left_type': value['leftType'],
        'right_type': value['rightType'],
        'left_object_id': value['leftObjectId'],
        'right_object_id': value['rightObjectId'],
    };
}

