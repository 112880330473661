/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnvidObjectPublic } from './EnvidObjectPublic';
import {
    EnvidObjectPublicFromJSON,
    EnvidObjectPublicFromJSONTyped,
    EnvidObjectPublicToJSON,
} from './EnvidObjectPublic';
import type { ReferenceResult } from './ReferenceResult';
import {
    ReferenceResultFromJSON,
    ReferenceResultFromJSONTyped,
    ReferenceResultToJSON,
} from './ReferenceResult';
import type { EnvidObjectSmallResult } from './EnvidObjectSmallResult';
import {
    EnvidObjectSmallResultFromJSON,
    EnvidObjectSmallResultFromJSONTyped,
    EnvidObjectSmallResultToJSON,
} from './EnvidObjectSmallResult';
import type { UserResult } from './UserResult';
import {
    UserResultFromJSON,
    UserResultFromJSONTyped,
    UserResultToJSON,
} from './UserResult';
import type { ReportTaskResultObjectLists } from './ReportTaskResultObjectLists';
import {
    ReportTaskResultObjectListsFromJSON,
    ReportTaskResultObjectListsFromJSONTyped,
    ReportTaskResultObjectListsToJSON,
} from './ReportTaskResultObjectLists';

/**
 * 
 * @export
 * @interface EnvidObjectResult
 */
export interface EnvidObjectResult {
    /**
     * 
     * @type {EnvidObjectPublic}
     * @memberof EnvidObjectResult
     */
    object?: EnvidObjectPublic;
    /**
     * 
     * @type {UserResult}
     * @memberof EnvidObjectResult
     */
    author?: UserResult;
    /**
     * 
     * @type {UserResult}
     * @memberof EnvidObjectResult
     */
    lastUpdateUser?: UserResult;
    /**
     * 
     * @type {{ [key: string]: ReferenceResult; }}
     * @memberof EnvidObjectResult
     */
    references?: { [key: string]: ReferenceResult; };
    /**
     * 
     * @type {ReportTaskResultObjectLists}
     * @memberof EnvidObjectResult
     */
    objectLists?: ReportTaskResultObjectLists;
    /**
     * 
     * @type {Array<EnvidObjectSmallResult>}
     * @memberof EnvidObjectResult
     */
    receptors?: Array<EnvidObjectSmallResult>;
}

/**
 * Check if a given object implements the EnvidObjectResult interface.
 */
export function instanceOfEnvidObjectResult(value: object): value is EnvidObjectResult {
    return true;
}

export function EnvidObjectResultFromJSON(json: any): EnvidObjectResult {
    return EnvidObjectResultFromJSONTyped(json, false);
}

export function EnvidObjectResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvidObjectResult {
    if (json == null) {
        return json;
    }
    return {
        
        'object': json['object'] == null ? undefined : EnvidObjectPublicFromJSON(json['object']),
        'author': json['author'] == null ? undefined : UserResultFromJSON(json['author']),
        'lastUpdateUser': json['lastUpdateUser'] == null ? undefined : UserResultFromJSON(json['lastUpdateUser']),
        'references': json['references'] == null ? undefined : (mapValues(json['references'], ReferenceResultFromJSON)),
        'objectLists': json['objectLists'] == null ? undefined : ReportTaskResultObjectListsFromJSON(json['objectLists']),
        'receptors': json['receptors'] == null ? undefined : ((json['receptors'] as Array<any>).map(EnvidObjectSmallResultFromJSON)),
    };
}

export function EnvidObjectResultToJSON(value?: EnvidObjectResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object': EnvidObjectPublicToJSON(value['object']),
        'author': UserResultToJSON(value['author']),
        'lastUpdateUser': UserResultToJSON(value['lastUpdateUser']),
        'references': value['references'] == null ? undefined : (mapValues(value['references'], ReferenceResultToJSON)),
        'objectLists': ReportTaskResultObjectListsToJSON(value['objectLists']),
        'receptors': value['receptors'] == null ? undefined : ((value['receptors'] as Array<any>).map(EnvidObjectSmallResultToJSON)),
    };
}

