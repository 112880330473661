import React from 'react';
// import ReactDOM from 'react-dom';
import AppContainer from './components/layouts/AppContainer'
import metismenu from 'metismenu';
import {createRoot} from "react-dom/client";

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/scss/bootstrap.scss'
// import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/font-awesome/css/font-awesome.css'
import './../node_modules/@fortawesome/fontawesome-svg-core/styles.css'
import '@fortawesome/fontawesome-svg-core/styles.css';
import './../node_modules/animate.css/animate.min.css'
import './styles/style.scss'
import './styles/ebase_editor-theme.css'
import 'froala-editor/css/themes/royal.min.css'
import "froala-editor/js/plugins/image.min.js"
import "froala-editor/js/plugins/align.min.js"
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/js/plugins/inline_class.min.js'
import './i18n';

// ReactDOM.render(
//     <AppContainer/>,
//     document.getElementById('root')
// );

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<AppContainer/>);
