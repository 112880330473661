import auth from '../../utils/auth'



const initDimensions = function(){
    var dim = {window: {width:0, height: 0}};
    dim.footerHeight = 39;
    dim.topheaderHeight = 62;
    if(auth.getIsLite() === true){
        dim.topheaderHeight = 86;
    }
    dim.headingHeight = 95;
    dim.extras = {width: dim.window.width, height:dim.footerHeight+dim.topheaderHeight+dim.headingHeight};

    return dim;
}

/**
 * @typedef {Object} WindowDimensions
 * @property {number} width - The width of the window.
 * @property {number} height - The height of the window.
 */
/**
 * @typedef {Object} DimExtras
 * @property {number} width - The width based on the window width.
 * @property {number} height - The sum of footerHeight, topheaderHeight, and headingHeight.
 */
/**
 * @typedef {Object} Dimensions
 * @property {WindowDimensions} window - The dimensions of the window.
 * @property {number} footerHeight - The height of the footer.
 * @property {number} topheaderHeight - The height of the top header.
 * @property {number} headingHeight - The height of the heading.
 * @property {DimExtras} extras - Additional dimensions derived from other values.
 */
/**
 * @typedef {Object} SessionState
 * @property {boolean} isLoading - Indicates if the operation is loading.
 * @property {boolean} isAuthenticated - Is the user authenticated.
 * @property {SiteDataResult} siteData - site data details including the user
 * @property {Dimensions} dimensions - dimension properties
 * @property {boolean} retrying - are we retrying a save.
 * @property {[*]} saveErrors - array of save errors
 * @property {string | Error} error - An error message or object
 */

/**
 * @typedef {Object} Action
 * @property {string} type - The action type.
 * @property {*} [data] - Optional data associated with the action.
 */

/**
 * @param {SessionState} state - The current state.
 * @param {Action} action - The action object.
 * @returns {SessionState} The new state after applying the action.
 */

export default function session(
    state = {
        isLoading:false,
        isAuthenticated:false,
        siteData:null,
        dimensions: initDimensions(),
        retrying: false,
        saveErrors:[],
        error:null,
    }, action){

    switch (action.type) {
        case 'AUTH_START':
            return {
                ...state,
                isAuthenticated: false,
                isLoading: true,
                siteData: null,
                error: null,
            };

        case "AUTH_SUCCESS":{
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                siteData: action.data,
                error: null
            }
        }
        case "AUTH_ERROR":{
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                error: action.data,
            }
        }
        case "AUTH_LOGOUT":{
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                siteData: null
            }
        }

        case 'CHECK_SESSION_START':{
            return {
                ...state,
                isLoading: true,
            }
        }

        case 'CHECK_SESSION_SUCCESS': {
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                siteData: action.data
            }
        }

        case 'CHECK_SESSION_NO_SUCCESS':{
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                siteData: null,
            }
        }

        case 'UPDATE_SESSION_SITEDATA':{
            return {
                ...state,
                siteData: action.data,
            }
        }

        case "UPDATE_DIMENSIONS":{
            return {
                ...state,
                dimensions: action.data,
            }
        }

        case "UPDATE_RETRY_STATE":{

            let retry = action.data.retrying;
            let errors = action.data.errors;

            if (retry != null && errors == null){
                return {
                    ...state,
                    retrying: retry,
                }
            }
            if (retry != null && errors != null){
                return {
                    ...state,
                    retrying: retry,
                    saveErrors: errors,
                }
            }
            if (retry == null && errors != null){
                return{
                    ...state,
                    saveErrors: errors,
                }
            }

            return state;
        }

        case "UPDATE_LIBRARY_OWNER_START":{
            return {
                ...state,
                isLoading: true,
            }
        }

        case "UPDATE_LIBRARY_OWNER_DONE":{
            return {
                ...state,
                siteData: {...state.siteData, dataset:action.data},
                isLoading: false,
            }
        }

        default:{}

    }

    return state;
}
