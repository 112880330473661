/**
 * Created by lewellyn on 28/9/18.
 */

import ebase from '../../utils/ajax'
import ClientManageView from '../../views/ClientManageView'
import { connect } from 'react-redux'
import SessionManager from "../../utils/SessionManager";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{


    return {
        isLoading:state.clients.isLoading,
        updateDone:state.clients.updateDone,
        siteData:state.session.siteData,
        dimensions:state.session.dimensions,
    }
};


const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {

        cancelClick : () => {
            SessionManager.shared().checkSession();
            dispatch({type:"UPDATE_CLIENT_RESET"});
            SessionManager.shared().goto("/libraries");
        },

        updateClient : (client,libraries,existing) => {

            // console.log("update client");
            // console.log(client);
            // console.log(libraries);
            // console.log(existing);

            dispatch({type:"UPDATE_CLIENT_START"});

            let data = new FormData();

            // update the libraries, set the lastUpdateTime
            console.log("------------ Libraries: ", libraries);

            for (let i=0; libraries != null && i<libraries.length; ++i)
            {
                libraries[i].lastUpdateTime = new Date().getTime();
            }

            let request = {
                clientId:client.clientId,
                client_name:client.name,
                description:client.desc,
                libraries:libraries,
                existingLibraries:existing
            };

            if(client.logoFile !== null && client.logoFile !== undefined) {
                data.set("logo", client.logoFile);
            }
            data.set("defaultLogo",client.defaultLogo);

            data.set("request",JSON.stringify(request));


            ebase.ajax({
                url: "/ebase/core/write-system-updateclient",
                data: data,
                contentType: false,
                processData: false,
                method: 'POST',
                success: (result) => {

                    console.log("load update client done");
                    console.log(result);

                    if(result.success === true){
                        dispatch({type:"UPDATE_CLIENT_DONE", result:result.data});
                    }else{
                        dispatch({type:"UPDATE_CLIENT_DONE", result:null});
                        let err = {message:result.error};
                        ownProps.showLoadError("Problem saving client", err);
                    }
                },
                error: (result) => {

                    dispatch({type:"UPDATE_CLIENT_DONE", result:null});
                    dispatch({type:"UPDATE_CLIENT_RESET"});

                    if(result !== undefined) {
                        ownProps.showLoadError("Problem saving client", result);
                    }else{
                        ownProps.showLoadError("Problem saving client", null);
                    }
                }
            }, false);


            /*

            let request = {
                clientId:client.clientId,
                client_name:client.name,
                description:client.desc,
                libraries:libraries,
                existingLibraries:existing
            };

            console.log(request);


            ebase.ajax({
                url: "/ebase/core/write-system-updateclient",
                data: request,
                success: (result) => {
                    // console.log("load update client done");
                    // console.log(result);
                    if(result.success === true){
                        dispatch({type:"UPDATE_CLIENT_DONE", result:result.data});
                    }else{
                        dispatch({type:"UPDATE_CLIENT_DONE", result:null});
                        let err = {message:result.error};
                        ownProps.showLoadError("Problem saving client", err);
                    }
                },
                error:   (result) => {
                    dispatch({type:"UPDATE_CLIENT_DONE", result:null});
                    dispatch({type:"UPDATE_CLIENT_RESET"});

                    if(result !== undefined) {
                        ownProps.showLoadError("Problem saving client", result);
                    }else{
                        ownProps.showLoadError("Problem saving client", null);
                    }
                }
            });

            */


        },

    };
};


const ClientManageContainer = connect(mapStateToProps,mapDispatchToProps)(ClientManageView);

export default ClientManageContainer

