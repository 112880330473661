/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SummaryRequest
 */
export interface SummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof SummaryRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryRequest
     */
    summaryType?: SummaryRequestSummaryTypeEnum;
}


/**
 * @export
 */
export const SummaryRequestSummaryTypeEnum = {
    ControlsSummary: 'ControlsSummary',
    StandardSummary: 'StandardSummary',
    KnowledgeBase: 'KnowledgeBase',
    Stakeholder: 'Stakeholder',
    ImpactSummary: 'ImpactSummary',
    RegionSummary: 'RegionSummary',
    ReceptorSummary: 'ReceptorSummary'
} as const;
export type SummaryRequestSummaryTypeEnum = typeof SummaryRequestSummaryTypeEnum[keyof typeof SummaryRequestSummaryTypeEnum];


/**
 * Check if a given object implements the SummaryRequest interface.
 */
export function instanceOfSummaryRequest(value: object): value is SummaryRequest {
    return true;
}

export function SummaryRequestFromJSON(json: any): SummaryRequest {
    return SummaryRequestFromJSONTyped(json, false);
}

export function SummaryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['objectId'] == null ? undefined : json['objectId'],
        'summaryType': json['summaryType'] == null ? undefined : json['summaryType'],
    };
}

export function SummaryRequestToJSON(value?: SummaryRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'objectId': value['objectId'],
        'summaryType': value['summaryType'],
    };
}

