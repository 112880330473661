/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClientLibraryResult } from './ClientLibraryResult';
import {
    ClientLibraryResultFromJSON,
    ClientLibraryResultFromJSONTyped,
    ClientLibraryResultToJSON,
} from './ClientLibraryResult';

/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserRequest
     */
    active?: boolean;
    /**
     * 
     * @type {Array<ClientLibraryResult>}
     * @memberof UpdateUserRequest
     */
    access?: Array<ClientLibraryResult>;
}

/**
 * Check if a given object implements the UpdateUserRequest interface.
 */
export function instanceOfUpdateUserRequest(value: object): value is UpdateUserRequest {
    return true;
}

export function UpdateUserRequestFromJSON(json: any): UpdateUserRequest {
    return UpdateUserRequestFromJSONTyped(json, false);
}

export function UpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'username': json['username'] == null ? undefined : json['username'],
        'email': json['email'] == null ? undefined : json['email'],
        'firstname': json['firstname'] == null ? undefined : json['firstname'],
        'lastname': json['lastname'] == null ? undefined : json['lastname'],
        'password': json['password'] == null ? undefined : json['password'],
        'active': json['active'] == null ? undefined : json['active'],
        'access': json['access'] == null ? undefined : ((json['access'] as Array<any>).map(ClientLibraryResultFromJSON)),
    };
}

export function UpdateUserRequestToJSON(value?: UpdateUserRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'username': value['username'],
        'email': value['email'],
        'firstname': value['firstname'],
        'lastname': value['lastname'],
        'password': value['password'],
        'active': value['active'],
        'access': value['access'] == null ? undefined : ((value['access'] as Array<any>).map(ClientLibraryResultToJSON)),
    };
}

