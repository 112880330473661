import React from 'react';
import Spinner from "../components/common/Spinner"
import EButton from "../components/common/EButton"
import EbasePanel from '../components/common/EbasePanel'

import {DropdownButton,Table,FormControl, Dropdown} from 'react-bootstrap'
import {withTranslation} from "react-i18next";

class ProjectRoles extends React.Component {

    constructor(props){
        super(props);

        this.state = {}

    }

    onEdit(object_id)
    {
        this.props.startEdit(object_id);
    }

    finishedField(fieldName, event) {


    }

    inputChanged(object_id, fieldName, event){
        event.preventDefault();
        let value = event.target.value;
        this.props.updateField(object_id, fieldName, value);
    }

    nameChanged(object_id,user_id){
        this.props.updateField(object_id,"details.user_id",user_id);
    }

    render(){
        // console.log("ProjectRoles RENDER");
        // console.log(this.props);
        const {t} = this.props;

        let body;

        let roles = this.props.roles;
        if(roles === null || roles === undefined){
            roles = [];
        }
        // let roles = [{object_id:"123",details:{name:"Office Overseer",user_id:"587c33472c989d7e6edf76e1"}},
        //     {object_id:"124",details:{name:"Mousecop",user_id:"587c33472c989d7e6edf76e1"}},
        //
        // ];

        if(this.props.isLoading){

            body = <Spinner/>

        }else{

            let addButton = <div style={{display:"flex",justifyContent:"flex-end"}}><EButton type="add" onClick={this.props.newObject}>{t("New Role")}</EButton></div>;

            let rolesTable;
            let editingID = this.props.editing_id;



            let rows = roles.map( (role,i) => {
                    // console.log("role - ", role);

                    let roleName = "";
                    if(role.details.name != null){
                        roleName = role.details.name;
                    }

                    let name = "";
                    let email = "";

                    let isEditing = editingID === role.sharedId;

                    if (this.props.users != null) {
                        let userInfo = this.props.users[role.details.user_id];
                        if (userInfo != null) {
                            if (userInfo.name != null) {
                                name = userInfo.name;
                            }
                            if (userInfo.extra != null) {
                                email = userInfo.extra;
                            }
                        }
                    }

                    let roleContent = <div><i className="fa fa-address-card-o" style={{color:"#c1c3c4",marginRight:5,fontSize:'1.1em'}}></i>{roleName}</div>;
                    //let nameContent = <div style={{display:"flex",justifyContent:"space-between", height:"100%"}}><span>{name}</span><span><i className="fa fa-envelope" style={{color:"#c1c3c4"}}></i>&nbsp;&nbsp;{email}</span></div>;
                let nameContent = <div><i className="fa fa-user-circle-o" style={{color:"#c1c3c4",marginRight:5}}></i>{name}</div>;
                    let button = <EButton type="edit" isTable={true} onClick={this.onEdit.bind(this, role.sharedId)}>{t("Edit")}</EButton>;
                let emailContent = <div><i className="fa fa-envelope" style={{color:"#c1c3c4",marginRight:5}}></i>{email}</div>

                    if(isEditing){
                        button = <EButton isTable={true} onClick={this.onEdit.bind(this, null)} type="done">{t("Done")}</EButton>;

                        roleContent = <FormControl
                                        type="text"
                                        field={"details.name"}
                                        onClick={(event) => event.target.select()}
                                        onChange={this.inputChanged.bind(this,role.sharedId,"details.name")}
                                        onBlur={this.finishedField.bind("details.name")}
                                        style={{width:"100%", fontSize:"13px"}}
                                        value={role.details.name}/>;

                        let items = [];
                        for (var userid in this.props.users){

                            if(userid === role.details.user_id){

                                items.push(
                                    <Dropdown.Item variant={'outline-secondary'} key={userid} eventKey={userid} active>{this.props.users[userid].name}</Dropdown.Item>
                                )

                            }else{
                                items.push(
                                    <Dropdown.Item variant={'outline-secondary'} key={userid} eventKey={userid}>{this.props.users[userid].name}</Dropdown.Item>
                                )
                            }

                            // nameContent = <div style={{display:"flex",justifyContent:"space-between"}}>
                            //     <DropdownButton title={this.props.users[role.details.user_id].name} onSelect={this.nameChanged.bind(this,role.object_id)}>{items}</DropdownButton>
                            //     <span><i className="fa fa-envelope" style={{color:"#c1c3c4"}}></i>&nbsp;&nbsp;{email}</span></div>;


                            let roleUser = this.props.users[role.details.user_id]
                            let name ="Select"
                            if (roleUser != null){
                                name = roleUser.name;
                            }

                            nameContent = <div style={{display:"flex",justifyContent:"space-between"}}>
                                <DropdownButton title={name} variant={'outline-secondary'} size="sm" style={{paddingTop: '3px'}} onSelect={this.nameChanged.bind(this,role.sharedId)}>{items}</DropdownButton>
                                </div>;
                        }
                    }

                    let tdStyle= {verticalAlign:"middle"};


                    return (
                        <tr key={""+i}>
                            <td style={tdStyle}>{roleContent}</td>
                            <td style={tdStyle}>
                                {nameContent}
                            </td>
                            <td style={tdStyle}>{emailContent}</td>
                            <td align="right" style={tdStyle}><div style={{display:"flex",justifyContent:"flex-end"}}>{button}</div></td>
                        </tr>
                    )
                }

            );
            //<TableHeader titles={["Role", " "," "," "]}/>

            if(rows.length === 0){

                rolesTable = <div style={{textAlign:"center",fontSize:'1.5em'}}>{t("Add your first Project Role")}</div>
            }else {

                rolesTable = (
                    <Table className="table table-hover">
                        <thead>
                        <tr>
                            <th><i className="fa fa-address-card-o" style={{marginRight:5,fontSize:'1.1em'}}></i>{t("Roles")}</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                );
            }

            body =  (
                <div>
                    {addButton}
                    <div className="row" style={{paddingLeft:10, paddingRight:10}}>

                                {rolesTable}

                    </div>

                </div>
            )
        }


        return <EbasePanel>{body}</EbasePanel>
    }

}

export default withTranslation()(ProjectRoles)
