/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FormattedText } from './FormattedText';
import {
    FormattedTextFromJSON,
    FormattedTextFromJSONTyped,
    FormattedTextToJSON,
} from './FormattedText';
import type { Contact } from './Contact';
import {
    ContactFromJSON,
    ContactFromJSONTyped,
    ContactToJSON,
} from './Contact';

/**
 * Base Class for EnvidDetails
 * @export
 * @interface EnvidDetails
 */
export interface EnvidDetails {
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    name?: string;
    /**
     * 
     * @type {FormattedText}
     * @memberof EnvidDetails
     */
    desc?: FormattedText;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    shortDesc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnvidDetails
     */
    useLongDesc?: boolean;
    /**
     * 
     * @type {FormattedText}
     * @memberof EnvidDetails
     */
    publicDesc?: FormattedText;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    impactId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    controlTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    controlNameId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    controlSourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    control?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    controlSource?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    impactReceptorLinkId?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvidDetails
     */
    ranking?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    epo?: string;
    /**
     * 
     * @type {FormattedText}
     * @memberof EnvidDetails
     */
    eps?: FormattedText;
    /**
     * 
     * @type {FormattedText}
     * @memberof EnvidDetails
     */
    criteria?: FormattedText;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    aspectId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    aspectActivityLinkId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    impactTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    timezone?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    referenceInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    website?: string;
    /**
     * 
     * @type {Array<Contact>}
     * @memberof EnvidDetails
     */
    contacts?: Array<Contact>;
    /**
     * 
     * @type {string}
     * @memberof EnvidDetails
     */
    receptorType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnvidDetails
     */
    environmentalInputs?: Array<string>;
}

/**
 * Check if a given object implements the EnvidDetails interface.
 */
export function instanceOfEnvidDetails(value: object): value is EnvidDetails {
    return true;
}

export function EnvidDetailsFromJSON(json: any): EnvidDetails {
    return EnvidDetailsFromJSONTyped(json, false);
}

export function EnvidDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvidDetails {
    if (json == null) {
        return json;
    }
    if (!ignoreDiscriminator) {
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'desc': json['desc'] == null ? undefined : FormattedTextFromJSON(json['desc']),
        'shortDesc': json['short_desc'] == null ? undefined : json['short_desc'],
        'useLongDesc': json['use_long_desc'] == null ? undefined : json['use_long_desc'],
        'publicDesc': json['public_desc'] == null ? undefined : FormattedTextFromJSON(json['public_desc']),
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'impactId': json['impact_id'] == null ? undefined : json['impact_id'],
        'controlTypeId': json['control_type_id'] == null ? undefined : json['control_type_id'],
        'controlNameId': json['control_name_id'] == null ? undefined : json['control_name_id'],
        'controlSourceId': json['control_source_id'] == null ? undefined : json['control_source_id'],
        'control': json['control'] == null ? undefined : json['control'],
        'controlSource': json['control_source'] == null ? undefined : json['control_source'],
        'impactReceptorLinkId': json['impact_receptor_link_id'] == null ? undefined : json['impact_receptor_link_id'],
        'ranking': json['ranking'] == null ? undefined : json['ranking'],
        'epo': json['epo'] == null ? undefined : json['epo'],
        'eps': json['eps'] == null ? undefined : FormattedTextFromJSON(json['eps']),
        'criteria': json['criteria'] == null ? undefined : FormattedTextFromJSON(json['criteria']),
        'aspectId': json['aspect_id'] == null ? undefined : json['aspect_id'],
        'aspectActivityLinkId': json['aspect_activity_link_id'] == null ? undefined : json['aspect_activity_link_id'],
        'impactTypeId': json['impact_type_id'] == null ? undefined : json['impact_type_id'],
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'referenceInfo': json['reference_info'] == null ? undefined : json['reference_info'],
        'website': json['website'] == null ? undefined : json['website'],
        'contacts': json['contacts'] == null ? undefined : ((json['contacts'] as Array<any>).map(ContactFromJSON)),
        'receptorType': json['receptor_type'] == null ? undefined : json['receptor_type'],
        'environmentalInputs': json['environmentalInputs'] == null ? undefined : json['environmentalInputs'],
    };
}

export function EnvidDetailsToJSON(value?: EnvidDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'desc': FormattedTextToJSON(value['desc']),
        'short_desc': value['shortDesc'],
        'use_long_desc': value['useLongDesc'],
        'public_desc': FormattedTextToJSON(value['publicDesc']),
        'displayName': value['displayName'],
        'impact_id': value['impactId'],
        'control_type_id': value['controlTypeId'],
        'control_name_id': value['controlNameId'],
        'control_source_id': value['controlSourceId'],
        'control': value['control'],
        'control_source': value['controlSource'],
        'impact_receptor_link_id': value['impactReceptorLinkId'],
        'ranking': value['ranking'],
        'epo': value['epo'],
        'eps': FormattedTextToJSON(value['eps']),
        'criteria': FormattedTextToJSON(value['criteria']),
        'aspect_id': value['aspectId'],
        'aspect_activity_link_id': value['aspectActivityLinkId'],
        'impact_type_id': value['impactTypeId'],
        'timezone': value['timezone'],
        'reference_info': value['referenceInfo'],
        'website': value['website'],
        'contacts': value['contacts'] == null ? undefined : ((value['contacts'] as Array<any>).map(ContactToJSON)),
        'receptor_type': value['receptorType'],
        'environmentalInputs': value['environmentalInputs'],
    };
}

