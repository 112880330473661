/**
 * Created by lewellyn on 14/12/16.
 */
import React from 'react'
import ebase from '../../utils/ajax'
import update from 'immutability-helper';
import sitedata from '../../utils/sitedata'
import DataPublishing from '../../views/DataPublishing'
import SessionManager from "../../utils/SessionManager";

class DataPublishingContainer extends React.Component{


    constructor(props){
        super(props);

        this.state={
            updating: false,
            workingStatus: this.props.currentObject.object.publishingInfo.state,
            workingNotes: '',
            objectHistory: [],
            isLoading: false,
        };

        this.onChangePublished = this.onChangePublished.bind(this);
        this.updateSuccess = this.updateSuccess.bind(this);
        this.updateError = this.updateError.bind(this);
        this.fieldChanged = this.fieldChanged.bind(this);
    }

    componentDidMount() {
        this.loadWorkflowHistory();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('Container prevProps', prevProps.currentObject?.object?.publishingInfo);
        // console.log('Container this.props', this.props.currentObject?.object?.publishingInfo);
        if (prevProps && prevProps.currentObject?.object?.publishingInfo?.state != this.props.currentObject?.object?.publishingInfo?.state) {
            this.loadWorkflowHistory();
        }
    }

    loadWorkflowHistory() {
        const ebaseDs = SessionManager.shared().getEbaseDS();
        this.setState({isLoading: true});
        ebaseDs.loadObjectWorkflowHistory(this.props.currentObject.object.sharedId).then((data)=>{
            // console.log('loadObjectWorkflowHistory result', data);
            this.setState({objectHistory: data});
            this.setState({isLoading: false});
        }).catch(error=>{
            console.log(' sad face', error);
        });
    }

    fieldChanged(fieldName, value) {
        if (fieldName === 'status') {
            this.setState({workingStatus: value});
        }
        if (fieldName === 'notes') {
            this.setState({workingNotes: value});
        }
    }

    onChangePublished(checkInFlag){
        // console.log(' onChangePublished', this.state, checkInFlag);
        let newPublishState = this.state.workingStatus
        this.setState({updating:true});
        this.props.updateSavedStatus(this.props.currentObject.object.objectId, "saving");

        // #TODO: Add the 'extra' param with notes - {notes: 'blahb alh'}
        ebase.ajax({
           url:"/ebase/envid-admin/write-enviddata-updatefield",
            data:{
                primarySharedId:this.props.currentObject.object.sharedId,
                targetSharedId:this.props.currentObject.object.sharedId,
                field:{name:"publishing.state",value: newPublishState},
                extra:{notes: this.state.workingNotes}
            },
            success: this.updateSuccess.bind(this, newPublishState, checkInFlag),
            error: this.updateError.bind(this)

        });
    }

    updateSuccess(published, checkInFlag, result){
        // console.log("update success: "+JSON.stringify(result));
        // console.log("UPDATE PUBLISH STATE: "+published);

        let updatedObject = update(this.props.currentObject, {object: {publishingInfo: {state:{$set: published}}}});

        updatedObject.last_update_user = sitedata.getUser(this.props);
        updatedObject.object.documentInfo.last_update_time = new Date().getTime();
        updatedObject.object.documentInfo.last_update_user = updatedObject.last_update_user.user_id;
        if (updatedObject.object.versionDetails.state !== 'Working') {
            updatedObject.object.versionDetails.state = 'Working';
        }

        this.setState({updating:false, workingStatus:updatedObject.object.publishingInfo.state, workingNotes: ''});

        this.props.updateSavedStatus(this.props.currentObject.object.objectId, "saved");
        this.props.updateObject(updatedObject);

        //Uses check in flag to stop race condition for updating status and checking in obj
        if (!!checkInFlag) {
            this.props.checkinObjectClick(checkInFlag, this.props.currentObject.object, "ObjectView")
        }
    }

    updateError(result){
        console.log("update error: ",result);
        this.setState({updating:false});
        this.props.updateSavedStatus(this.props.currentObject.object.objectId, "error");
    }


    render()
    {
        return (

                <DataPublishing currentObject={this.props.currentObject}
                                doChangePublished = {this.onChangePublished}
                                isUpdating={this.state.updating}
                                isLoading={this.state.isLoading}
                                fieldChanged={this.fieldChanged}
                                siteData={this.props.siteData}
                                workingInfo={{status: this.state.workingStatus, notes: this.state.workingNotes}}
                                // checkinObjectClick={this.props.checkinObjectClick}
                                objectHistory={this.state.objectHistory}
                />
        );
    }


}

export default DataPublishingContainer


