/**
 * Created by lewellyn on 18/10/18.
 */
import React from 'react';
import ContainerHeader from "../components/common/ContainerHeader";
import PageWrapper from "../components/common/PageWrapper";
import SummaryFull from "../components/common/SummaryFull";
import SplitPane from 'react-split-pane'
import Spinner from '../components/common/Spinner'
import details from "../utils/details";
import DataDetailsReadOnly from '../views/DataDetailsReadOnly';
import EButton from "../components/common/EButton";
import {withTranslation} from "react-i18next";

class StakeholderSummaryView extends React.Component {


    selectObject(object,parent)
    {
        // load the object from the server
        // console.log("Stakeholder summary parent:");
        // console.log(parent);
        this.props.loadObject(object,parent);

    }

    getCSVClick(regionId){
        // console.log("stakeholder csv for: "+regionId);

        window.location.assign("/ebase/stakeholder/read-stakeholder-csvregionsummary/" + regionId);
    }

    render() {
        const {t} = this.props;

        let page_title = t("Stakeholder Summary");
        let pane_style = {padding:20, overflow:"scroll"};
        pane_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height;
        pane_style.overflowY = "scroll";
        pane_style.overflowX = "hidden";
        pane_style.textOverflow = "ellipsis";

        let selected = <div>{t("Select an object")}</div>;

        if (this.props.isLoading)
        {
            return <div>
                <ContainerHeader title={page_title} crumbs={['/', page_title]} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <Spinner/>
                </PageWrapper>
            </div>
        }

        // console.log("Stakeholder Summary");

        // console.log("objects");
        // console.log(this.props.objects);
        //
        // console.log("children");
        // console.log(this.props.children);

        let downloadButton =[];

        if (this.props.selectedIsLoading === true)
        {
            selected = <Spinner/>
        }
        else if (this.props.selectedData != null)
        {
            let template = details.getObjectReadOnlyTemplate(this.props.selectedData.object.type);
            selected = <DataDetailsReadOnly fields={template[0].fields} currentObject={this.props.selectedData}/>

            if(this.props.selectedData.object.type === "Region"){
                downloadButton = (
                    <div style={{marginBottom:10,textAlign:'right'}}>
                        <EButton onClick={() => this.getCSVClick(this.props.selectedData.object.sharedId)}>{t("Get Stakeholder CSV")}</EButton>
                    </div>
                );
            }

        }

        return (
            <div>
                <ContainerHeader title={page_title} crumbs={['/', page_title]} dimensions={this.props.dimensions}/>
                <PageWrapper dimensions={this.props.dimensions} style={{padding:0, margin:0, position:"relative"}}>
                    <SplitPane split="vertical" minSize={350}>
                        <div style={pane_style}>
                            <SummaryFull topType={"Region"} children={this.props.children} objects={this.props.objects} onclick={this.selectObject.bind(this)}/>
                        </div>
                        <div style={pane_style}>
                            {downloadButton}
                            {selected}
                        </div>
                    </SplitPane>
                </PageWrapper>
            </div>

        );
    }

}


export default withTranslation()(StakeholderSummaryView)
