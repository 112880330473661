/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvidDocumentInfo
 */
export interface EnvidDocumentInfo {
    /**
     * 
     * @type {string}
     * @memberof EnvidDocumentInfo
     */
    author?: string;
    /**
     * 
     * @type {Date}
     * @memberof EnvidDocumentInfo
     */
    creationTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EnvidDocumentInfo
     */
    lastUpdateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof EnvidDocumentInfo
     */
    lastUpdateUser?: string;
}

/**
 * Check if a given object implements the EnvidDocumentInfo interface.
 */
export function instanceOfEnvidDocumentInfo(value: object): value is EnvidDocumentInfo {
    return true;
}

export function EnvidDocumentInfoFromJSON(json: any): EnvidDocumentInfo {
    return EnvidDocumentInfoFromJSONTyped(json, false);
}

export function EnvidDocumentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvidDocumentInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'author': json['author'] == null ? undefined : json['author'],
        'creationTime': json['creation_time'] == null ? undefined : (new Date(json['creation_time'])),
        'lastUpdateTime': json['last_update_time'] == null ? undefined : (new Date(json['last_update_time'])),
        'lastUpdateUser': json['last_update_user'] == null ? undefined : json['last_update_user'],
    };
}

export function EnvidDocumentInfoToJSON(value?: EnvidDocumentInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'author': value['author'],
        'creation_time': value['creationTime'] == null ? undefined : ((value['creationTime']).toISOString()),
        'last_update_time': value['lastUpdateTime'] == null ? undefined : ((value['lastUpdateTime']).toISOString()),
        'last_update_user': value['lastUpdateUser'],
    };
}

