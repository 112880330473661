import React, {ReactNode, useEffect, useState} from 'react';
import ContainerHeader from '../components/common/ContainerHeader';
import PageWrapper from '../components/common/PageWrapper';
import EbasePanel from '../components/common/EbasePanel';
import {Table, Form} from 'react-bootstrap';
import Spinner from '../components/common/Spinner';
import EButton from '../components/common/EButton';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {TemplateManager} from '../utils/TemplateManager';
import SessionManager from '../utils/SessionManager';
import {EbaseAppState} from "../redux/reducers";
import {ClientLibraryResult, DatasetResult, ReportTemplateResult} from "../fetchapi";
import EBCommonTable, {ColumnDef} from "../components/common/EBCommonTable";
import {PropsWithLocation} from "../index";

export interface TemplatesAssignViewProps extends PropsWithLocation {}


const TemplatesAssignView = (props:TemplatesAssignViewProps) => {

  // console.log("TemplatesAssignView::: props - ", props);
  const {t} = useTranslation();

  const headings: ColumnDef[] = [
    {key: 'use', display: t('Use')},
    {key: 'report', display: t('Report')},
    {key: 'name', display: t('Name')},
    {key: 'type', display: t('Type')},
    {key: 'default', display: t('Default')},
    {key: 'builtIn', display: t('Built In')}
  ];

  const templates: ReportTemplateResult[] | null = useSelector((state: EbaseAppState) => state.templates.templates);
  const isLoading = useSelector((state: EbaseAppState) => state.templates.isLoading);
  const dimensions = useSelector((state: EbaseAppState) => state.session.dimensions);
  const siteData = useSelector((state: EbaseAppState) => state.session.siteData);

  const [assigned, setAssigned] = useState<{ [key: string]: boolean }>({});
  const [saving, setSaving] = useState(false);
  const [client, setClient] = useState<ClientLibraryResult>();
  const [library, setLibrary] = useState<DatasetResult>();
  const [libraryConfig, setLibraryConfig] = useState<any>();

  const clientId = props.match?.params?.clientId;
  const dataSetId = props.match?.params?.dataSetId;

  const backLink = '/client/' + clientId;

  const assignTemplate = (template_id: string, assign: boolean) => {
    let newAssigned = {...assigned};
    newAssigned[template_id] = assign;
    setAssigned(newAssigned);
  }

  const assignAll = (use: boolean) => {
    let newAssigned = {...assigned};
    Object.keys(assigned).forEach(tid => newAssigned[tid] = use);
    setAssigned(newAssigned);
  }

  const saveAssignments = () => {
    let tids = Object.keys(assigned).filter(tid => assigned[tid]);

    setSaving(true);
    TemplateManager.updateClientTemplates(clientId, dataSetId, tids).then(() => {
      SessionManager.shared().checkSession(() => {
      });
      SessionManager.shared().goto(backLink);
    }).catch(error => {
      SessionManager.shared().showLoadError(t('Error Saving Template Assignments') + " - " + error, error);
    }).finally(() => {
      setSaving(false);
    })
  }

  const cancelAssignments = () => {
    SessionManager.shared().goto(backLink);
  }

  useEffect(() => {
    if (!templates && !isLoading) {
      TemplateManager.loadReportTemplates();
    }
  }, [templates, isLoading]);

  useEffect(() => {
    let client;
    if (siteData) {
      client = siteData?.clientLibraries?.find(cl => {
        return cl.client?.clientId === clientId;
      })
    }
    setClient(client);
  }, [siteData]);

  useEffect(() => {
    if (client) {
      setLibrary(client.libraries?.find(l => l.datasetId === dataSetId));
      if (!!client.libraryConfig && !!client.libraryConfig[dataSetId]) {
        setLibraryConfig(client.libraryConfig![dataSetId]);
      } else {
        setLibraryConfig({});
      }

    } else {
      setLibrary(undefined);
      setLibraryConfig(undefined);
    }
  }, [client]);

  useEffect(() => {
    let newAssigned: { [key: string]: boolean } = {};

    if (libraryConfig) {
      let templateIds: string[] = libraryConfig?.templateIds;

      if (templates) {
        templates.forEach(template => {
          let tid = template.templateId!;
          let curAssign = assigned[tid];
          let isAssigned = !templateIds ? template.builtIn : templateIds.find(
            i => i === tid);
          newAssigned[tid] = curAssign ?? isAssigned;
        });
      }

    }

    setAssigned(newAssigned);
  }, [templates, libraryConfig]);

  const renderTableHeader = (defs: ColumnDef[]): React.ReactElement => {
    return (
      <tr>
        {defs.map((def, index) => (
          <th style={(['use', 'default', 'builtIn'].includes(def.key) ? {textAlign: 'center'} : {})}
              key={index}>{def.display}</th>
        ))}
      </tr>
    );
  }

  const renderTemplateRow = (template: ReportTemplateResult): ReactNode => {
    return (
      <tr key={template.templateId}>
        <td style={{textAlign: 'center'}}>
          <Form.Check id={template.templateId + '_use'}
                      className="icheckbox_minimal-green"
                      checked={!!assigned[template?.templateId!]}
                      onChange={(ev) => assignTemplate(template.templateId!, ev.target.checked)}>
          </Form.Check>
        </td>
        <td>{template.reportType}</td>
        <td>{template.name}</td>
        <td>{template.type}</td>
        <td style={{textAlign: 'center'}}>{template.isDefault && (<i className="fa fa-check"/>)}</td>
        <td style={{textAlign: 'center'}}>{template.builtIn && (<i className="fa fa-check"/>)}</td>
      </tr>
    )
  };

  let body;


  // console.log("USersView;:");
  // console.log(this.props);

  if (isLoading || saving) {
    body = <Spinner/>;
  } else {

    if (!templates || !templates.length) {
      body =
      <Table className="table table-hover">
        <thead>
        <tr>
          <th style={{textAlign:'center'}}>{t('Use')}</th>
          <th>{t('Report')}</th>
          <th>{t('Name')}</th>
          <th>{t('Type')}</th>
          <th style={{textAlign:'center'}}>{t('Default')}</th>
          <th style={{textAlign:'center'}}>{t('Built In')}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{t('No Data')}</td>
        </tr>
        </tbody>
      </Table>;

    } else {

      templates.sort((a, b) => {

        let ret = a.reportType!.localeCompare(b.reportType!);
        if (ret === 0) {
          ret = a.name!.localeCompare(b.name!);
        }
        if (ret === 0) {
          ret = a.description!.localeCompare(b.description!);
        }
        if (ret === 0) {
          ret = a.type!.localeCompare(b.type!);
        }
        if (ret === 0) {
          ret = a.templateId!.localeCompare(b.templateId!);
        }
        return ret;

      });

      body = <EBCommonTable
        className={'table-hover'}
        rowData={templates ?? []}
        columnDefinitions={headings}
        renderHeader={renderTableHeader}
        renderRow={(item: ReportTemplateResult, defs: ColumnDef[]) => renderTemplateRow(item)}/>;
    }

    let title = t('Assign Templates') + ' - ' + library?.desc ?? '';

    return (
      <div>
        <ContainerHeader title={title} crumbs={['/', backLink, t('Assign Templates')]}
                         dimensions={dimensions}/>
        <PageWrapper dimensions={dimensions}>
          <EbasePanel>
            <div style={{display: 'flex', flexDirection: 'row', paddingTop: 10, borderTop: '1px solid #eaeaea'}}>
              <EButton onClick={() => assignAll(true)} type="done">{t('Select All')}</EButton>
              <EButton style={{marginLeft: 25}} onClick={() => {
                assignAll(false)
              }} type="cancel">{t('Deselect All')}</EButton>
            </div>
            {body}
            <div style={{display: 'flex', flexDirection: 'row', paddingTop: 10, borderTop: '1px solid #eaeaea'}}>
              <EButton onClick={cancelAssignments} type="cancel">{t('Cancel')}</EButton>
              <EButton style={{marginLeft: 25}} onClick={saveAssignments} type="done">{t('Done')}</EButton>
            </div>
          </EbasePanel>
        </PageWrapper>
      </div>
    );

  }
}

export default TemplatesAssignView;
