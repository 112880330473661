/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import sitedata from '../../utils/sitedata'
import ComplianceStatusView from '../../views/ComplianceStatusView'
import { connect } from 'react-redux'
import ComplianceFilter from "../../utils/ComplianceFilter"

import {
    loadProjectComplianceStatusStart,
    loadProjectComplianceStatusDone
} from '../../redux/actions/actions'

import SessionManager from "../../utils/SessionManager";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{

    let currentObject = state.reports.currentObject;
    return {
        project: (currentObject==null)? null : currentObject.project,
        statusInfo : state.projects.compliance_status,
        isStatusLoading : state.projects.isStatusLoading,
    }
}

const loadStatus = function(project_id,dispatch){

    if(project_id === null || project_id === undefined){
        return;
    }

    dispatch(loadProjectComplianceStatusStart());
    ebase.ajax({
        url: "/ebase/compliance/read-compliance-projectstatus",
        data: {project_id: project_id},
        success: (result) => {
            dispatch(loadProjectComplianceStatusDone(result));
        },
        error:   () => {
            dispatch(loadProjectComplianceStatusDone());
        }
    });
}

const statusClicked = (status, isUsersOnly, ownProps) => {

    // console.log("Compliance Status Container:: status clicked: " + status);


    let filter = new ComplianceFilter();
    if (status === "DUE_SOON")
    {
        filter.setShowDueIn(14);
    }
    else if (status === "COMPLIANT")
    {
        filter.setStatus("COMPLIANT", true);
    }
    else if (status === "IN_PROGRESS")
    {
        filter.setStatus("IN_PROGRESS", true);
    }
    else if (status === "NOT_COMPLIANT")
    {
        filter.setStatus("NOT_COMPLIANT", true);
    }
    else if (status === "INCOMPLETE")
    {
        filter.setStatus("NEW", true);
    }

    if (isUsersOnly)
    {
        filter.setMineOnly(true);
    }

    let project_id = ownProps.project.object_id;

    SessionManager.shared().goto('/compliance/' + project_id + '/manage/' + filter.toString() + '/none/setup');

}


const mapDispatchToProps = (dispatch, ownProps) =>
{
    return {
        loadStatus : (project_id) => loadStatus(project_id,dispatch),
        statusClicked : (status, isProjectStatus) => statusClicked(status, !isProjectStatus, ownProps),
    }
}

class CompStatusView extends React.Component{
    render() {

        return (
            <div>
                {React.cloneElement(<ComplianceStatusView/>, this.props)}
            </div>
        )
    }
    componentWillReceiveProps(nextProps)
    {
        // console.log("TheAlertsView will receive props");
        // console.log(nextProps.routeParams.projectId);
        // console.log(this.props.routeParams.projectId);
        // console.log("=--------------------------");

        let nextParams = sitedata.getParams(nextProps);
        let params = sitedata.getParams(this.props);
        if(nextParams.projectId !== params.projectId){
            this.props.loadStatus(nextParams.projectId);
        }

        // if(nextProps.routeParams.projectId !== this.props.routeParams.projectId){
        //     this.props.loadStatus(nextProps.routeParams.projectId);
        // }

    }
    componentWillMount()
    {
        // console.log("CompStatusView will mount");
        // console.log(this.props);
        // console.log(this.props.project);
        // console.log("=--------------------------");

        if(this.props.project !== null) {
            this.props.loadStatus(this.props.project.object_id);
        }
    }

};

const ComplianceStatusContainer = connect(mapStateToProps, mapDispatchToProps)(CompStatusView);

export default ComplianceStatusContainer
