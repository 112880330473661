/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewReferenceRequest
 */
export interface NewReferenceRequest {
    /**
     * 
     * @type {string}
     * @memberof NewReferenceRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewReferenceRequest
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewReferenceRequest
     */
    reference?: string;
}

/**
 * Check if a given object implements the NewReferenceRequest interface.
 */
export function instanceOfNewReferenceRequest(value: object): value is NewReferenceRequest {
    return true;
}

export function NewReferenceRequestFromJSON(json: any): NewReferenceRequest {
    return NewReferenceRequestFromJSONTyped(json, false);
}

export function NewReferenceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewReferenceRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['objectId'] == null ? undefined : json['objectId'],
        'referenceId': json['referenceId'] == null ? undefined : json['referenceId'],
        'reference': json['reference'] == null ? undefined : json['reference'],
    };
}

export function NewReferenceRequestToJSON(value?: NewReferenceRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'objectId': value['objectId'],
        'referenceId': value['referenceId'],
        'reference': value['reference'],
    };
}

