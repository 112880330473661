/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvidPublishingInfo
 */
export interface EnvidPublishingInfo {
    /**
     * 
     * @type {boolean}
     * @memberof EnvidPublishingInfo
     */
    published?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EnvidPublishingInfo
     */
    state?: string;
}

/**
 * Check if a given object implements the EnvidPublishingInfo interface.
 */
export function instanceOfEnvidPublishingInfo(value: object): value is EnvidPublishingInfo {
    return true;
}

export function EnvidPublishingInfoFromJSON(json: any): EnvidPublishingInfo {
    return EnvidPublishingInfoFromJSONTyped(json, false);
}

export function EnvidPublishingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvidPublishingInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'published': json['published'] == null ? undefined : json['published'],
        'state': json['state'] == null ? undefined : json['state'],
    };
}

export function EnvidPublishingInfoToJSON(value?: EnvidPublishingInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'published': value['published'],
        'state': value['state'],
    };
}

