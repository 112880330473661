/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewMilestoneRequest
 */
export interface NewMilestoneRequest {
    /**
     * 
     * @type {string}
     * @memberof NewMilestoneRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMilestoneRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMilestoneRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewMilestoneRequest
     */
    date?: string;
}

/**
 * Check if a given object implements the NewMilestoneRequest interface.
 */
export function instanceOfNewMilestoneRequest(value: object): value is NewMilestoneRequest {
    return true;
}

export function NewMilestoneRequestFromJSON(json: any): NewMilestoneRequest {
    return NewMilestoneRequestFromJSONTyped(json, false);
}

export function NewMilestoneRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewMilestoneRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['object_id'] == null ? undefined : json['object_id'],
        'projectId': json['project_id'] == null ? undefined : json['project_id'],
        'name': json['name'] == null ? undefined : json['name'],
        'date': json['date'] == null ? undefined : json['date'],
    };
}

export function NewMilestoneRequestToJSON(value?: NewMilestoneRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_id': value['objectId'],
        'project_id': value['projectId'],
        'name': value['name'],
        'date': value['date'],
    };
}

