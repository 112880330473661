/**
 * Created by lewellyn on 18/9/18.
 */
import React from 'react';
import { connect } from 'react-redux'
import ClientLibrarySelectView from "../../views/ClientLibrarySelectView";
import SessionManager from "../../utils/SessionManager";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{
    return {
        siteData: state.session.siteData,
        dimensions:state.session.dimensions,
    };
};


const mapDispatchToProps = (dispatch, ownProps) =>
{

    return {
        manageClient : (clientId) => {
            // console.log("manage client "+clientId);
            SessionManager.shared().goto("/client/"+clientId);
        }
    };
};

const CLSelectView = props =>{
        return (

            <div>
                {React.cloneElement(<ClientLibrarySelectView/>,props)}
            </div>
        );
};

const ClientLibraryHomeContainer = connect(mapStateToProps,mapDispatchToProps)(CLSelectView);

export default ClientLibraryHomeContainer
