/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AlertResult
 */
export interface AlertResult {
    /**
     * 
     * @type {string}
     * @memberof AlertResult
     */
    path?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertResult
     */
    relatedType?: AlertResultRelatedTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AlertResult
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof AlertResult
     */
    message?: string;
}


/**
 * @export
 */
export const AlertResultRelatedTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type AlertResultRelatedTypeEnum = typeof AlertResultRelatedTypeEnum[keyof typeof AlertResultRelatedTypeEnum];


/**
 * Check if a given object implements the AlertResult interface.
 */
export function instanceOfAlertResult(value: object): value is AlertResult {
    return true;
}

export function AlertResultFromJSON(json: any): AlertResult {
    return AlertResultFromJSONTyped(json, false);
}

export function AlertResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertResult {
    if (json == null) {
        return json;
    }
    return {
        
        'path': json['path'] == null ? undefined : json['path'],
        'relatedType': json['relatedType'] == null ? undefined : json['relatedType'],
        'count': json['count'] == null ? undefined : json['count'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function AlertResultToJSON(value?: AlertResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'path': value['path'],
        'relatedType': value['relatedType'],
        'count': value['count'],
        'message': value['message'],
    };
}

