import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import EnvidObjectIcon from "./EnvidObjectIcon";
import envid from "../../utils/envid";
import {element} from "prop-types";

// Table Column
// - header is displayed value
// - field is used by data as a reference
// - sortable allows the data to be sorted (currently string sort: getSortedData)
//   could implement optional custom sorting algorithms.
// - render is an optional custom rending function for the column to apply to the data
interface TableColumn {
    header: string | JSX.Element;
    field: string;
    sortable?: boolean;
    render?: (el: string | any) => JSX.Element; // Render function for custom components
    compare?: (a: any, b: any) => boolean; //test a >= b return true,  a < b return false
    style?: any;
}

//Table inputs
// - columns and data are the important ones
//Columns is an array that defines each column header for the table
// - field variable must match the label in the data record
// - data at a basic level stores string values for each column/field but
//   can store data objects or JSX Elements that get rendered by a columns
//   custom render

// --- IMPORTANT ---
// The field name customRow is a special field name that cant be used as a regular field name
// in this scenario you can define an entry in the data array to have a record
// {"customRow": value}
// where value is and entire custom row to be inserted into the table in the form: <tr> /*rowContent*/ </tr>

interface TableProps {
    columns: TableColumn[];
    data: Record<string, any>[];
    striped?: boolean;
    bordered?: boolean;
    hover?: boolean;
    responsive?: boolean;
    classCustom?: string;
}

interface SortCriteria {
    field: string;
    direction: 'asc' | 'desc';
}

interface TableState {
    sortCriteria: SortCriteria[];
}

class EBTable extends Component<TableProps, TableState> {
    constructor(props: TableProps) {
        super(props);
        this.state = {
            sortCriteria: [], // Array of multiple sort criteria
        };
    }

    //Add which columns to sort by
    handleSort = (field: string) => {
        const {sortCriteria} = this.state;
        const existingCriteria = sortCriteria.find((criteria) => criteria.field === field);

        // Cycle through: unsorted -> asc -> desc -> unsorted
        if (!existingCriteria) {
            // If the column isn't sorted, set to 'asc'
            this.setState({
                sortCriteria: [{field, direction: 'asc'}],//, ...sortCriteria],
            });
        } else if (existingCriteria.direction === 'asc') {
            // If currently 'asc', set to 'desc'
            this.setState({
                sortCriteria: sortCriteria.map((criteria) =>
                    criteria.field === field ? {...criteria, direction: 'desc'} : criteria
                ),
            });
        } else {
            // If currently 'desc', remove the sorting criteria (unsorted)
            this.setState({
                sortCriteria: sortCriteria.filter((criteria) => criteria.field !== field),
            });
        }
    };

    //Create sorted data for rendering
    getSortedData = () => {
        const {data, columns} = this.props;
        const {sortCriteria} = this.state;

        if (sortCriteria.length === 0) return data;

        return [...data].sort((a, b) => {
            for (let ii = 0; ii < sortCriteria.length; ii++) {
                const {field, direction} = sortCriteria[ii];
                let col = columns.find(col => col.field === field);
                if (!!col?.compare) {
                    if (col.compare(a[field], b[field])) return direction === 'asc' ? 1 : -1;
                    if (!col.compare(a[field], b[field])) return direction === 'asc' ? -1 : 1;
                } else {
                    if (a[field] < b[field]) return direction === 'asc' ? -1 : 1;
                    if (a[field] > b[field]) return direction === 'asc' ? 1 : -1;
                }
            }
            return 0;
        });
    };

    render() {
        const {columns, striped, bordered, hover, responsive, classCustom} = this.props;
        const sortedData = this.getSortedData();
        const {sortCriteria} = this.state;

        const tableClass = `
                  table
                  ${striped ? 'table-striped' : ''}
                  ${bordered ? 'table-bordered' : ''}
                  ${hover ? 'table-hover' : ''}
                  ${responsive ? 'table-responsive' : ''}
                  ${classCustom ?? ''}
                `.trim();

        //Sorting chevrons
        const ascending = <div className="d-flex flex-column">
            <i className="fa fa-chevron-up fa-xs text-primary p-1"></i>
            <i className="fa fa-chevron-down fa-xs p-1"></i>
        </div>;
        const descending = <div className="d-flex flex-column">
            <i className="fa fa-chevron-up fa-xs p-1"></i>
            <i className="fa fa-chevron-down fa-xs text-primary p-1"></i>
        </div>;
        const unsorted = <div className="d-flex flex-column">
            <i className="fa fa-chevron-up fa-xs p-1"></i>
            <i className="fa fa-chevron-down fa-xs p-1"></i>
        </div>;

        return (
            <table className={tableClass}>
                <thead>
                <tr>
                    {columns.map((col, index) => {
                        const sortForColumn = sortCriteria.find((criteria) => criteria.field === col.field);
                        return (
                            <th
                                className={"bg-body-secondary font-bold"}
                                key={index}
                                onClick={() => col.sortable && this.handleSort(col.field)}
                                style={{cursor: col.sortable ? 'pointer' : 'default', ...col.style}}
                            >
                                <div className="d-flex w-100">
                                    {col.header} &nbsp;
                                    {col.sortable && ((sortForColumn) ? (sortForColumn.direction === 'asc' ? ascending : descending) : unsorted)}
                                </div>
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {sortedData.map((row, rowIndex) => {
                    if (!!row && !!row["customRow"]) {
                        return (<>{row["customRow"]}</>);
                    }
                    return (
                        <tr key={rowIndex}>
                            {columns.map((col, colIndex) => (
                                <td key={colIndex}>
                                    {col.render ? col.render(row[col.field]) : row[col.field]}
                                </td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }
}

export default EBTable;


// --- Sample table for use in parent component ---
//
// import EBTable from "../components/common/EBTable";
// import envid from "../utils/envid";
// import {element} from "prop-types";

// const columns = [
//     { header: 'ID', field: 'id', sortable: true  },
//     { header: 'Object Type', field: 'objectType', sortable: true, render: (re) => (<EnvidObjectIcon type={re} showName={true}/>)},
//     { header: 'Object Name', field: 'objectName', sortable: true  },
//     { header: 'State', field: 'state', sortable: true , render: (st) => <div style={{...envid.getPublishTextStyle(st), width: 'fit-content', fontSize: "12px"}} className="rounded-2 p-1">{st}</div> },
//     {
//         header: <input className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"/>,
//         field: 'test3',
//         render: (row) => <input className="form-check-input mt-0" type="checkbox" value="" onClick={() => {console.log('where am i:', this, row); this.state.selectedRows.push(element.id)}}
//                                 aria-label="Checkbox for following text input"/>
//     },
// ];
//
// const data = [
//     {id: 1, objectType: 'activity', objectName: 'ROV Operations' , state: 'Approved' },
//     {id: 2, objectType: 'aspect', objectName: 'Underwater Sound Emissions – Continuous' , state: 'Submitted' },
//     {id: 3, objectType: 'receptor', objectName: 'Whales' , state: 'Reviewed'},
//     {id: 4, objectType: 'environmentalinput', objectName: 'Seismic Survey' , state: 'Pending' },
//     {id: 5, objectType: 'control', objectName: 'Draft' , state: 'Draft' },
// ];
//
// <div className="p-4">
//     <EBTable
//         columns={columns}
//         data={data} hover responsive></EBTable>
// </div>
