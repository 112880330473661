/**
 * Created by lewellyn on 14/12/16.
 */
import React from 'react'
import ebase from '../../utils/ajax'
import DataManage from '../../views/DataManage'
import SessionManager from "../../utils/SessionManager";

class DataManageContainer extends React.Component{


    constructor(props){
        super(props);
        this.loadDeleteCheckInfo = this.loadDeleteCheckInfo.bind(this);
        this.loadUserList = this.loadUserList.bind(this);

        this.state = {
            checkInfo : null,
            loading  : null,
            userList: null,
            tempObjectOwner: {value: this.props.currentObject.object.ownerId, label: this.props.currentObject.object.ownerName},
        };

    }

    doDelete()
    {
        this.setState({updating:true});

        ebase.ajax({
           url:"/ebase/envid-admin/write-enviddata-delete",
            data:{objectId:this.props.currentObject.object.sharedId},
            success: this.deleteSuccess.bind(this),
            error: this.deleteError.bind(this)

        });
    }

    doRestore()
    {
        this.setState({updating:true});
        const ebaseDs = SessionManager.shared().getEbaseDS();

        ebaseDs.restoreDeletedObject(this.props.currentObject.object.sharedId).then(result => {
            // console.log('Restored deleted obj::', result);
            //Set current object here
            this.props.updateObject(result);

            this.setState({updating:false});
        }).catch(err => {
            // console.log('Restoring error:: ', err);
            this.setState({updating:false});
        })
    }

    deleteSuccess(result){
        // go back to the search screen
        if (result.success) {
            // this.context.router.push("/enviddata/objects");
            //dispatch SEARCH_SET_CURRENT_OBJECT
            SessionManager.shared().dispatch({type:"SEARCH_SET_CURRENT_OBJECT",data:null});
            SessionManager.shared().goto("/enviddata");
        } else {
            this.setState({checkInfo: {canDelete:false, error:"Error deleting object - " + result.error}, loading: null})
        }
    }

    deleteError(result){
        // console.log("update error: "+result);
        this.setState({checkInfo: {canDelete:false, error:"Error deleting object, try again later."}, loading: null})
    }

    changeObjectOwner() {
        this.setState({updating:true});
        const ebaseDs = SessionManager.shared().getEbaseDS();

        ebaseDs.changeObjectOwner(this.props.currentObject.object.sharedId, this.state.tempObjectOwner.value).then(result => {
            // console.log('Changed obj owner::', result);
            //Set current object here\
            this.props.updateObject(result);

            this.setState({updating:false});
        }).catch(err => {
            // console.log('Changed obj owner error:: ', err);
            this.setState({updating:false});
        })
    }

    changeTempOwner(owner) {
        this.setState({tempObjectOwner: owner});
    }

    getObjectId()
    {
        return this.props.currentObject.object.sharedId;
    }

    loadDeleteCheckInfo()
    {
        var request = {
            objectId:this.getObjectId(),
        }

        ebase.ajax({
            url:"/ebase/envid-admin/read-enviddata-deletecheck",
            data:request,
            success: this.checkInfoSuccess.bind(this, request),
            error:   () => this.updateLinkInfo.setState({checkInfo: {canDelete:false, error:"Error checking object status"}, loading: null})
        });
    }

    loadUserList(){
        const ebaseDs = SessionManager.shared().getEbaseDS();
        ebaseDs.getUsersWithAccessToCurrentDataset().then(res => {
            let currOwner = res.find((u) => (u.userId === this.state.tempObjectOwner.value));

            const onlyActive = res.filter(o => o.active);
            if (!!currOwner) {
                this.setState({
                    userList: onlyActive,
                    tempObjectOwner: {value: this.state.tempObjectOwner.value, label: currOwner.firstname + " " + currOwner.lastname + (currOwner.email ? (" - " + currOwner.email) : "")},
                });
            }else{
                this.setState({
                    userList: onlyActive,
                });
            }
        }).catch( err => {
            this.setState({userList:[]});
            SessionManager.shared().showToast("Loading Error",err, 'danger');
        })
    }

    reload(){
        this.setState({loading:true});
        this.loadDeleteCheckInfo();
    }

    checkInfoSuccess(request, result)
    {
        if (result.success) {
            this.setState({checkInfo: result.data, loading: null});
        }
    }

    componentDidMount() {
        if (this.state.checkInfo == null )
        {
            // show the spinner, and request they be loaded
            if (this.state.loading == null || this.state.loading !== this.getObjectId()) {
                this.loadDeleteCheckInfo();
                this.loadUserList();
                this.state.loading = this.getObjectId();
            }
        }
    }


    render()
    {
        return (

                <DataManage currentObject={this.props.currentObject}
                            updating={this.state.updating}
                            loading={this.state.loading!=null || this.state.userList == null}
                            deleteCheckInfo={this.state.checkInfo}
                            userList={this.state.userList}
                            doDelete = {this.doDelete.bind(this)}
                            doRestore={this.doRestore.bind(this)}
                            changeObjectOwner={this.changeObjectOwner.bind(this)}
                            siteData={this.props.siteData}
                            height={this.props.height}
                            reload={this.reload.bind(this)}
                            tempObjectOwner={this.state.tempObjectOwner}
                            changeTempOwner={this.changeTempOwner.bind(this)}
                />

        );
    }


}

export default DataManageContainer


