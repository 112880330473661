/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EbaseResultMapStringNumber
 */
export interface EbaseResultMapStringNumber {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultMapStringNumber
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultMapStringNumber
     */
    error?: string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof EbaseResultMapStringNumber
     */
    data?: { [key: string]: number; };
}

/**
 * Check if a given object implements the EbaseResultMapStringNumber interface.
 */
export function instanceOfEbaseResultMapStringNumber(value: object): value is EbaseResultMapStringNumber {
    return true;
}

export function EbaseResultMapStringNumberFromJSON(json: any): EbaseResultMapStringNumber {
    return EbaseResultMapStringNumberFromJSONTyped(json, false);
}

export function EbaseResultMapStringNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultMapStringNumber {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : json['data'],
    };
}

export function EbaseResultMapStringNumberToJSON(value?: EbaseResultMapStringNumber | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': value['data'],
    };
}

