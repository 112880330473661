/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnvidObjectList } from './EnvidObjectList';
import {
    EnvidObjectListFromJSON,
    EnvidObjectListFromJSONTyped,
    EnvidObjectListToJSON,
} from './EnvidObjectList';

/**
 * 
 * @export
 * @interface ReportTaskResultObjectLists
 */
export interface ReportTaskResultObjectLists {
    [key: string]: EnvidObjectList | any;
    /**
     * 
     * @type {boolean}
     * @memberof ReportTaskResultObjectLists
     */
    empty?: boolean;
}

/**
 * Check if a given object implements the ReportTaskResultObjectLists interface.
 */
export function instanceOfReportTaskResultObjectLists(value: object): value is ReportTaskResultObjectLists {
    return true;
}

export function ReportTaskResultObjectListsFromJSON(json: any): ReportTaskResultObjectLists {
    return ReportTaskResultObjectListsFromJSONTyped(json, false);
}

export function ReportTaskResultObjectListsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTaskResultObjectLists {
    if (json == null) {
        return json;
    }
    return {
        
            ...json,
        'empty': json['empty'] == null ? undefined : json['empty'],
    };
}

export function ReportTaskResultObjectListsToJSON(value?: ReportTaskResultObjectLists | null): any {
    if (value == null) {
        return value;
    }
    return {
        
            ...value,
        'empty': value['empty'],
    };
}

