/**
 * Created by lewellyn on 4/9/18.
 */
import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import {loadUsersStart, loadUsersDone} from '../../redux/actions/actions'
import ebase from "../../utils/ajax";
import UsersView from "../../views/UsersView";
import SessionManager from "../../utils/SessionManager";
import UserManageContainer from "./UserManageContainer";



/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{


    return {
        theusers: state.users.userList,
        isLoading: state.users.isLoading,
        doLoad : state.users.userList==null && state.users.isLoading === false,
        dimensions: state.session.dimensions,
    }
};

const mapDispatchToProps = (dispatch, ownProps) =>
{

    return {
        load : () => {

            dispatch(loadUsersStart());
            ebase.ajax({
                url: "/ebase/core/read-user-list",
                contentType: "application/json; charset=utf-8",
                data:{},
                success: (result) => dispatch(loadUsersDone(result)),
                error: (result) => ownProps.showLoadError("Unable to load Users", result)
            })},

        userClicked: (userId) => {console.log("user clicked: "+userId);},

        manageUser : (user_id) => {
            // console.log("manage user: "+user_id);
            //console.log(ownProps);
            // ownProps.routerPush("/users/manage/"+user_id);

            SessionManager.shared().goto("/users/manage/"+user_id);
        }


    }

};


const UsrHomeView = (props) =>
{

    // console.log("UsersHomeContainer:: - UsrHomeView ",props);

    let path = props.location.pathname;

    if (path === '/users'){
        return (
            <Fragment>
                {React.cloneElement(<UsersView />, props)}
            </Fragment>
        )
    }

    if (path.startsWith('/users/manage')){
        return (
            <Fragment>
                {React.cloneElement(<UserManageContainer />, props)}
            </Fragment>
        )
    }

    return (
        <div style={{backgroundColor:'white', color:'black'}}>
            what to do
            {JSON.stringify(props.location)}
        </div>
    )

// <Route path="users" component={UsersHomeContainer}>
//                                 <IndexRedirect to="/users/list" />
//                                 <Route path="list" component={UsersView}/>
//                                 <Route path="manage/:userId" component={UserManageContainer}/>
//                             </Route>



}


const UsersHomeContainer = connect(mapStateToProps, mapDispatchToProps)(UsrHomeView);

export default UsersHomeContainer
