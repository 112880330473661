import React from 'react';
import ProjectMilestone from '../../views/ProjectMilestone'

import ebase from '../../utils/ajax'
import { connect } from 'react-redux'

import {loadProjectObjectsDone, loadProjectObjectsStart, loadProjectObjectsError} from '../../redux/actions/actions'
import {newProjectObjectDone, updateProjectObjectField, newProjectObjectError} from '../../redux/actions/actions'
import {startProjectObjectEdit}  from '../../redux/actions/actions'
import SessionManager from "../../utils/SessionManager";

const object_type = "ProjectMilestone";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{
    let parent = state.projects.ProjectMilestone;
    if (parent == null)
    {
        parent = {isLoading:false, objects:[]};
    }
    return {
        isLoading: parent.isLoading,
        editing_id: parent.editing_id,
        milestones: parent.objects,
        project:state.projects.currentObject.object
    }
}


const newObject = function(props, dispatch)
{
    let project_id = props.currentObject.object.objectId;

    //dispatch(loadProjectObjectsStart(object_type));

    ebase.ajax({
        url:"/ebase/core/write-project-newobject",
        data:{project_id:project_id, type:object_type},
        success:(result) => {

            if (result.success) {
                dispatch(newProjectObjectDone(object_type, result.data));
            } else {
                dispatch(newProjectObjectError(object_type, result.error));
                props.showLoadError("Error Loading Milestones", result);
            }
        },
        error :(result) => {
            dispatch(newProjectObjectError(object_type, result.error));
            props.showLoadError("Error Loading Milestones", result);
        }

    });


}

const loadObjects = function(props, dispatch)
{
    let project_id = props.currentObject.object.objectId;
    dispatch(loadProjectObjectsStart(object_type));

    ebase.ajax({
        url:"/ebase/core/read-project-objects",
        data:{project_id : project_id, type:object_type},
        success:(result) => {

            if (result.success) {
                dispatch(loadProjectObjectsDone(object_type, result.data));
            } else {
                dispatch(loadProjectObjectsError(object_type, result.error));
                props.showLoadError("Error Loading Milestones", result);
            }
        },
        error :(result) => {
            dispatch(loadProjectObjectsError(object_type, result.error));
            props.showLoadError("Error Loading Milestones", result);
        }

    });

}

const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {
        loadObjects : () => {

            loadObjects(ownProps, dispatch);
        },

        newObject : () => {
            newObject(ownProps, dispatch);
        },

        startEdit : (object_id) => {
            dispatch(startProjectObjectEdit(object_type, object_id));
        },

        updateField : (object_id, fieldName, fieldValue) =>
        {
            dispatch(updateProjectObjectField(object_type, object_id, fieldName, fieldValue));
        },


    }
}

class ProjectMilestoneVw extends React.Component{
    render() {


        return  <div>
            {React.cloneElement(<ProjectMilestone/>, this.props)}
        </div>

    }
    componentDidMount()
    {

        this.props.loadObjects();

    }

};

const ProjectMilestoneContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectMilestoneVw)

export default ProjectMilestoneContainer
