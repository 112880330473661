/**
 * Created by lewellyn on 14/12/16.
 */
import React from 'react'
import ebase from '../../utils/ajax'
import update from 'immutability-helper';
import DataReferences from '../../views/DataReferences'
import SessionHelper from "../../utils/SessionHelper";
import SessionManager from "../../utils/SessionManager";

class DataReferencesContainer extends React.Component{


    constructor(props){
        super(props);

        this.state = {allReferences:[],filteredReferences:[],filterString:"",loadingReferences:false};

        this.addReference = this.addReference.bind(this);
        this.removeReference = this.removeReference.bind(this);
        this.getAllReferences = this.getAllReferences.bind(this);
        this.filterReferences = this.filterReferences.bind(this);
        // console.log('DataReferencesContainer', this.props);


        this.checkedOut = SessionHelper.shared().isObjectCheckedOutToSessionUser(this.props.currentObject?.object);
    }

    getAllReferences(){
        //console.log("get all references");
        this.setState({allReferences:[],loadingReferences:true})
        ebase.ajax({
           url:"/ebase/envid-admin/read-enviddata-objectsbytype",
            data:{type:"Reference"},
            success:this.getReferencesSuccess.bind(this),
            error:(result)=> {
               console.log(result);
                this.setState({loadingReferences:false});
            }
        });

    }

    getReferencesSuccess(result){

        //console.log("result: "+JSON.stringify(result));

        let uniqueRefs = [];

        if(result.success) {

            const refids = this.props.currentObject.object.references;

            const allRefs = result.data;

            allRefs.forEach((ref) => {
                if (!refids.includes(ref.sharedId)) {
                    uniqueRefs.push(ref);
                }

            });
        }else {
            console.log(result.error);
        }

        this.setState({allReferences:uniqueRefs,loadingReferences:false});
    }

    addReference(ref_id,reference){
        // console.log("add ref_id: "+ref_id + ", reference: "+reference);
        this.props.updateSavedStatus(this.props.currentObject.object.sharedId, "saving");
        ebase.ajax({
            url:"/ebase/envid-admin/write-enviddata-addreference",
            data:{"objectId":this.props.currentObject.object.sharedId,"referenceId":ref_id,"reference":reference},
            success:(result) => {
                if(result.success){
                    let updatedObject = update(this.props.currentObject, {object: {references: {$push:[result.data]}}});
                    updatedObject.references[result.data]={"reference":reference};
                    if (updatedObject.object.versionDetails.state !== 'Working') {
                        updatedObject.object.versionDetails.state = 'Working';
                        updatedObject.object.publishingInfo.state = 'Draft';
                    }
                    this.props.updateObject(updatedObject);
                    this.props.updateSavedStatus(this.props.currentObject.object.sharedId, "saved");

                }else{
                    console.log(result.error);
                    this.props.updateSavedStatus(this.props.currentObject.object.sharedId, "error");
                    SessionManager.shared().showToast("Error adding Reference",result.error, 'danger');
                }
            },
            error:(result)=>{
                console.log(result);
                this.props.updateSavedStatus(this.props.currentObject.object.sharedId, "error");
                SessionManager.shared().showLoadError("Error adding Reference",result);
            }
        });

    }

    removeReference(ref_id){
        // console.log("remove ref_id: "+ref_id);

        this.props.updateSavedStatus(this.props.currentObject.object.sharedId, "saving");
        ebase.ajax({
            url:"/ebase/envid-admin/write-enviddata-removereference",
            data:{"objectId":this.props.currentObject.object.sharedId,"referenceId":ref_id,"reference":null},
            success:(result) =>{
                if(result.success){

                    let refs = this.props.currentObject.object.references;
                    let index = refs.indexOf(ref_id);
                    refs.splice(index,1);

                    let updatedObject = update(this.props.currentObject, {object: {references: {$set:refs}}});

                    delete updatedObject.references[ref_id];

                    if (updatedObject.object.versionDetails.state !== 'Working') {
                        updatedObject.object.versionDetails.state = 'Working';
                        updatedObject.object.publishingInfo.state = 'Draft';
                    }

                    this.props.updateObject(updatedObject);
                    this.props.updateSavedStatus(this.props.currentObject.object.sharedId, "saved");

                }else{
                    this.props.updateSavedStatus(this.props.currentObject.object.sharedId, "error");
                    SessionManager.shared().showToast("Error removing Reference",result.error, 'danger');
                }
            },
            error:(result)=>{
                console.log(result);
                this.props.updateSavedStatus(this.props.currentObject.object.sharedId, "error");
                SessionManager.shared().showLoadError("Error removing Reference",result);
            }

        });

    }

    filterReferences(filter) {
        //console.log("filter references: " + filter);
        this.setState({filterString: filter});

        if (filter != null && filter !== "") {


            let filtered = this.state.allReferences.filter((ref) => ref.name.includes(filter));

            //console.log("filtered: "+JSON.stringify(filtered));

            this.setState({filteredReferences:filtered});
        }else{
            this.setState({filteredReferences:[]});
        }


    }

    render() {
        this.checkedOut = SessionHelper.shared().isObjectCheckedOutToSessionUser(this.props.currentObject?.object);

        return(
            <DataReferences
                allReferences={this.state.allReferences}
                objectReferences={this.props.currentObject.references}
                refIds={this.props.currentObject.object.references}
                doAdd={this.addReference}
                doRemove={this.removeReference}
                doGetAllReferences = {this.getAllReferences}
                loadingReferences={this.state.loadingReferences}
                filterString = {this.state.filterString}
                doFilterReferences = {this.filterReferences}
                filteredReferences = {this.state.filteredReferences}
                disabled={!this.checkedOut || !! this.props.currentObject.object.deleteState}
            />
        );

    }

}
export default DataReferencesContainer
