/**
 * @param {ManageReducerState} state - The current state.
 * @param {ManageReducerAction} action - The action object.
 * @returns {ManageReducerState} The new state after applying the action.
 */


export default function manage (state = {
  isLoading: false,
  currentCheckedOutObject: null,
  changedObjects: [],
  historyRecords: [],
  historyVersionObjects: [],
  publishedVersion: null,
  ownerOptions: [],
}, action) {

  switch (action.type) {
    case("CHECKOUT_OBJECT_START"):{
      return {...state, isLoading: true, currentCheckedOutObject: null}
    }

    case("CHECKOUT_OBJECT_DONE"):{
      // action.data should be a EnvidObjectResult
      return {...state, isLoading: false, currentCheckedOutObject: action.data}
    }

    case("CHECKIN_OBJECT_START"):{
      return {...state, isLoading: true, currentCheckedOutObject: null}
    }

    case("CHECKIN_OBJECT_DONE"):{
      return {...state, isLoading: false}
    }

    case("SET_CURRENT_CHECKOUT_OBJECT"):{
      // action.data should be a EnvidObjectResult
      return {...state, currentCheckedOutObject: action.data}
    }

    case("LOAD_HISTORY_VERSION_START"):{
      return {...state, isLoading: true, historyVersionObjects: []}
    }

    case("LOAD_HISTORY_VERSION_DONE"):{
      return {...state, isLoading: false, historyVersionObjects: action.data}
    }

    case("LOAD_PUBLISH_INFO_START"):{
      return {...state, isLoading: true, historyRecords: [], changedObjects: [], publishedVersion: null}
    }

    case("LOAD_PUBLISH_INFO_DONE"):{
      return {...state, isLoading: false, historyRecords: action.data.versionHistory ?? [], changedObjects: action.data.changedObjects ?? [], publishedVersion:action.data.publishedVersion}
    }

    case("LOAD_OWNER_INFO_START"):{
      return {...state, isLoading: true, ownerOptions: []}
    }

    case("LOAD_OWNER_INFO_DONE"):{
      if (!!action.data) {
        const onlyActive = action.data.filter(o => o.active);
        return {...state, isLoading: false, ownerOptions: onlyActive}
      }
      return {...state, isLoading: false, ownerOptions: []}
    }

    case("UPDATE_CHECKOUT_OBJECT_VERSION_STATUS"): {

      // this is going to have either the same object as current checked out object or a link object that
      // is linked to the current checked out object.
      // Because changing a link object also creates a working version of the primary object we need to handle
      // the case where the user checks out the primary object in a published state but updates a link details.
      if (!!action.data && !!state.currentCheckedOutObject){
        const update = action.data; // EnvidObjectResult
        const next = {...state.currentCheckedOutObject};
        next.object.versionDetails.state = update.object.versionDetails.state;
        next.object.publishingInfo.state = update.object.publishingInfo.state
        return {...state, currentCheckedOutObject: next};
      }

      return state;
    }

  }

  return state;

}
