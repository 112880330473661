import ebase from "./ajax"

let isLite = false;

export default {

    getIsLite(){
        return isLite;
    },
    setIsListe(lite){
        isLite = lite;
    },

    /**
     * Login, takes an email & password.
     *
     * @param email
     * @param password
     * @param thecallback - function(user, error)
     */
    loginSession(email, password, thecallback)
    {
        let pathComp = 'auth';
        if(isLite){
            pathComp = 'lite-auth';
        }

        ebase.ajax({
            url: "/ebase/"+pathComp+"/authenticate",
            data: {username:email,password:password},
            contentType: "application/json; charset=utf-8",
            success: function(result) {
                // console.log("loginSession success");
                // console.log(result);
                // console.log("-------");
                if (result.success) {
                    thecallback(result.data, null);
                } else {
                    thecallback(null, result.error);
                }
            },
            error: (err) => {
                let msg = "Connection Error";
                if (err != null){
                    msg = msg + ": "+err.status + " "+err.statusText;
                }
                thecallback(null, msg);
            }
        });
    },


    setSessionAccess(clientId,datasetId, thecallback){
        // console.log("set session access "+clientId + " | "+datasetId);
        ebase.ajax({
            url: "/ebase/auth/setSessionAccess",
            data: {clientId:clientId,datasetId:datasetId},
            contentType: "application/json; charset=utf-8",
            success: function(result) {
                // console.log("set Session access success");
                // console.log(result);
                // console.log("-------");
                thecallback(result, null);
            }
        });
    },

    /**
     * Checks if the user is logged into the current session
     *
     * @param callback - function(user, error)
     */
    checkSession(callback)
    {

        let pathComp = 'auth';
        if(isLite){
            pathComp = 'lite-auth';
        }

        ebase.ajax({
            url: "/ebase/"+pathComp+"/checkSession",
            contentType: "application/json; charset=utf-8",
            data:{},
            success: function(result) {
                if (result.success) {
                    callback(result.data, null);
                } else {
                    callback(null, result.error);
                }
            },
            error: (err) => {
                let msg = "Connection Error";
                if (err != null){
                    msg = msg + ": "+err.status + " "+err.statusText;
                }
                callback(null, msg);
            }
        });

    },

    logoutSession(){
        let pathComp = 'auth';
        if(isLite){
            pathComp = 'lite-auth';
        }
        ebase.ajax({
            url:"/ebase/"+pathComp+"/logout",
            data:{},
            success: function(result){
                console.log("logout result: "+result);
            }

        });
    }
}
