/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewProjectRoleRequest
 */
export interface NewProjectRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof NewProjectRoleRequest
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectRoleRequest
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectRoleRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectRoleRequest
     */
    userId?: string;
}

/**
 * Check if a given object implements the NewProjectRoleRequest interface.
 */
export function instanceOfNewProjectRoleRequest(value: object): value is NewProjectRoleRequest {
    return true;
}

export function NewProjectRoleRequestFromJSON(json: any): NewProjectRoleRequest {
    return NewProjectRoleRequestFromJSONTyped(json, false);
}

export function NewProjectRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewProjectRoleRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['object_id'] == null ? undefined : json['object_id'],
        'projectId': json['project_id'] == null ? undefined : json['project_id'],
        'name': json['name'] == null ? undefined : json['name'],
        'userId': json['user_id'] == null ? undefined : json['user_id'],
    };
}

export function NewProjectRoleRequestToJSON(value?: NewProjectRoleRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_id': value['objectId'],
        'project_id': value['projectId'],
        'name': value['name'],
        'user_id': value['userId'],
    };
}

