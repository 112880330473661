/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetDatasetRequest
 */
export interface SetDatasetRequest {
    /**
     * 
     * @type {string}
     * @memberof SetDatasetRequest
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof SetDatasetRequest
     */
    datasetId?: string;
}

/**
 * Check if a given object implements the SetDatasetRequest interface.
 */
export function instanceOfSetDatasetRequest(value: object): value is SetDatasetRequest {
    return true;
}

export function SetDatasetRequestFromJSON(json: any): SetDatasetRequest {
    return SetDatasetRequestFromJSONTyped(json, false);
}

export function SetDatasetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetDatasetRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'clientId': json['clientId'] == null ? undefined : json['clientId'],
        'datasetId': json['datasetId'] == null ? undefined : json['datasetId'],
    };
}

export function SetDatasetRequestToJSON(value?: SetDatasetRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clientId': value['clientId'],
        'datasetId': value['datasetId'],
    };
}

