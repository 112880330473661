/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EbaseResult,
  EbaseResultArrayListUpdateFieldResult,
  EbaseResultCollatedObjectSummary,
  EbaseResultDeleteCheckResult,
  EbaseResultEnvidObjectResult,
  EbaseResultHashMapStringObject,
  EbaseResultListAuditEventPublic,
  EbaseResultListEnvidObjectSmallResult,
  EbaseResultListLinkListResult,
  EbaseResultString,
  EbaseResultSummaryResult,
  LinkListRequest,
  LinkRequest,
  NewObjectRequest,
  NewReferenceRequest,
  ObjectRequest,
  SearchRequest,
  SummaryRequest,
  UpdateFieldRequest,
} from '../models/index';
import {
    EbaseResultFromJSON,
    EbaseResultToJSON,
    EbaseResultArrayListUpdateFieldResultFromJSON,
    EbaseResultArrayListUpdateFieldResultToJSON,
    EbaseResultCollatedObjectSummaryFromJSON,
    EbaseResultCollatedObjectSummaryToJSON,
    EbaseResultDeleteCheckResultFromJSON,
    EbaseResultDeleteCheckResultToJSON,
    EbaseResultEnvidObjectResultFromJSON,
    EbaseResultEnvidObjectResultToJSON,
    EbaseResultHashMapStringObjectFromJSON,
    EbaseResultHashMapStringObjectToJSON,
    EbaseResultListAuditEventPublicFromJSON,
    EbaseResultListAuditEventPublicToJSON,
    EbaseResultListEnvidObjectSmallResultFromJSON,
    EbaseResultListEnvidObjectSmallResultToJSON,
    EbaseResultListLinkListResultFromJSON,
    EbaseResultListLinkListResultToJSON,
    EbaseResultStringFromJSON,
    EbaseResultStringToJSON,
    EbaseResultSummaryResultFromJSON,
    EbaseResultSummaryResultToJSON,
    LinkListRequestFromJSON,
    LinkListRequestToJSON,
    LinkRequestFromJSON,
    LinkRequestToJSON,
    NewObjectRequestFromJSON,
    NewObjectRequestToJSON,
    NewReferenceRequestFromJSON,
    NewReferenceRequestToJSON,
    ObjectRequestFromJSON,
    ObjectRequestToJSON,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    SummaryRequestFromJSON,
    SummaryRequestToJSON,
    UpdateFieldRequestFromJSON,
    UpdateFieldRequestToJSON,
} from '../models/index';

export interface AddReferenceRequest {
    newReferenceRequest?: NewReferenceRequest;
}

export interface ChangeObjectOwnerRequest {
    sharedId: string;
    objectRequest?: ObjectRequest;
}

export interface CheckinObjectRequest {
    sharedId: string;
}

export interface CheckoutObjectRequest {
    sharedId: string;
}

export interface DeleteObjectRequest {
    objectRequest?: ObjectRequest;
}

export interface DeleteObjectCheckRequest {
    objectRequest?: ObjectRequest;
}

export interface GetChangeHistoryRequest {
    sharedId: string;
    version?: number;
}

export interface GetEnvidData1Request {
    objectRequest?: ObjectRequest;
}

export interface GetEnvidData2Request {
    searchRequest?: SearchRequest;
}

export interface GetObjectsByTypeRequest {
    searchRequest?: SearchRequest;
}

export interface GetVersionChangesRequest {
    sharedId: string;
}

export interface GetWorkflowHistoryRequest {
    sharedId: string;
    version?: number;
}

export interface NewEnvidDataRequest {
    newObjectRequest?: NewObjectRequest;
}

export interface NewLinkRequest {
    linkRequest?: LinkRequest;
}

export interface ReadLinkListRequest {
    linkListRequest?: LinkListRequest;
}

export interface ReadSummaryRequest {
    summaryRequest?: SummaryRequest;
}

export interface RemoveLinkRequest {
    linkRequest?: LinkRequest;
}

export interface RemoveReferenceRequest {
    newReferenceRequest?: NewReferenceRequest;
}

export interface RestoreDeletedObjectRequest {
    objectRequest?: ObjectRequest;
}

export interface UpdateFields2Request {
    updateFieldRequest?: UpdateFieldRequest;
}

/**
 * EbaseEnvidAdminWSApi - interface
 * 
 * @export
 * @interface EbaseEnvidAdminWSApiInterface
 */
export interface EbaseEnvidAdminWSApiInterface {
    /**
     * 
     * @param {NewReferenceRequest} [newReferenceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    addReferenceRaw(requestParameters: AddReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultString>>;

    /**
     */
    addReference(newReferenceRequest?: NewReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultString>;

    /**
     * 
     * @param {string} sharedId 
     * @param {ObjectRequest} [objectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    changeObjectOwnerRaw(requestParameters: ChangeObjectOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    changeObjectOwner(sharedId: string, objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {string} sharedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    checkinObjectRaw(requestParameters: CheckinObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    checkinObject(sharedId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {string} sharedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    checkoutObjectRaw(requestParameters: CheckoutObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    checkoutObject(sharedId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    createReferenceDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>>;

    /**
     */
    createReferenceData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult>;

    /**
     * 
     * @param {ObjectRequest} [objectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    deleteObjectRaw(requestParameters: DeleteObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>>;

    /**
     */
    deleteObject(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult>;

    /**
     * 
     * @param {ObjectRequest} [objectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    deleteObjectCheckRaw(requestParameters: DeleteObjectCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultDeleteCheckResult>>;

    /**
     */
    deleteObjectCheck(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultDeleteCheckResult>;

    /**
     * 
     * @param {string} sharedId 
     * @param {number} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    getChangeHistoryRaw(requestParameters: GetChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListAuditEventPublic>>;

    /**
     */
    getChangeHistory(sharedId: string, version?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListAuditEventPublic>;

    /**
     * 
     * @param {ObjectRequest} [objectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    getEnvidData1Raw(requestParameters: GetEnvidData1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    getEnvidData1(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {SearchRequest} [searchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    getEnvidData2Raw(requestParameters: GetEnvidData2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultHashMapStringObject>>;

    /**
     */
    getEnvidData2(searchRequest?: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultHashMapStringObject>;

    /**
     * 
     * @param {SearchRequest} [searchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    getObjectsByTypeRaw(requestParameters: GetObjectsByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListEnvidObjectSmallResult>>;

    /**
     */
    getObjectsByType(searchRequest?: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListEnvidObjectSmallResult>;

    /**
     * 
     * @param {string} sharedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    getVersionChangesRaw(requestParameters: GetVersionChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultCollatedObjectSummary>>;

    /**
     */
    getVersionChanges(sharedId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultCollatedObjectSummary>;

    /**
     * 
     * @param {string} sharedId 
     * @param {number} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    getWorkflowHistoryRaw(requestParameters: GetWorkflowHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListAuditEventPublic>>;

    /**
     */
    getWorkflowHistory(sharedId: string, version?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListAuditEventPublic>;

    /**
     * 
     * @param {NewObjectRequest} [newObjectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    newEnvidDataRaw(requestParameters: NewEnvidDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    newEnvidData(newObjectRequest?: NewObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {LinkRequest} [linkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    newLinkRaw(requestParameters: NewLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    newLink(linkRequest?: LinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {LinkListRequest} [linkListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    readLinkListRaw(requestParameters: ReadLinkListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListLinkListResult>>;

    /**
     */
    readLinkList(linkListRequest?: LinkListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListLinkListResult>;

    /**
     * 
     * @param {SummaryRequest} [summaryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    readSummaryRaw(requestParameters: ReadSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSummaryResult>>;

    /**
     */
    readSummary(summaryRequest?: SummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSummaryResult>;

    /**
     * 
     * @param {LinkRequest} [linkRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    removeLinkRaw(requestParameters: RemoveLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultString>>;

    /**
     */
    removeLink(linkRequest?: LinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultString>;

    /**
     * 
     * @param {NewReferenceRequest} [newReferenceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    removeReferenceRaw(requestParameters: RemoveReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>>;

    /**
     */
    removeReference(newReferenceRequest?: NewReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    reportTestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>>;

    /**
     */
    reportTest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult>;

    /**
     * 
     * @param {ObjectRequest} [objectRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    restoreDeletedObjectRaw(requestParameters: RestoreDeletedObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    restoreDeletedObject(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {UpdateFieldRequest} [updateFieldRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseEnvidAdminWSApiInterface
     */
    updateFields2Raw(requestParameters: UpdateFields2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultArrayListUpdateFieldResult>>;

    /**
     */
    updateFields2(updateFieldRequest?: UpdateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultArrayListUpdateFieldResult>;

}

/**
 * 
 */
export class EbaseEnvidAdminWSApi extends runtime.BaseAPI implements EbaseEnvidAdminWSApiInterface {

    /**
     */
    async addReferenceRaw(requestParameters: AddReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultString>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/write-enviddata-addreference`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewReferenceRequestToJSON(requestParameters['newReferenceRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultStringFromJSON(jsonValue));
    }

    /**
     */
    async addReference(newReferenceRequest?: NewReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultString> {
        const response = await this.addReferenceRaw({ newReferenceRequest: newReferenceRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async changeObjectOwnerRaw(requestParameters: ChangeObjectOwnerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        if (requestParameters['sharedId'] == null) {
            throw new runtime.RequiredError(
                'sharedId',
                'Required parameter "sharedId" was null or undefined when calling changeObjectOwner().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/write-enviddata-owner/{sharedId}`.replace(`{${"sharedId"}}`, encodeURIComponent(String(requestParameters['sharedId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ObjectRequestToJSON(requestParameters['objectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async changeObjectOwner(sharedId: string, objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.changeObjectOwnerRaw({ sharedId: sharedId, objectRequest: objectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async checkinObjectRaw(requestParameters: CheckinObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        if (requestParameters['sharedId'] == null) {
            throw new runtime.RequiredError(
                'sharedId',
                'Required parameter "sharedId" was null or undefined when calling checkinObject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-admin/write-enviddata-checkin/{sharedId}`.replace(`{${"sharedId"}}`, encodeURIComponent(String(requestParameters['sharedId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async checkinObject(sharedId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.checkinObjectRaw({ sharedId: sharedId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async checkoutObjectRaw(requestParameters: CheckoutObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        if (requestParameters['sharedId'] == null) {
            throw new runtime.RequiredError(
                'sharedId',
                'Required parameter "sharedId" was null or undefined when calling checkoutObject().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-admin/write-enviddata-checkout/{sharedId}`.replace(`{${"sharedId"}}`, encodeURIComponent(String(requestParameters['sharedId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async checkoutObject(sharedId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.checkoutObjectRaw({ sharedId: sharedId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createReferenceDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-admin/write-system-reference-data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultFromJSON(jsonValue));
    }

    /**
     */
    async createReferenceData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult> {
        const response = await this.createReferenceDataRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteObjectRaw(requestParameters: DeleteObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/write-enviddata-delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObjectRequestToJSON(requestParameters['objectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteObject(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult> {
        const response = await this.deleteObjectRaw({ objectRequest: objectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteObjectCheckRaw(requestParameters: DeleteObjectCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultDeleteCheckResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/read-enviddata-deletecheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObjectRequestToJSON(requestParameters['objectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultDeleteCheckResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteObjectCheck(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultDeleteCheckResult> {
        const response = await this.deleteObjectCheckRaw({ objectRequest: objectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getChangeHistoryRaw(requestParameters: GetChangeHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListAuditEventPublic>> {
        if (requestParameters['sharedId'] == null) {
            throw new runtime.RequiredError(
                'sharedId',
                'Required parameter "sharedId" was null or undefined when calling getChangeHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['version'] != null) {
            queryParameters['version'] = requestParameters['version'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-admin/read-enviddata-changehistory/{sharedId}`.replace(`{${"sharedId"}}`, encodeURIComponent(String(requestParameters['sharedId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListAuditEventPublicFromJSON(jsonValue));
    }

    /**
     */
    async getChangeHistory(sharedId: string, version?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListAuditEventPublic> {
        const response = await this.getChangeHistoryRaw({ sharedId: sharedId, version: version }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEnvidData1Raw(requestParameters: GetEnvidData1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/read-enviddata-object`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObjectRequestToJSON(requestParameters['objectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async getEnvidData1(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.getEnvidData1Raw({ objectRequest: objectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEnvidData2Raw(requestParameters: GetEnvidData2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultHashMapStringObject>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/read-enviddata-search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultHashMapStringObjectFromJSON(jsonValue));
    }

    /**
     */
    async getEnvidData2(searchRequest?: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultHashMapStringObject> {
        const response = await this.getEnvidData2Raw({ searchRequest: searchRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getObjectsByTypeRaw(requestParameters: GetObjectsByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListEnvidObjectSmallResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/read-enviddata-objectsbytype`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters['searchRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListEnvidObjectSmallResultFromJSON(jsonValue));
    }

    /**
     */
    async getObjectsByType(searchRequest?: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListEnvidObjectSmallResult> {
        const response = await this.getObjectsByTypeRaw({ searchRequest: searchRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getVersionChangesRaw(requestParameters: GetVersionChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultCollatedObjectSummary>> {
        if (requestParameters['sharedId'] == null) {
            throw new runtime.RequiredError(
                'sharedId',
                'Required parameter "sharedId" was null or undefined when calling getVersionChanges().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-admin/read-enviddata-versionChanges/{sharedId}`.replace(`{${"sharedId"}}`, encodeURIComponent(String(requestParameters['sharedId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultCollatedObjectSummaryFromJSON(jsonValue));
    }

    /**
     */
    async getVersionChanges(sharedId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultCollatedObjectSummary> {
        const response = await this.getVersionChangesRaw({ sharedId: sharedId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getWorkflowHistoryRaw(requestParameters: GetWorkflowHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListAuditEventPublic>> {
        if (requestParameters['sharedId'] == null) {
            throw new runtime.RequiredError(
                'sharedId',
                'Required parameter "sharedId" was null or undefined when calling getWorkflowHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['version'] != null) {
            queryParameters['version'] = requestParameters['version'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-admin/read-enviddata-workflowhistory/{sharedId}`.replace(`{${"sharedId"}}`, encodeURIComponent(String(requestParameters['sharedId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListAuditEventPublicFromJSON(jsonValue));
    }

    /**
     */
    async getWorkflowHistory(sharedId: string, version?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListAuditEventPublic> {
        const response = await this.getWorkflowHistoryRaw({ sharedId: sharedId, version: version }, initOverrides);
        return await response.value();
    }

    /**
     */
    async newEnvidDataRaw(requestParameters: NewEnvidDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/write-enviddata-newobject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewObjectRequestToJSON(requestParameters['newObjectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async newEnvidData(newObjectRequest?: NewObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.newEnvidDataRaw({ newObjectRequest: newObjectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async newLinkRaw(requestParameters: NewLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/write-enviddata-newlink`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRequestToJSON(requestParameters['linkRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async newLink(linkRequest?: LinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.newLinkRaw({ linkRequest: linkRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async readLinkListRaw(requestParameters: ReadLinkListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultListLinkListResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/read-enviddata-linklist`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkListRequestToJSON(requestParameters['linkListRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultListLinkListResultFromJSON(jsonValue));
    }

    /**
     */
    async readLinkList(linkListRequest?: LinkListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultListLinkListResult> {
        const response = await this.readLinkListRaw({ linkListRequest: linkListRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async readSummaryRaw(requestParameters: ReadSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSummaryResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/read-enviddata-summary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SummaryRequestToJSON(requestParameters['summaryRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultSummaryResultFromJSON(jsonValue));
    }

    /**
     */
    async readSummary(summaryRequest?: SummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSummaryResult> {
        const response = await this.readSummaryRaw({ summaryRequest: summaryRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeLinkRaw(requestParameters: RemoveLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultString>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/write-enviddata-removelink`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkRequestToJSON(requestParameters['linkRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultStringFromJSON(jsonValue));
    }

    /**
     */
    async removeLink(linkRequest?: LinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultString> {
        const response = await this.removeLinkRaw({ linkRequest: linkRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeReferenceRaw(requestParameters: RemoveReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/write-enviddata-removereference`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewReferenceRequestToJSON(requestParameters['newReferenceRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultFromJSON(jsonValue));
    }

    /**
     */
    async removeReference(newReferenceRequest?: NewReferenceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult> {
        const response = await this.removeReferenceRaw({ newReferenceRequest: newReferenceRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async reportTestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/envid-admin/report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultFromJSON(jsonValue));
    }

    /**
     */
    async reportTest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResult> {
        const response = await this.reportTestRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async restoreDeletedObjectRaw(requestParameters: RestoreDeletedObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/write-enviddata-undelete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObjectRequestToJSON(requestParameters['objectRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async restoreDeletedObject(objectRequest?: ObjectRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.restoreDeletedObjectRaw({ objectRequest: objectRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateFields2Raw(requestParameters: UpdateFields2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultArrayListUpdateFieldResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/envid-admin/write-enviddata-updatefield`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFieldRequestToJSON(requestParameters['updateFieldRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultArrayListUpdateFieldResultFromJSON(jsonValue));
    }

    /**
     */
    async updateFields2(updateFieldRequest?: UpdateFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultArrayListUpdateFieldResult> {
        const response = await this.updateFields2Raw({ updateFieldRequest: updateFieldRequest }, initOverrides);
        return await response.value();
    }

}
