/**
 * Created by craig on 17/01/17.
 */

import React from 'react'
import ebase from '../../utils/ajax'
import sitedata from '../../utils/sitedata'
import ComplianceMgrView from '../../views/ComplianceMgrView'
import { connect } from 'react-redux'
import details from "../../utils/details"
import values from "object.values"

import {
    loadComplianceStart, loadComplianceDone, loadComplianceFailed,
    complianceUpdated, deleteComplianceEvidenceUpload, deleteComplianceEvidenceUploadDone
} from '../../redux/actions/actions'
import {loadComplianceControlStart, loadComplianceControlDone, loadComplianceControlFailed} from '../../redux/actions/actions'
import {editComplianceActionStart,updateComplianceField} from '../../redux/actions/actions'
import {savingComplianceStart, savingComplianceSuccess, savingComplianceFailed} from '../../redux/actions/actions'
import {newComplianceActionDone, newComplianceActionError} from '../../redux/actions/actions'
import {uploadingEvidenceStart, uploadingEvidenceDone, uploadingEvidenceError} from '../../redux/actions/actions'
import {deleteComplianceActionDone, deleteComplianceActionFailed} from '../../redux/actions/actions'
import SessionManager from "../../utils/SessionManager";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{
    let currentObject = state.reports.currentObject;

    return {
        project: (currentObject==null)? null : currentObject.project,
        isLoading: state.compliance.isLoading,
        isControlLoading: state.compliance.isControlLoading,
        objectLists:state.compliance.objectLists,
        complianceDefn:state.compliance.complianceDefn,
        complianceActions:state.compliance.complianceActions,
        control:state.compliance.selectedControl,
        object:state.compliance.object,
        editing_id:state.compliance.editing_id,
        changes:state.compliance.changes,
        siteData: state.session.siteData,
        dimensions:state.session.dimensions,
    }
}

const saveFields = function(dispatch, changes)
{
    console.log("CompliancMgr save fields");
    console.log(changes);
    let changedFields = changes.changedFields;

    if (details.isObjectEmpty(changedFields))
    {
        return;
    }

    if (!Object.values) values.shim();


    dispatch(savingComplianceStart());

    ebase.ajax({
        url: "/ebase/compliance/write-compliance-updatefields",
        data: Object.values(changedFields),
        success: (result) => {
            console.log("ComplianceMgr save fields success");
            dispatch(savingComplianceSuccess(result));
            dispatch(complianceUpdated(new Date()));
        },
        error:   () => {
            dispatch(savingComplianceFailed(changedFields))
        }
    });

}

const loadControlCompliance = function(project_id, control_id, dispatch)
{
    if (control_id == null || control_id === "none")
    {
        return;
    }

    dispatch(loadComplianceControlStart());

    ebase.ajax({
        url:"/ebase/compliance/read-compliance-control",
        data:{project_id : project_id, control_id : control_id},
        success:(result) => {if (result.success) dispatch(loadComplianceControlDone(result)); else dispatch(loadComplianceControlFailed(result.error));},
        error:() => dispatch(loadComplianceFailed("Error contacting server"))
    });


}
const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {
        newComplianceAction : (project_id, control_id) =>
        {
            ebase.ajax({
                url:"/ebase/compliance/write-compliance-new",
                data:{project_id:project_id, control_id:control_id, type:"ComplianceAction"},
                success:(result) => {

                    if (result.success) {
                        dispatch(newComplianceActionDone(result.data));
                    } else {
                        dispatch(newComplianceActionError(result.error));
                        ownProps.showLoadError("Error Loading Compliance Action", result);
                    }
                },
                error :(result) => {
                    dispatch(newComplianceActionError(result.error));
                    ownProps.showLoadError("Error Loading Compliance Action", result);
                }

            });
        },

        loadControlCompliance : (project_id, control_id) =>
        {
            loadControlCompliance(project_id, control_id, dispatch);
        },

        loadComplianceTask : (project_id, control_id) =>
        {
            dispatch(loadComplianceStart());


            ebase.ajax({
                url:"/ebase/compliance/read-compliance-loadcontrols",
                data:{project_id : project_id},
                success:(result) => {
                    if (result.success)
                    {
                        dispatch(loadComplianceDone(result));
                        loadControlCompliance(project_id, control_id, dispatch);
                    }
                    else
                    {
                        dispatch(loadComplianceFailed(result.error));
                    }
                },
                error:() => dispatch(loadComplianceFailed("Error contacting server"))
            });

        },

        startEdit(action_id)
        {
            dispatch(editComplianceActionStart(action_id));
        },

        updateField : (object_id, fieldName, fieldValue) =>
        {
            console.log("Update Field : " + fieldName + " = " + fieldValue);
            dispatch(updateComplianceField(object_id, fieldName, fieldValue));
        },

        saveFields(changed)
        {
            saveFields(dispatch, changed)
        },

        deleteAction(action_id)
        {
            ebase.ajax({
                url:"/ebase/compliance/write-compliance-deleteaction",
                data:{objectId : action_id},
                success:(result) => {
                    if (result.success)
                    {
                        dispatch(deleteComplianceActionDone(action_id, result));
                        dispatch(complianceUpdated(new Date()));
                    }
                    else
                    {
                        dispatch(deleteComplianceActionFailed(result.error));
                    }
                },
                error:() => dispatch(deleteComplianceActionFailed("Error contacting server"))
            });
        },
        uploadEvidence(action_id, file)
        {
            let data = new FormData();
            data.set("file", file);
            data.set("action_id", action_id);

            console.log("uploadEvidence");
            dispatch(uploadingEvidenceStart(action_id, file.name));

            ebase.ajax({
                url: "/ebase/compliance/write-compliance-uploadpdf",
                data: data,
                contentType: false,
                processData: false,
                method: 'POST',
                success: (result) => {
                    if (result.success) {
                        dispatch(uploadingEvidenceDone(action_id, file.name, result.data));
                    } else {
                        dispatch(uploadingEvidenceError(action_id, file.name, result.error))
                    }
                },
                error: ((error) => dispatch(uploadingEvidenceError(action_id, file.name, "Error contacting server")))
            }, false);

        },
        deleteEvidence(action_id,path)
        {
            dispatch(deleteComplianceEvidenceUpload(action_id,path));

            ebase.ajax({
                url:"/ebase/compliance/write-compliance-deleteuploadpdf",
                data:{object_id :action_id,project_id: path},
                success:(result) => {
                    dispatch(deleteComplianceEvidenceUploadDone(action_id,result));
                },
                error:() => dispatch(deleteComplianceActionFailed("Error contacting server"))
            });
        },
        loadBranch(parent){
            //placeholder incase we need to implement this in future.
            console.log("ComplianceMgrContainer:: Load Branch not implemented "+parent);
        }


    }
}

class CompMgrView extends React.Component{

    constructor(props) {
        super(props);
        let params = sitedata.getParams(this.props);

        let project_id = params.projectId;
        let control_id = params.controlId;

        this.props.loadComplianceTask(project_id, control_id);
    }

    render() {

        return (
            <div>
                {React.cloneElement(<ComplianceMgrView/>, this.props)}
            </div>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let nextParams = sitedata.getParams(prevProps);
        let params = sitedata.getParams(this.props);

        let project_id = nextParams.projectId;
        let control_id = nextParams.controlId;

        if (params.controlId !== control_id || params.projectId !== project_id) {
            this.props.loadControlCompliance(project_id, control_id);
        }
    }

    // componentWillReceiveProps(nextProps)
    // {
    //
    //     let nextParams = sitedata.getParams(nextProps);
    //     let params = sitedata.getParams(this.props);
    //
    //     let project_id = nextParams.projectId;
    //     let control_id = nextParams.controlId;
    //
    //     if (params.controlId !== control_id || params.projectId !== project_id) {
    //         this.props.loadControlCompliance(project_id, control_id);
    //     }
    // }

    // componentWillMount()
    // {
    //
    //     let params = sitedata.getParams(this.props);
    //
    //     let project_id = params.projectId;
    //     let control_id = params.controlId;
    //
    //     this.props.loadComplianceTask(project_id, control_id);
    //
    // }
};

const ComplianceMgrContainer = connect(mapStateToProps, mapDispatchToProps)(CompMgrView)

export default ComplianceMgrContainer
