/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FieldObject } from './FieldObject';
import {
    FieldObjectFromJSON,
    FieldObjectFromJSONTyped,
    FieldObjectToJSON,
} from './FieldObject';

/**
 * 
 * @export
 * @interface UpdateFieldRequest
 */
export interface UpdateFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateFieldRequest
     */
    targetSharedId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFieldRequest
     */
    primarySharedId?: string;
    /**
     * 
     * @type {FieldObject}
     * @memberof UpdateFieldRequest
     */
    field?: FieldObject;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UpdateFieldRequest
     */
    extra?: { [key: string]: object; };
}

/**
 * Check if a given object implements the UpdateFieldRequest interface.
 */
export function instanceOfUpdateFieldRequest(value: object): value is UpdateFieldRequest {
    return true;
}

export function UpdateFieldRequestFromJSON(json: any): UpdateFieldRequest {
    return UpdateFieldRequestFromJSONTyped(json, false);
}

export function UpdateFieldRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateFieldRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'targetSharedId': json['targetSharedId'] == null ? undefined : json['targetSharedId'],
        'primarySharedId': json['primarySharedId'] == null ? undefined : json['primarySharedId'],
        'field': json['field'] == null ? undefined : FieldObjectFromJSON(json['field']),
        'extra': json['extra'] == null ? undefined : json['extra'],
    };
}

export function UpdateFieldRequestToJSON(value?: UpdateFieldRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'targetSharedId': value['targetSharedId'],
        'primarySharedId': value['primarySharedId'],
        'field': FieldObjectToJSON(value['field']),
        'extra': value['extra'],
    };
}

