import React from 'react';
import EbasePanel from "../components/common/EbasePanel"
import EButton from "../components/common/EButton"
import "../ladda.min.css"
import Spinner from '../components/common/Spinner'
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import {Modal, ModalBody, ModalHeader, ModalTitle, ModalFooter} from 'react-bootstrap'
import IntersectionVisible    from 'react-intersection-visible'
import {Trans, withTranslation} from "react-i18next";
import { Link } from 'react-router-dom';
import SessionHelper from "../utils/SessionHelper";
import Select from "react-select";



class DataManage extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            asking : false,
        };

        //don't store loadOnShow in a state because don't want to trigger a render when changing it.
        //null at start so initial show/hide doesn't trigger a reload.
        this.loadOnShow = null;

        this.onDelete = this.onDelete.bind(this);
        this.onRealDelete = this.onRealDelete.bind(this);
        this.onRestore = this.onRestore.bind(this);
        this.changeOwner = this.changeOwner.bind(this);

        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onDelete()
    {
        this.setState({asking:true});
    }

    onRealDelete()
    {
        this.props.doDelete();
    }

    onRestore()
    {
        this.props.doRestore();
    }

    changeOwner()
    {
        this.props.changeObjectOwner();
    }

    onClose()
    {
        this.setState({asking:false});
    }

    onHide(entries){

        if(this.loadOnShow !== null) {
            this.loadOnShow = true;
        }
    }
    onShow(entires){
        if(this.loadOnShow === null){
           this.loadOnShow = true;
        }else if (this.loadOnShow){
            this.loadOnShow = false;
            this.props.reload();
        }
    }

    render() {
        let obj = this.props.currentObject.object;
        let checkedOut = SessionHelper.shared().isObjectCheckedOutToSessionUser(obj);
        let inputDisabled = !(checkedOut);

        const {t} = this.props;

        if (this.props.loading)
        {
            return <Spinner/>
        }

        if (!!obj.deleteState){
            return (
              <IntersectionVisible onShow={(e) => this.onShow(e)} onHide={(e) => this.onHide(e)}>
                  <div>
                      <div className="col-lg-6">
                          <div className={"card card-default"}>
                              <div className="card-header"><h3>Object Deleted</h3></div>
                              <div className="card-body p-lg text-left">
                                  <p>This object has already been deleted and will be removed in the next published version.</p>
                                  <p>To return the object to its previous state use the restore button.</p>
                                  <div className="">
                                      <EButton type="restore" disabled={this.state.updating} onClick={() => {this.onRestore();}}>{"Restore"}</EButton>
                                  </div>
                              </div>
                              {this.state.updating && <Spinner/>}
                          </div>
                      </div>
                  </div>
              </IntersectionVisible>
            )
        }

        let deleteCheckInfo = this.props.deleteCheckInfo;
        if (deleteCheckInfo == null)
        {
            return <div>{t("No Information Found")}</div>;
        }

        if (deleteCheckInfo.error != null)
        {
            return <div className="label-error">{deleteCheckInfo.error}</div>
        }

        let canDelete = deleteCheckInfo.canDelete;

        let modal = (

            <Modal show={this.state.asking} onHide={this.onClose.bind(this)}>
                <ModalHeader closeButon>
                    <ModalTitle>
                        {t("Delete Object")}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>

                    <p>{t("Are you sure you want to delete this object")}</p>

                </ModalBody>
                <ModalFooter>
                    <EButton isTable={true} type="cancel" onClick={this.onClose.bind(this)}>{t("Cancel")}</EButton>
                    <EButton isTable={true} type="delete" onClick={this.onRealDelete}>{t("Delete")}</EButton>
                </ModalFooter>

            </Modal>

        );

        let objectOwnerElement =
            <div className="col-lg-6">
                <div className={"card card-default"}>
                    <div className="card-header d-flex justify-content-between  align-items-center">
                        <div><h3 className="">{"Object Owner"}</h3></div>

                        {obj.ownerName ? <div>{"The current Owner of this object is "}<b>{obj.ownerName}</b></div> : <div><i>{"No current owner."}</i></div>}
                    </div>
                    <div className="card-body p-lg text-left">
                        <p><b>{"Change Object Owner"}</b></p>
                        <div className="d-flex w-100 gap-4">
                            {/*<label className="eb-div-ctr">{"Object Owner: "}</label>*/}

                            {this.props.userList && <Select
                                className="flex-grow-1"
                                options={this.props.userList.map((value) => {
                                    return {value: value.userId, label: value.firstname + " " + value.lastname + " - " + value.email};
                                })}
                                onChange={(val) => {
                                    this.props.changeTempOwner(val);
                                }}
                                value={this.props.tempObjectOwner}
                                isDisabled={inputDisabled}
                                isSearchable={true}
                                isClearable={false}
                            />}
                            <EButton type="" disabled={inputDisabled} onClick={() => {
                                this.changeOwner();
                            }}>{"Update Object Owner"}</EButton>
                        </div>
                    </div>
                </div>
            </div>;


        let links = "";
        if (deleteCheckInfo != null && deleteCheckInfo.linked != null) {

            links = deleteCheckInfo.linked.map((linkedObject) =>
                <li key={linkedObject.objectId}><EnvidObjectIcon type={linkedObject.type}
                                                                 showName={false}/> &nbsp;{linkedObject.name + " "}&nbsp;
                    <Link to={"/enviddata/object/" + linkedObject.sharedId + "/det"}>{"View  "}<i
                        className="fa fa-external-link-square"></i></Link>
                </li>
            );
        }

        if (canDelete) {

            let message = [<Trans i18nKey="delete-warning-one"><p>You can delete this object. This will remove it
                permanently from the database.</p><p>Please only delete this if you know what you are doing.</p>
            </Trans>]

            let type = this.props.currentObject.object.type;
            if (type !== "ProjectCategory" && type !== "Region" && type !== "Stakeholder") {
                message = [<Trans i18nKey="delete-warning-two"><p>You can delete this object. This will remove it
                    permanently from the database and may effect projects associated with this Library.</p><p>Please
                    only delete this if you sure it needs to be deleted.</p></Trans>]
            }

            return (
                <IntersectionVisible onShow={(e) => this.onShow(e)} onHide={(e) => this.onHide(e)}>
                    <div className="row">
                        {modal}
                        <div className="col-lg-6">
                            <div className={"card card-default"}>
                                <div className="card-header"><h3>{t("Delete Object")}</h3></div>
                                <div className="card-body p-lg text-left">
                                    {message}
                                    <p style={{fontSize: 'small'}}>{inputDisabled ? "Object needs to be checked out to be deleted." : ""}</p>
                                    <div className="text-right">
                                        <EButton disabled={inputDisabled} type="delete"
                                                 onClick={this.onDelete}>{t("Delete Object")}</EButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {objectOwnerElement}

                    </div>
                </IntersectionVisible>
            )
        }

        return (
            <IntersectionVisible onShow={(e) => this.onShow(e)} onHide={(e) => this.onHide(e)}>
                <EbasePanel>

                    <div className="row">

                        <div className="col-lg-6" style={{height: this.props.height - 40, overflow: 'scroll'}}>
                            <Trans i18nKey="cannot-delete-message">
                                <h3>Cannot Delete Object</h3>
                                <p>You cannot delete this object, it has connections to other objects.</p>
                                <p>Remove all it's connections if you really want to delete it.</p>
                            </Trans>

                            <ul>{links}</ul>


                        </div>
                        {objectOwnerElement}
                    </div>
                </EbasePanel>
            </IntersectionVisible>
    );
    }

}

export default withTranslation()(DataManage)
