/**
 * Created by lewellyn on 6/9/18.
 */
import React from 'react';
import {Table} from 'react-bootstrap'
import EButton from "../components/common/EButton";
import {withTranslation} from "react-i18next";

class DatasetSelectView extends React.Component {

    constructor(props)
    {
        super(props);

        this.libraryTable = this.libraryTable.bind(this);
    }
    libraryTable(client,libraries, use){

        const {t} = this.props;

        // console.log("Library Table");
        // console.log(libraries);

        if(libraries === null || libraries === undefined){
            return(
                <Table><thead><tr><th>{t("No Libraries")}</th></tr></thead></Table>
            );
        }
        if(libraries.length === 0){
            return(
                <Table><thead><tr><th>{t("No Libraries")}</th></tr></thead></Table>
            );
        }

        libraries.sort((a,b) => {
            return a.name.localeCompare(b.name);
        });

        let rows = [];

        for(let i=0;i<libraries.length; i++){
            let lib = libraries[i];

            if (!this.props.showArchived && !!lib.archived === true){
                continue;
            }

            let access = "";
            if(lib.clientDatasetAccessType === "READONLY"){
                access = <span style={{color:'#aaaaaa',fontWeight:"bold"}}> ({t("READ ONLY")})</span>
            }

            let archived = lib.archived ?
              <span style={{color: '#aaaaaa', fontWeight: "bold"}}><em> [ARCHIVED]</em></span> : <></>

              let
            btn = <EButton onClick={() => use(client.clientId, lib.datasetId)} type="standard" isTable={true}>{t("Use")}</EButton>;
            rows.push(
                <tr key={""+i}>
                    <td>{lib.name}{access}{archived}</td>
                    <td style={{textAlign:'right'}}>{lib.archived ? <></> : btn}</td>
                </tr>
            )
        }



        return (
            <Table>
                <thead><tr><th>{t("Libraries")}</th><th></th></tr></thead>
                <tbody>{rows}</tbody>
            </Table>
        );

    }

    render() {

        const {t} = this.props;

        let rows =[];
        let body = [];
        let datasets = this.props.clientLibraries;

        // console.log("DatasetSelectView");
        // console.log(this.props);
        // console.log("");
        // console.log(datasets);

        datasets.sort((a,b) => {

            return a.client.name.localeCompare(b.client.name);

        });

        if(datasets == null){
            body.push(<thead key="head"><tr><td>{t("No Libraries")}</td></tr></thead>);
        }else{

            if(datasets.length === 0){
                    body.push(<thead><tr><td>{t("No Libraries")}</td></tr></thead>);
            }else {

                body.push(<thead key="head"><tr><th></th><th>{t("Company")}</th><th></th></tr></thead>);

                for(let i=0;i<datasets.length;i++){

                    let editBtn = "";
                    if(this.props.canEdit){
                        editBtn = <EButton onClick={() => this.props.editClick(client.clientId)} type="edit" isTable={true}>{t("Edit")}</EButton>;
                    }

                    let clientLibrary = datasets[i];
                    let client = clientLibrary.client;

                    rows.push(
                        <tr key={""+i}>
                            <td style={{width:100, verticalAlign:'middle'}}>{editBtn}</td>
                            <td style={{width:250, verticalAlign:'middle',borderRight:1}}>{client.name}</td>
                            <td>{this.libraryTable(client,clientLibrary.libraries,this.props.selectDataset)}</td>
                        </tr>);
                }

                body.push(<tbody key={"tbody"}>{rows}</tbody>);

            }



        }

        return (

            <div>

                <div className="ibox-content">
                    <Table>
                        {body}
                    </Table>
                </div>
            </div>

        );
    }

}


export default withTranslation()(DatasetSelectView)
