/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatasetResult
 */
export interface DatasetResult {
    /**
     * 
     * @type {string}
     * @memberof DatasetResult
     */
    datasetId?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetResult
     */
    desc?: string;
    /**
     * 
     * @type {Date}
     * @memberof DatasetResult
     */
    lastUpdateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof DatasetResult
     */
    accessRole?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetResult
     */
    clientDatasetAccessType?: DatasetResultClientDatasetAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DatasetResult
     */
    userDatasetAccessType?: DatasetResultUserDatasetAccessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DatasetResult
     */
    languageCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetResult
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof DatasetResult
     */
    ownerName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetResult
     */
    archived?: boolean;
}


/**
 * @export
 */
export const DatasetResultClientDatasetAccessTypeEnum = {
    Readonly: 'READONLY',
    Write: 'WRITE',
    None: 'NONE'
} as const;
export type DatasetResultClientDatasetAccessTypeEnum = typeof DatasetResultClientDatasetAccessTypeEnum[keyof typeof DatasetResultClientDatasetAccessTypeEnum];

/**
 * @export
 */
export const DatasetResultUserDatasetAccessTypeEnum = {
    Readonly: 'READONLY',
    Write: 'WRITE',
    None: 'NONE'
} as const;
export type DatasetResultUserDatasetAccessTypeEnum = typeof DatasetResultUserDatasetAccessTypeEnum[keyof typeof DatasetResultUserDatasetAccessTypeEnum];


/**
 * Check if a given object implements the DatasetResult interface.
 */
export function instanceOfDatasetResult(value: object): value is DatasetResult {
    return true;
}

export function DatasetResultFromJSON(json: any): DatasetResult {
    return DatasetResultFromJSONTyped(json, false);
}

export function DatasetResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetResult {
    if (json == null) {
        return json;
    }
    return {
        
        'datasetId': json['datasetId'] == null ? undefined : json['datasetId'],
        'name': json['name'] == null ? undefined : json['name'],
        'desc': json['desc'] == null ? undefined : json['desc'],
        'lastUpdateTime': json['lastUpdateTime'] == null ? undefined : (new Date(json['lastUpdateTime'])),
        'accessRole': json['accessRole'] == null ? undefined : json['accessRole'],
        'clientDatasetAccessType': json['clientDatasetAccessType'] == null ? undefined : json['clientDatasetAccessType'],
        'userDatasetAccessType': json['userDatasetAccessType'] == null ? undefined : json['userDatasetAccessType'],
        'languageCode': json['languageCode'] == null ? undefined : json['languageCode'],
        'ownerId': json['ownerId'] == null ? undefined : json['ownerId'],
        'ownerName': json['ownerName'] == null ? undefined : json['ownerName'],
        'archived': json['archived'] == null ? undefined : json['archived'],
    };
}

export function DatasetResultToJSON(value?: DatasetResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'datasetId': value['datasetId'],
        'name': value['name'],
        'desc': value['desc'],
        'lastUpdateTime': value['lastUpdateTime'] == null ? undefined : ((value['lastUpdateTime']).toISOString()),
        'accessRole': value['accessRole'],
        'clientDatasetAccessType': value['clientDatasetAccessType'],
        'userDatasetAccessType': value['userDatasetAccessType'],
        'languageCode': value['languageCode'],
        'ownerId': value['ownerId'],
        'ownerName': value['ownerName'],
        'archived': value['archived'],
    };
}

