import React from 'react'
import ebase from '../../utils/ajax'
import sitedata from '../../utils/sitedata'
import StakeholderFilter from "../../utils/StakeholderFilter";
import StakeholderStatusView from "../../views/StakeholderStatusView"
import {connect} from "react-redux";
import SessionManager from "../../utils/SessionManager";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{

    let currentObject = state.reports.currentObject;
    return {
        project: (currentObject==null)? null : currentObject.project,
        statusInfo : state.stakeholder.statusInfo,
        isStatusLoading : state.projects.isStatusLoading,
        readOnly:(!sitedata.checkWriteAccess(state.session.siteData,"project"))
    }
}

const loadStatus = function(project_id,dispatch){

    if(project_id === null || project_id === undefined){
        return;
    }

    dispatch({type:"LOAD_STAKEHOLDER_STATUS_START"});

    ebase.ajax({
        url: "/ebase/stakeholder/read-stakeholder-status",
        data: {project_id: project_id},
        success: (result) => {
            // console.log("load stakeholder status");
            // console.log(result);
            if(result.success === true){
                dispatch({type:"LOAD_STAKEHOLDER_STATUS_DONE", result:result.data});
            }else{
                dispatch({type:"LOAD_STAKEHOLDER_STATUS_DONE", result:null});
            }
        },
        error:   () => {
            dispatch({type:"LOAD_STAKEHOLDER_STATUS_DONE", result:null});
        }
    });
}

const statusClicked = (status, ownProps) => {

    // console.log("Stakeholder Status Container:: status clicked: " + status);

    //{"IN_PROGRESS":"In Progress","COMPLETE":"Complete","COMPLETE_WITH_CONSULTATION":"Complete With Consultation","NA":"Not Relevant"};

    let filter = new StakeholderFilter();
    if (status === "IN_PROGRESS")
    {
        filter.setStatus("IN_PROGRESS", true);
    }
    else if (status === "COMPLETE")
    {
        filter.setStatus("COMPLETE", true);
    }
    else if (status === "COMPLETE_WITH_CONSULTATION")
    {
        filter.setStatus("COMPLETE_WITH_CONSULTATION", true);
    }
    else if (status === "NA")
    {
        filter.setStatus("NA", true);
    }

    let project_id = ownProps.project.object_id;

    SessionManager.shared().goto("/stakeholder/" + project_id + "/manage/"+filter.toString()+"/none/setup");


}


const mapDispatchToProps = (dispatch, ownProps) =>
{
    return {
        loadStatus : (project_id) => loadStatus(project_id,dispatch),
        statusClicked : (status) => statusClicked(status, ownProps),
    }
}

class StatusView extends React.Component{
    render() {

        return (
            <div>
                {React.cloneElement(<StakeholderStatusView/>, this.props)}
            </div>
        )
    }
    componentWillReceiveProps(nextProps)
    {
        // console.log("Stakeholder Status will receive props");
        // console.log(this.props);
        // console.log("=--------------------------");

        let nextParams = sitedata.getParams(nextProps);
        let params = sitedata.getParams(this.props);

        if(nextParams.projectId !== params.projectId){
            this.props.loadStatus(nextParams.projectId);
        }

        // if(nextProps.routeParams.projectId !== this.props.routeParams.projectId){
        //     this.props.loadStatus(nextProps.routeParams.projectId);
        // }

    }
    componentWillMount()
    {
        // console.log("Stakeholder Statusw will mount");
        // console.log(this.props);
        // console.log("=--------------------------");

        if(this.props.project !== null) {
            this.props.loadStatus(this.props.project.object_id);
        }
    }


};

const StakeholderStatusContainer = connect(mapStateToProps, mapDispatchToProps)(StatusView)

export default StakeholderStatusContainer
