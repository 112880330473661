/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnvidObjectResult } from './EnvidObjectResult';
import {
    EnvidObjectResultFromJSON,
    EnvidObjectResultFromJSONTyped,
    EnvidObjectResultToJSON,
} from './EnvidObjectResult';

/**
 * 
 * @export
 * @interface EbaseResultEnvidObjectResult
 */
export interface EbaseResultEnvidObjectResult {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultEnvidObjectResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultEnvidObjectResult
     */
    error?: string;
    /**
     * 
     * @type {EnvidObjectResult}
     * @memberof EbaseResultEnvidObjectResult
     */
    data?: EnvidObjectResult;
}

/**
 * Check if a given object implements the EbaseResultEnvidObjectResult interface.
 */
export function instanceOfEbaseResultEnvidObjectResult(value: object): value is EbaseResultEnvidObjectResult {
    return true;
}

export function EbaseResultEnvidObjectResultFromJSON(json: any): EbaseResultEnvidObjectResult {
    return EbaseResultEnvidObjectResultFromJSONTyped(json, false);
}

export function EbaseResultEnvidObjectResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultEnvidObjectResult {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : EnvidObjectResultFromJSON(json['data']),
    };
}

export function EbaseResultEnvidObjectResultToJSON(value?: EbaseResultEnvidObjectResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': EnvidObjectResultToJSON(value['data']),
    };
}

