/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkListRequest
 */
export interface LinkListRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkListRequest
     */
    sharedId?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkListRequest
     */
    linkType?: LinkListRequestLinkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LinkListRequest
     */
    objectType?: LinkListRequestObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LinkListRequest
     */
    listType?: LinkListRequestListTypeEnum;
}


/**
 * @export
 */
export const LinkListRequestLinkTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type LinkListRequestLinkTypeEnum = typeof LinkListRequestLinkTypeEnum[keyof typeof LinkListRequestLinkTypeEnum];

/**
 * @export
 */
export const LinkListRequestObjectTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type LinkListRequestObjectTypeEnum = typeof LinkListRequestObjectTypeEnum[keyof typeof LinkListRequestObjectTypeEnum];

/**
 * @export
 */
export const LinkListRequestListTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type LinkListRequestListTypeEnum = typeof LinkListRequestListTypeEnum[keyof typeof LinkListRequestListTypeEnum];


/**
 * Check if a given object implements the LinkListRequest interface.
 */
export function instanceOfLinkListRequest(value: object): value is LinkListRequest {
    return true;
}

export function LinkListRequestFromJSON(json: any): LinkListRequest {
    return LinkListRequestFromJSONTyped(json, false);
}

export function LinkListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkListRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'sharedId': json['sharedId'] == null ? undefined : json['sharedId'],
        'linkType': json['link_type'] == null ? undefined : json['link_type'],
        'objectType': json['object_type'] == null ? undefined : json['object_type'],
        'listType': json['list_type'] == null ? undefined : json['list_type'],
    };
}

export function LinkListRequestToJSON(value?: LinkListRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'sharedId': value['sharedId'],
        'link_type': value['linkType'],
        'object_type': value['objectType'],
        'list_type': value['listType'],
    };
}

