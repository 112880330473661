/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClientResult } from './ClientResult';
import {
    ClientResultFromJSON,
    ClientResultFromJSONTyped,
    ClientResultToJSON,
} from './ClientResult';

/**
 * 
 * @export
 * @interface EbaseResultListClientResult
 */
export interface EbaseResultListClientResult {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultListClientResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultListClientResult
     */
    error?: string;
    /**
     * 
     * @type {Array<ClientResult>}
     * @memberof EbaseResultListClientResult
     */
    data?: Array<ClientResult>;
}

/**
 * Check if a given object implements the EbaseResultListClientResult interface.
 */
export function instanceOfEbaseResultListClientResult(value: object): value is EbaseResultListClientResult {
    return true;
}

export function EbaseResultListClientResultFromJSON(json: any): EbaseResultListClientResult {
    return EbaseResultListClientResultFromJSONTyped(json, false);
}

export function EbaseResultListClientResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultListClientResult {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : ((json['data'] as Array<any>).map(ClientResultFromJSON)),
    };
}

export function EbaseResultListClientResultToJSON(value?: EbaseResultListClientResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': value['data'] == null ? undefined : ((value['data'] as Array<any>).map(ClientResultToJSON)),
    };
}

