import React from 'react';
import {
    FormControl,
  Form
} from 'react-bootstrap';
import "../ladda.min.css";
import '../../node_modules/ladda/js/spin.js';
import '../../node_modules/ladda/js/ladda.js';
import {withTranslation} from "react-i18next";
import envid from "../utils/envid";
import EButton from "../components/common/EButton";
import EBTable from "../components/common/EBTable";
import SessionManager from "../utils/SessionManager";
import {AuditEventPublic} from "../fetchapi";
import sitedata from "../utils/sitedata";
import Spinner from "../components/common/Spinner";


class DataPublishing extends React.Component {

    constructor(props)
    {
        super(props);
        this.onPublishClick = this.onPublishClick.bind(this);
        this.onPublishCheckInClick = this.onPublishCheckInClick.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);
        this.onNotesChange = this.onNotesChange.bind(this);
        this.state = {
            stateChanged: false
            // objectHistory: [],
        };
    }

    componentDidMount() {}

    onPublishClick(e) {
        this.props.doChangePublished();
    }

    onPublishCheckInClick(e) {
        // console.log(this.props.currentObject.object.checkoutUserId);
        this.props.doChangePublished(e);
        // this.props.checkinObjectClick(e, this.props.currentObject.object, "ObjectView");
    }

    onStatusChange(e){
        this.setState({stateChange: e.target.value !== this.props.currentObject.object.publishingInfo.state});
        this.props.fieldChanged('status', e.target.value);
    }

    onNotesChange(e){
        this.props.fieldChanged('notes', e.target.value);
    }

    render() {
        const {t} = this.props;

        if (this.props.isLoading)
        {
            return <Spinner/>
        }
        // console.log("props: ",this.props);

        // let publishState = this.props.currentObject.object.publishingInfo.state;
        const publishState = this.props.workingInfo.status;
        const deleteSate = this.props.currentObject.object.deleteState;

        let columns =
            [
                {
                    header:
                        <div className="d-flex flex-column">
                            <span>Changed</span>
                            <span style={{fontSize: 'x-small'}}>When and Who</span>
                        </div>,
                    field: 'author'
                },
                {
                    header: 'Object Status',
                    field: 'objectStatus',
                    render: (status) => {
                        return (!!status ? <span
                            style={{...envid.getPublishTextStyle(status), width: 'fit-content', fontSize: "12px"}}
                            className="label">{envid.getPublishStateName(status)}</span> : <></>);
                    }
                },
                {header: 'Notes', field: 'notes', render: (notes) => <div style={{minWidth: '200px'}}>{notes}</div>},
                {header: 'Library Version', field: 'libVersion'},
            ];

        let data =
            this.props.objectHistory ? this.props.objectHistory.map((row,index) => {
                    if (!!row.eventType && row.eventType === "PUBLISH") {
                        return { customRow: <tr>
                                <td colSpan={3} style={{backgroundColor: "#EDF7F9", borderBottom: "solid #8DC4D3", borderTop: "solid #8DC4D3"}}>
                                    {"Included in "}<b>{(sitedata.getCurrentDataset(this.props.siteData).name ?? "") + " Version " + row.version}</b> {" when published " + row.userEvent.eventDate.toLocaleString()+ " by " +row.userEvent.firstName + " " + row.userEvent.lastName}
                                </td>
                                <td  style={{backgroundColor: "#EDF7F9", borderBottom: "solid #8DC4D3", borderTop: "solid #8DC4D3"}}>
                                    <b>{row.version + " - Published"}</b>
                                </td>
                            </tr>}
                    }
                    if (!!row.eventType && row.eventType === "CREATE") {
                        return { customRow: <tr>
                                <td colSpan={3} style={{backgroundColor: "#EDF7F9", borderBottom: "solid #8DC4D3", borderTop: "solid #8DC4D3"}}>
                                    <b>Object created</b> {" " + row.userEvent.eventDate.toLocaleString()+ " by " +row.userEvent.firstName + " " + row.userEvent.lastName}
                                </td>
                                <td  style={{backgroundColor: "#EDF7F9", borderBottom: "solid #8DC4D3", borderTop: "solid #8DC4D3"}}>
                                    <b>{row.version}</b>
                                </td>
                            </tr>}
                    }
                    return (
                        {
                            author: row.userEvent.eventDate.toLocaleString() + " by " + row.userEvent.firstName + " " + row.userEvent.lastName,
                            objectStatus: row.value,
                            notes: row.payload?.notes ?? '' ,
                            libVersion: row.version,
                        }
                    );
                }
            ) : [];

        return (
            <div className="h-100 row">
                <div className="col-lg-9">
                    <h3 className="m-b-md">Object Status Changes</h3>
                    <EBTable
                        columns={columns}
                        data={data}
                        responsive classCustom="overflow-y-auto"></EBTable>
                </div>
                <div className="col-lg-3 border-left border-2">
                    <h3 className="m-b-md">Manage Object Status</h3>
                    <div className="card">
                        <div className="card-header d-flex gap-2">
                            <div className="eb-div-r">
                                <h4>Current Status</h4>
                            </div>
                          {!!deleteSate ? <div className="eb-div-l">
                                <span style={{
                                  ...envid.getPublishTextStyle('Draft'),
                                  width: 'fit-content',
                                  fontSize: "12px"
                                }}
                                      className="label">Deleted</span>
                            </div>
                            :
                            <div className="eb-div-l">
                                <span style={{
                                  ...envid.getPublishTextStyle(this.props.currentObject.object.publishingInfo.state),
                                  width: 'fit-content',
                                  fontSize: "12px"
                                }}
                                      className="label">{envid.getPublishStateName(this.props.currentObject.object.publishingInfo.state)}</span>
                            </div>
                          }

                        </div>
                      <div className="card-body text-left">
                        <div className="d-flex flex-column gap-2">
                          <div>
                            <h4>New Status</h4>
                            <Form.Select disabled={this.props.isUpdating || !!deleteSate} value={publishState}
                                         onChange={this.onStatusChange}
                                         placeholder="Select...">
                            {
                                            envid.getPublishStates().map((s) => {
                                                return (<option key={s} value={s}>{t(envid.getPublishStateName(s))}</option>);
                                            })
                                        }
                                    </Form.Select>
                                </div>
                                <div>
                                    <h4>Notes</h4>
                                    <Form.Control disabled={!this.state.stateChange || (this.props.isUpdating || !!deleteSate)} as={'textarea'} value={this.props.workingInfo.notes} onChange={this.onNotesChange}
                                                 placeholder="Add Notes..">
                                    </Form.Control>
                                </div>
                                <div className="d-flex gap-2 justify-content-between">
                                    <EButton disabled={!this.state.stateChange || (this.props.isUpdating || !!deleteSate)} className="btn btn-primary" ebSize="sm" onClick={this.onPublishClick}>Update Object</EButton>
                                    {this.props.currentObject.object.checkoutUserId === this.props.siteData.user.userId ?
                                    <EButton disabled={!this.state.stateChange || (this.props.isUpdating || !!deleteSate)} className="btn btn-primary" ebSize="sm" onClick={this.onPublishCheckInClick}>Update & Check In</EButton>
                                        :<></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(DataPublishing)
