import {EnvidObjectPublic, SiteDataResult, UserResult} from "../fetchapi";
import {Store} from "redux";


let staticSessionHelper: SessionHelper;

class SessionHelper extends Object{

  private store: Store<any>;

  constructor(store: Store<any>) {
    super();
    this.store = store;
    console.log(' SessionHelper ', this.store);
    staticSessionHelper = this;
  }

  static shared(): SessionHelper{
    return staticSessionHelper;
  }

  public getSessionUser(): UserResult {
    // @ts-ignore
    return <UserResult>this.store.getState().session.siteData.user;
  }

  public isObjectCheckedOutToSessionUser(object: EnvidObjectPublic): boolean{
    if (!!object){
      return object.checkoutUserId == this.getSessionUser().userId;
    }
    return false;
  }

  public isObjectCheckedOutToUser(object: EnvidObjectPublic, userId: string): boolean {
    if (!!object && !!userId) {
      return object.checkoutUserId === userId;
    }
    return false;
  }

}

export default SessionHelper;
