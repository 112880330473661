import React from 'react';
import {SummaryTree, SummaryItem} from '../components/common/SummaryTree'
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner";
import {withTranslation} from "react-i18next";


class DataSummary extends React.Component {

    extractObjectId(contexted)
    {
        let splitted = contexted.split("::");
        let object_id = splitted[splitted.length-1];
        return object_id;

    }

    renderObject(object)
    {
        let style_small_space = {marginRight:"5px"};

        let ret = [];

        let type = object.type;
        if (object.displayed_object_type != null) type = object.displayed_object_type;
        ret.push(<EnvidObjectIcon type={type} showName={false}/>);
        ret.push(<span style={style_small_space}/>);
        ret.push(object.name);

        return <span className="tree-item">&nbsp;{ret}</span>
    }


    renderChildren(object_ids)
    {
        //console.log(JSON.stringify(object_ids));
        if (object_ids == null || object_ids.length === 0) {
            return null;
        }

        let children = [];
        for (let i=0; i<object_ids.length; ++i)
        {
            let contexted = object_ids[i]
            let object_id = this.extractObjectId(contexted);

            //let object_id = this.nextObject(i,object_ids);
            let object = this.props.summary.objects[object_id];
            // if(object.type == "Impact" || object.type == "Control" || object.type == "Receptor"){
            //     object_id = this.nextObject(i,object_ids);
            // }

            let child_object_ids = this.props.summary.children[contexted];

            // sort the child_object_ids
            child_object_ids.sort((a, b) => {
                let oA = this.props.summary.objects[this.extractObjectId(a)];
                let oB = this.props.summary.objects[this.extractObjectId(b)];
                let nameA = oA.name;
                let nameB = oB.name;
                if (oA.type === "Control") nameA = "b"+nameA;
                if (oA.type === "Receptor") nameA = "a"+nameA;
                if (oB.type === "Control") nameB = "b"+nameB;
                if (oB.type === "Receptor") nameB = "a"+nameB;
                return (nameB < nameA) ? 1 : -1
            });
            let child = <SummaryItem key={object_id} object={object}>{this.renderChildren(child_object_ids)}</SummaryItem>
            children.push(child);
        }


        return children;
    }

    //Attempting to sort things with the least children first
    //returns the next object_id in object_ids that has the smallest number of children
    nextObject(index,object_ids){

        if (object_ids.length === 1){
            return object_ids[0];
        }

        let unsorted = [];
        for (let i=0; i<object_ids.length; ++i){

            unsorted.push({[object_ids[i]]: this.props.summary.children[object_ids[i]].length});
        }

        console.log("unsorted");
        console.log(JSON.stringify(unsorted));

        let sorted = unsorted.sort( (a,b) => {
            var keya = Object.keys(a)[0];
            var keyb = Object.keys(b)[0];

            //console.log("keya: "+keya);
            //console.log("keyb: "+keyb);

            return a[keya].localeCompare(b[keyb]);
        });

        console.log("sorted");
        console.log(JSON.stringify(sorted));

        let next = sorted[index];
        let next_id = Object.keys(next)[0];
        console.log("next: "+next_id);

        return next_id;

        //return object_ids[index];
    }


    render() {

        const {t} = this.props;

        let summaryElement = null;
        if (this.props.summary != null)
        {
            let summary = this.props.summary;

            // find the top leve objects
            let top = [];

            for (var object_id in summary.objects) {
                let obj = summary.objects[object_id];
                if (obj.type === summary.top) {
                    top.push(object_id);
                }
            }

            let summaryElement = null;
            if (top.length === 0)
            {
                summaryElement = <div>{t("Empty")}</div>
            } else {

                summaryElement = (<SummaryTree renderObject={this.renderObject} callback={(object) => this.props.callbacks.clickedObject((object.displayed_object_id==null)?object.objectId : object.displayed_object_id)}>{this.renderChildren(top)}</SummaryTree>);
            }

            return summaryElement;
        }

        if (this.props.loading === true){
            summaryElement = <Spinner />;
        }

        return (
            <div className="ibox-content" style={this.props.style}>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="panel panel-default">
                            <div className="panel-heading"><h3>{t("Summary")}</h3>
                                {summaryElement}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default withTranslation()(DataSummary)
