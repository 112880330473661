import React, {Component, useState} from "react";
import EbasePanel from "../components/common/EbasePanel";
import EButton from "../components/common/EButton";
import EBSwitch from "../components/common/EBSwitch";
import ContainerHeader from "../components/common/ContainerHeader";
import {Button, Modal, Tab, Table, Tabs} from "react-bootstrap";
import PageWrapper from "../components/common/PageWrapper";
import {PublishingHomeProps} from "../components/containers/PublishingHomeContainer";
import Spinner from "../components/common/Spinner";
import EBTable from "../components/common/EBTable";
import envid from "../utils/envid";
import EnvidObjectIcon from "../components/common/EnvidObjectIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faRocketLaunch, faUpRightFromSquare, faWarning} from "@fortawesome/pro-solid-svg-icons";
import SessionManager from "../utils/SessionManager";
import {EnvidObjectSmallResult, UserResult} from "../fetchapi";
import {Link} from 'react-router-dom';
import {Trans, withTranslation} from "react-i18next";
import Select from "react-select";
import LibraryAuditContainer from "../components/containers/LibraryAuditContainer";
import LibraryPublishSummary from "../components/common/LibraryPublishSummary";

interface HistoryModalProps {
    show: boolean;
    onHide: () => void;
    columns: any;
    data: any;
    libraryName: string;
    version: string;
    numPublishedObjects: number;
    dataLoaded: boolean;
}

const HistoryModal: React.FC<HistoryModalProps> = (props) => {
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="d-flex gap-2 m-t-sm">
                    <div className="w-auto">
                        <div className="bg-primary rounded-circle eb-div-ctr" style={{width: "36px", height: "36px"}}>
                            <FontAwesomeIcon icon={faRocketLaunch} size="xl"/>
                        </div>
                    </div>
                    <div className="flex-grow-1" style={{maxWidth: "100%"}}>
                        <p>List of {props.numPublishedObjects} object {(props.numPublishedObjects == 1) ? " " : "s "} published in <b>{props.libraryName} - Version {props.version}</b></p>
                        {(props.dataLoaded) ? <EBTable columns={props.columns} data={props.data} responsive></EBTable> : <Spinner></Spinner>}
                        <div className="eb-div-r">
                            <EButton onClick={props.onHide} ebSize="sm">Close</EButton>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

interface PublishModalProps {
    show: boolean;
    onHide: () => void;
    libraryName: string;
    unapproved: boolean;
    selectedObjs: any[];
    versionHistory: any[];
}

const PublishModal: React.FC<PublishModalProps> = (props) => {
    const [formData, setFormData] = useState({
        versionInput: '',
        notesInput: '',
        emailInput: false,
    });
    const [loading, setLoading] = useState(false);
    const [publishMessage, setPublishMessage] = useState<string|null>();
    const [publishError, setPublishError] = useState<string|null>();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value, type} = e.target;
        const checked = type === 'checkbox' ? (e.target as HTMLInputElement).checked : undefined;
        setFormData(prevData => ({
            ...prevData,
            [id]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const sharedIds: string[] = props.selectedObjs;
        doPublish(formData.versionInput, formData.notesInput, formData.emailInput, sharedIds);
    };

    const doPublish = (version: string, notes: string | null | undefined, notifyUsers: boolean, selection: string[]): void => {

        setLoading(true);
        setPublishError(null);
        setPublishError(null)

        const ebaseDs = SessionManager.shared().getEbaseDS();
        ebaseDs.publishCurrentDataset(version, (notes ?? ''), notifyUsers, selection).then(result => {
            setPublishMessage("Publish success");
            setLoading(false);
        }).catch(reason => {
            SessionManager.shared().showToast("Publish Failed", reason, 'danger');
            setPublishError(reason);
            setLoading(false);
        });
    }

    const dismissAction = () => {
        props.onHide();
        setPublishError(null);
        setPublishMessage(null);
        setFormData({versionInput: '',notesInput: '', emailInput: true});
    }


    let unapprovedComp =
        <div className="d-flex gap-2 border-left border-size-md border-warning bg-warning-subtle p-2 mb-3">
            <div><FontAwesomeIcon icon={faWarning}></FontAwesomeIcon></div>
            <div>
                <p><b>You Are Publishing Unapproved Objects</b></p>
                <p> You have selected one or more objects that have <b>not yet</b> been approved.</p>
            </div>
        </div>

    let lastVersion = props.versionHistory[props.versionHistory.length - 1] ? props.versionHistory[props.versionHistory.length - 1].version : null;

    let content: React.ReactNode;
    if (!(!!publishMessage || !!publishError)) {
        content = <>
            <div className="row">
                <h3>Publish Library - New Version</h3>
            </div>

            {props.unapproved ? unapprovedComp : <></>}
            <div className="row">
                <p>You about to publish <b>{props.libraryName}</b>&nbsp;to a new version. Enter a version number and
                    publishing notes. </p>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <div className="col-sm-4">
                        <label htmlFor="versionInput" className="m-t-xs form-label">Version Number</label>
                    </div>
                    <div className="col-sm-8">
                        <input type="text" className="form-control rounded-1"
                               id="versionInput"
                               disabled={loading}
                               value={formData.versionInput}
                               onChange={handleChange}
                               style={{fontSize: "12px"}}
                               placeholder={"Enter a new version number"}/>
                        {lastVersion ? <div className="text-info m-l-sm"><i>{"Previous version: " + lastVersion}</i></div> : <></>}
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-sm-4">
                        <label htmlFor="notesInput" className="m-t-xs form-label">Publishing Notes</label>
                    </div>
                    <div className="col-sm-8">
                        <textarea className="form-control rounded-1"
                                  id="notesInput"
                                  disabled={loading}
                                  value={formData.notesInput}
                                  onChange={handleChange}
                                  style={{fontSize: "12px"}} rows={3}
                                  placeholder="Enter publishing notes"></textarea>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-sm-4">
                        <label htmlFor="emailCheck" className="m-t-xs form-label">Notify members?</label>
                    </div>
                    <div className="col-sm-8 d-flex align-content-center">
                        <input type="checkbox"
                               id="emailInput"
                               disabled={loading}
                               checked={formData.emailInput}
                               onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="row mb-3">

                    <div className="d-flex justify-content-end gap-2">
                        {loading ? <Spinner style={{margin: 'unset', height: '30px' }}/> : []}
                        <Button size="sm" className="btn btn-secondary btn-outline"
                                disabled={loading}
                                onClick={dismissAction}>Cancel</Button>
                        <EButton ebSize="sm" onClick={handleSubmit}
                                 disabled={formData.versionInput.trim() === '' || loading}>Publish</EButton>
                    </div>
                </div>
            </form>
        </>
    }else{
        content =
          <>
              <div className="row">
                  <h3>Publish Library - New Version</h3>
              </div>
              {!!publishError ?
                <div className="d-flex gap-2 border-left border-size-md border-warning bg-warning-subtle p-2 mb-3">
                    <div><FontAwesomeIcon icon={faWarning}></FontAwesomeIcon></div>
                    <div>
                        <p><b>Publishing Failed</b></p>
                        <p>There was a problem publishing this new library version.</p>
                        <p>{publishError}</p>
                    </div>
                </div>
                : <></>}

              {!!publishMessage ?
                <div className="d-flex gap-2 border-left border-size-md border-successss bg-success-subtle p-2 mb-3">
                    <div><FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon></div>
                    <div>
                        <p><b>Publishing Success</b></p>
                        <p>New library version published successfully.</p>
                    </div>
                </div>
                : <></>}
                  <div className="row mb-3">
                      <div className="d-flex justify-content-end gap-2">
                          <Button size="sm" className="btn btn-secondary btn-outline"
                                  onClick={dismissAction}>Done</Button>
                      </div>
                  </div>
          </>
    }

    return (
      <Modal
             show={props.show}
             onHide={props.onHide}
        // size="md" //no size defaults to medium
             aria-labelledby="contained-modal-title-vcenter"
             centered>
          <Modal.Body>
              <div className="d-flex gap-2 m-t-sm">
                  <div className="w-auto">
                      <div className="bg-primary rounded-circle eb-div-ctr" style={{width: "36px", height: "36px"}}>
                          <FontAwesomeIcon icon={faRocketLaunch} size="xl"/>
                      </div>
                  </div>
                  <div className="flex-grow-1 container">
                      {content}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

interface PublishLibraryViewState {
    historyModalShow: boolean;
    historyModalDataLoaded: boolean;
    publishModalShow: boolean;
    showAllObjects: boolean;
    publishData: any[];
    approvedLength: number;
    publishDataWarning: boolean[];
    publishDataSelected: boolean[];
    versionHistoryData: any[];
    selectedVersion: any;
    currentTab: string;
    tempLibraryOwner: any;
}

class PublishLibraryView extends React.Component<PublishingHomeProps, PublishLibraryViewState> {

    // @ts-ignore
    constructor(props) {
        super(props);
        // this.props.loadOwnerList();
        //publishData is linked via props to state in manage.js: changedObjects: []
        // mapped to table data on construction

        let approvedLength = this.getChangedObjects("Approved").length;
        let tempPublishData = this.getChangedObjects("");
        this.state = {
            historyModalShow: false,
            historyModalDataLoaded: false,
            publishModalShow: false,
            showAllObjects: true,
            currentTab: 'pub',
            selectedVersion: {version: "", versionId: "", numObjs: 0},
            versionHistoryData: [],
            publishData: this.getChangedObjects(""),
            approvedLength: approvedLength,
            publishDataWarning: new Array(this.props.changedObjs.length).fill(false, 0, approvedLength).fill(true, approvedLength),
            publishDataSelected: new Array(approvedLength).fill(false).map((value, index) => (tempPublishData[index]?.state === "Deleted")),
            tempLibraryOwner: this.props.currLibraryOwner ?? null,
        };
    }

    componentDidMount() {
        this.props.loadLibrarySummary();

    }

    setHistoryModalShow = (show: boolean) => {
        this.setState({ historyModalShow: show });
    };

    setPublishModalShow = (show: boolean) => {
        this.setState({...this.state, publishModalShow: show });
        if (show == false) {
            this.props.loadPublishInfo();
        }
    };

    getChangedObjects = (publishState: string) => {
        if (publishState === "") {
            return this.props.changedObjs.map((obj, index) => ({
                objectType: obj.type,
                objectName: {name: obj.name, id: obj.sharedId},
                state: !obj.deleteState ? obj.publishState : "Deleted",
                checked: !obj.deleteState ? (obj.checkoutUserId ? "Checked Out" : "Checked In") : "",
            })).sort((a, b) => {
                if ((a.state === "Deleted" || (a.state === 'Approved' && a.checked === "Checked In"))  && !(b.state === "Deleted" || (b.state === 'Approved' && b.checked === "Checked In"))) {
                    return -1; // Move 'Approved' and Deleted before others
                }
                if ((b.state === "Deleted" || (b.state === 'Approved' && b.checked === "Checked In"))  && !(a.state === "Deleted" || (a.state === 'Approved' && a.checked === "Checked In"))) {
                    return 1;
                }
                return 0;
            }).map((obj,index) => ({
                ...obj,
                warning: index,
                checkbox: index
            }));
        } else {
            return this.props.changedObjs.map((obj,index) => {
                if (obj.deleteState || (obj.publishState === publishState && obj.checkoutUserId == null)) { //e.g. publishState = "Approved" and checked in
                    return ({
                        objectType: obj.type,
                        objectName: {name: obj.name, id: obj.sharedId},
                        state: !obj.deleteState ? obj.publishState : "Deleted",
                        checked: !obj.deleteState ? (obj.checkoutUserId ? "Checked Out" : "Checked In") : "",
                    });
                }
                return null;
            }).filter(item => item !== null).map((obj,index) => ({
                ...obj,
                warning: index,
                checkbox: index
            }));
        }
    }

    getSelectedObjects = () => {
        return this.state.publishDataSelected.map((value, index) => value ? this.state.publishData[index].objectName.id : null).filter(item => item !== null);
    }

    handleCheckboxChange = (index: number, checked: boolean) => {
        let updatedSelection = this.state.publishDataSelected;
        updatedSelection[index] = checked;
        this.setState({ publishDataSelected: updatedSelection });
    };

    handleHeaderCheckboxChange = (checked: boolean) => {
        if (checked) {
            this.setState({publishDataSelected: this.state.publishDataSelected.fill(checked)});
        } else {
            this.setState( {publishDataSelected: this.state.publishDataSelected.map((value, index) => (this.state.publishData[index]?.state === "Deleted"))});
        }
    }

    //This dynamically loads the selected history version for the popup
    selectHistoryVersion = ( re: any) => {
        // console.log('selectHistoryVersion', this.props.datasetId, re.versionId, re.version );
        this.setState({historyModalDataLoaded: false});
        const ebaseDs = SessionManager.shared().getEbaseDS();
        ebaseDs.loadDatasetChangedObjects(re.versionId).then((result: EnvidObjectSmallResult[]) => {
            this.setState({versionHistoryData: result!.map((obj,index) => {return {objectType: obj.type, objectName: obj.name, state: !obj.deleteState ? obj.publishState : "Deleted"};})});
            this.setState({selectedVersion: {version: re.version, versionId: re.versionId, numObjs: re.objs}})
            this.setState({historyModalDataLoaded: true});
        })
        this.setHistoryModalShow(true);
    }

    tabChange = (key: string | null) => {
        if (!!key) {
            this.setState({currentTab: key});
        }
    }

    render() {
        //Loading screen for when global state is still loading (manage.js)
        if (this.props.isLoading) {
            return(
                <div>
                    <ContainerHeader title="Publishing"  dimensions={this.props.dimensions} crumbs={['/', '/publishing']}/>
                    <PageWrapper dimensions={this.props.dimensions} noScroll={true}>
                        <Spinner/>
                    </PageWrapper>
                </div>
            );
        }

        //Set exact tab height for scrolling
        let tabHeight = null;
        if (this.props.dimensions) {
            tabHeight = this.props.dimensions.window.height - this.props.dimensions.extras.height - 70;
        }

        //Table definition for Publish Library table
        const publishCols = [
            {
                header: '',
                field: 'warning',
                render: (re: any) => (this.state.publishDataWarning[re] ?
                    <FontAwesomeIcon icon={faWarning} size="lg" title={"Objects must be checked in and approved to publish."}></FontAwesomeIcon> :
                    <div style={{width: '18px'}}></div>),
                style: {width: '20px'},
            },
            {
                header: 'Object Type',
                field: 'objectType',
                sortable: true,
                render: (re: any) => (<EnvidObjectIcon type={re} showName={true}/>),
                style: {width: '200px'},
            },
            {
                header: <div className="eb-div-ctr w-100">Checked Status</div>,
                field: 'checked',
                render: (st: any) => (st !== "" ?
                    <div className="eb-div-ctr">
                        <span style={{backgroundColor: (st === "Checked Out" ? 'grey' : envid.getPublishStateColour("Approved")), color: 'white', width: 'fit-content', fontSize: "12px"}} className="label text-nowrap">
                            {st}
                        </span>
                    </div> : <></>),
                style: {width: '140px'},
            },
            {
                header: <div className="eb-div-ctr w-100">State</div>,
                field: 'state',
                render: (st: any) => <div className="eb-div-ctr"><span style={{...envid.getPublishTextStyle(st), width: 'fit-content', fontSize: "12px"}} className="label text-nowrap">{envid.getPublishStateName(st)}</span></div>,
                style: {width: '140px'},
            },
            {
                header: 'Object Name',
                field: 'objectName',
                sortable: true,
                render: (re: any) => (<span>{re.name}&nbsp;<Link to={"/enviddata/object/" + re.id + "/pub"}>{"View  "}<i className="fa fa-external-link-square"></i></Link></span>),
            },
            {
                header: <>{this.state.publishDataSelected.length > 0 ? <input className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"
                                            checked={!this.state.publishDataSelected.includes(false)}
                                            onChange={(e) => {this.handleHeaderCheckboxChange(e.target.checked)}}/> : <></>}</>,
                field: 'checkbox',
                render: (re: any) => ((re < this.state.publishDataSelected.length) ?
                    <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        data-custom={re}
                        checked={this.state.publishDataSelected[re]}
                        onChange={(e) => {this.handleCheckboxChange(re, e.target.checked);}}
                        aria-label="Checkbox for following text input"
                        disabled={this.state.publishData[re].state === "Deleted"}/> : <></>),
                style: {width: '16px'},
            },
        ];

        //Table definition for History table
        const historyCols = [
            { header: 'Version', field: 'version'},
            { header: 'Published By', field: 'author', sortable: true  },
            {
                header: 'Date/Time',
                field: 'datetime',
                sortable: true,
                compare: (a: string, b: string) => {
                    let dateA = new Date(a);
                    let dateB = new Date(b);
                    return dateA >= dateB;
                }
            },
            { header: 'Details', field: 'details'},
            {
                header: 'Published Objects',
                field: 'publishedObj',
                render: (re: any) => <a className="text-decoration-none"
                                        onClick={() => this.selectHistoryVersion(re)}>
                                        {re.objs} Object{re.objs == 1 ? " ":"s "} <FontAwesomeIcon icon={faUpRightFromSquare}></FontAwesomeIcon>
                                    </a>
            },
        ];

        let historyData = this.props.historyRecords.map((obj,index) => {
            return ({
                version: obj.version ?? "",
                author: (obj.userEvent?.firstName ?? "") + " " + (obj.userEvent?.lastName ?? ""),
                datetime: obj.userEvent?.eventDate?.toLocaleString() ?? "",
                details: obj.notes ?? "",
                publishedObj: {objs: obj.numPublishedObjects ?? 0, version: obj.version ?? "", versionId: obj.id ?? ""}
            })
        }).reverse();

        const historyModalCols = [
            { header: 'Object Type', field: 'objectType', sortable: true, render: (re: any) => (<EnvidObjectIcon type={re} showName={true}/>)},
            { header: 'Object Name', field: 'objectName', sortable: true  },
            {
                header: <div className="eb-div-ctr w-100">Status</div>,
                field: 'state',
                render: (st: any) => <div className="eb-div-ctr"><span style={{...envid.getPublishTextStyle(st), width: 'fit-content', fontSize: "12px"}} className="label">{st}</span></div>
            },
        ];

        //Defining Objects to render
        let publishInfo = <Trans i18nKey="list-approved-objects-one"><p>List of approved objects that have changed.</p></Trans>;
        if (!!this.props.publishedVersion) {
            publishInfo = <Trans i18nKey="list-approved-objects-two" values={{version: this.props.publishedVersion?.version}}><p>List of objects that have changed since version&nbsp;{this.props.publishedVersion?.version}&nbsp;was published.</p></Trans>;
        }
        let changedInfo = (this.props.changedObjs.length ?? 0) + ' object' + (this.props.changedObjs.length != 1 ? 's' : '') + ' have been changed and ' + (this.state.approvedLength ?? 0) + ' object' + (this.state.approvedLength != 1 ? 's are' : ' is') + ' ready to publish.';
        let objectsInfo = <></>;
        if ( !this.state.publishData || this.state.publishData.length == 0 ) {
            objectsInfo = this.state.showAllObjects ?
                <Trans i18nKey="No changed objects to display">No changed objects to display.</Trans> :
                <Trans i18nKey="No approved objects to display">No approved and checked in objects to display.</Trans>;
        }

        let selectedLen = this.state.publishDataSelected.filter(value => value).length;
        let publishLib =
            <EbasePanel>
                <div className="d-flex justify-content-between align-content-center gap-2">
                    <div>
                        <h4>{this.props.publishedVersion != null ? 'Published Version: '+this.props.publishedVersion?.version : 'No Published Version Yet'}</h4>
                        {publishInfo}
                        <p>{changedInfo}</p>
                    </div>
                    <div className="d-flex gap-2">
                        <span>{this.state.showAllObjects ?
                            <Trans i18nKey="Showing all objects">Showing all objects.</Trans> :
                            <Trans i18nKey="Showing allowed objects"> Showing checked in and approved objects.</Trans>
                        }</span>
                        <EBSwitch
                            id="custom-switch-1"
                            onChange={(checked: boolean) => {
                                this.setState({
                                    showAllObjects: checked,
                                    publishData: checked ? this.getChangedObjects("") : this.getChangedObjects("Approved")
                                });
                            }}
                            checked={true}  // Default to checked
                        />
                    </div>
                </div>
                <div className="row p-2">
                    {this.state.publishData.length > 0 ?
                        <EBTable columns={publishCols} data={this.state.publishData} hover
                                 responsive></EBTable> : <></>}
                </div>
                <div className="d-flex justify-content-end align-content-center gap-2">
                    <div>{objectsInfo}</div>
                    <div className="flex-grow-1"></div>
                    <div className="eb-div-ctr">{selectedLen} object{selectedLen != 1 ? 's' : ''} selected</div>
                    <div>
                        <EButton type="publish" ebSize="sm" onClick={() => this.setPublishModalShow(true)} disabled={selectedLen === 0}>Publish Library</EButton>
                    </div>
                </div>
            </EbasePanel>;

        let history =
            <EbasePanel>
                <h2>Library Publishing History</h2>
                <div className="row p-2">
                    <EBTable columns={historyCols} data={historyData}></EBTable>
                </div>
            </EbasePanel>;

        let libDetails =
            <EbasePanel>
                <h2>Library Details</h2>
                <hr></hr>
                <div className="row">
                    <div className="col-lg-8">
                        <div className={"card card-default"}>
                            <div className="card-header d-flex justify-content-between  align-items-center">
                                <div><h3 className="">{"Library Owner"}</h3></div>

                                {this.props.siteData?.dataset?.ownerName ? <div>{"The current Owner of this library is "}<b>{this.props.siteData.dataset.ownerName}</b></div> : <div><i>{"No current owner."}</i></div>}
                            </div>
                            <div className="card-body p-lg text-left">
                                <p><b>{"Change Library Owner"}</b></p>
                                <div className="d-flex w-100 gap-4">
                                    <label className="eb-div-ctr">{"Library Owner: "}</label>

                                    {this.props.userList && <Select
                                        className="flex-grow-1"
                                        options={this.props.userList.map((value) => {
                                            return {value: value.userId, label: value.firstname + " " + value.lastname + " - " + value.email};
                                        })}
                                        onChange={(val) => {
                                            this.setState({tempLibraryOwner: val});
                                        }}
                                        value={this.state.tempLibraryOwner}
                                        isSearchable={true}
                                        isClearable={true}
                                    />}
                                    <EButton type="" onClick={() => {
                                        this.props.changeOwner(this.state.tempLibraryOwner ? this.state.tempLibraryOwner.value : null);
                                    }}>{"Update Library Owner"}</EButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 ">
                        <div className={"card card-default"}>
                            <div className="card-header d-flex justify-content-between  align-items-center">
                                <div><h3 className="">{"Library Summary"}</h3></div>
                            </div>
                            <div className="card-body p-lg text-left">
                                <LibraryPublishSummary
                                    summary={this.props.publishSummary}
                                    loading={this.props.isPublishSummaryLoading}
                                    viewObjects={this.props.viewObjectsWithState}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </EbasePanel>

        //Library name for display
        let dataset = this.props.siteData?.dataset;
        let publishModal = <PublishModal
            show={this.state.publishModalShow}
            onHide={() => this.setPublishModalShow(false)}
            libraryName={dataset?.name ?? "Unknown"}
            unapproved={false}
            selectedObjs={this.getSelectedObjects()}
            versionHistory={this.props.historyRecords}
        />;

        let historyModal = <HistoryModal
                show={this.state.historyModalShow}
                onHide={() => {this.setHistoryModalShow(false);}}
                columns={historyModalCols}
                data={this.state.versionHistoryData}
                libraryName={dataset?.name ?? "Unknown"}
                version={this.state.selectedVersion.version}
                numPublishedObjects={this.state.selectedVersion.numObjs}
                dataLoaded={this.state.historyModalDataLoaded}
            />

        return (
            <div>
                <ContainerHeader title="Manage Library"  dimensions={this.props.dimensions} crumbs={['/', '/publishing']}/>
                <PageWrapper dimensions={this.props.dimensions} noScroll={true}>
                    <Tabs id="publish-tabs" activeKey={this.state.currentTab}
                          onSelect={(key, e) => this.tabChange(key)}>
                        <Tab title="Publish Library" eventKey="pub">
                            <div className="overflow-y-auto overflow-x-hidden" style={tabHeight ? {height: tabHeight} : {}}>{publishLib}</div>
                        </Tab>
                        <Tab title="Version History" eventKey="hist">
                            <div className="overflow-y-auto overflow-x-hidden" style={tabHeight ? {height: tabHeight} : {}}>{history}</div>
                        </Tab>
                        <Tab title="Library Details" eventKey="det">
                            <div className="overflow-y-auto overflow-x-hidden" style={tabHeight ? {height: tabHeight} : {}}>{libDetails}</div>
                        </Tab>
                        <Tab title="Audit Log" eventKey="aud">
                            <LibraryAuditContainer height={tabHeight}></LibraryAuditContainer>
                        </Tab>
                    </Tabs>
                </PageWrapper>
                {historyModal}
                {publishModal}
            </div>
        );
    }
}

export default withTranslation()(PublishLibraryView)
