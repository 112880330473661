import React from 'react';
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import {Tab,Tabs} from 'react-bootstrap'
import Spinner from "../components/common/Spinner"
import EButton from "../components/common/EButton"
import ProjectDetailsContainer from '../components/containers/ProjectDetailsContainer'
import ProjectMilestoneContainer from '../components/containers/ProjectMilestoneContainer'
import ProjectRolesContainer from '../components/containers/ProjectRolesContainer'
import ProjectManageContainer from '../components/containers/ProjectManageContainer'
import TabCheck from "../components/common/TabCheck"
import ProjectActionStatus from "../components/common/ProjectActionStatus"
import {withTranslation} from "react-i18next";
import sitedata from "../utils/sitedata";


class ProjectView extends React.Component {

    constructor(props){

        super(props);

        // console.log("projectView");
        // console.log(props);

        this.actionClick = this.actionClick.bind(this);

        let canRead = sitedata.checkReadAccess(this.props,"enviddata");
        let canWrite = sitedata.checkWriteAccess(this.props,"enviddata");
        this.state = {
            detailsReadOnly: canRead && !canWrite,
        };
    }

    componentWillMount()
    {
        let params = this.props.match.params;
        let object_id = params.objectId;
        if (this.props.currentObject == null || this.props.currentObject.object.objectId !== object_id) {
            let p = null;
            if (this.props.list != null)
            {
                p = this.props.list.find((p) => p.objectId === object_id);
            }
            this.props.loadProject(object_id, (p != null) ? p.name : "");
        }
    }

    actionClick(action){

        if(action === "ira_report"){
            this.props.editReport();
        }else if(action === "control_report"){
            this.props.editControlsSummaryReport();
        }else if(action === "compliance"){
            this.props.editCompliance();
        }else if(action === "stakeholder"){
            this.props.editStakeholder();
        }

    }


    render() {

        // console.log("ProjectView Render");
        //  console.log(this.props);
        //
        // console.log("-----------------------------------");
        const {t} = this.props;

        let params = this.props.match.params;
        let object_id = params.objectId;
        let object = this.props.currentObject;

        var title;
        var body;
        var subtitle = "";

        if (this.props.isLoading) {

            title = "Project";
            if (this.props.list != null)
            {
                let p = this.props.list.find((p) => p.objectId === object_id);
                if (p != null)
                {
                    title = p.name;
                }
            }

            body = (
                <PageWrapper dimensions={this.props.dimensions} noScroll={false}>
                    <Spinner/>
                </PageWrapper>
            );
        }else {

            if (this.props.changes.savedStatus === "unchanged") {
                subtitle = "";
            }
            else if (this.props.changes.savedStatus === "saved") {
                subtitle = t("All Changes Saved");
            }
            else if (this.props.changes.savedStatus === "saving") {
                subtitle = t("Saving") + "...";
            }
            else {
                subtitle = t("Saving") + "...";
            }

            if (object == null || object.object == null) {
                title = "Project not Found";
                body = <div>{t(title)}</div>
            }else {

                title = object.object.details.name;

                let containers = {
                    "info": {
                        "name": t("Info"),
                        "component": React.cloneElement(<ProjectDetailsContainer/>, this.props)
                    },
                    "milestones": {"name": t("Milestones"), "component": React.cloneElement(<ProjectMilestoneContainer/>,this.props)},
                    "roles": {"name": t("Roles"), "component": React.cloneElement(<ProjectRolesContainer/>,this.props)},
                    "manage":{"name":t("Manage"),"component":React.cloneElement(<ProjectManageContainer/>,this.props)}
                };

                if(this.state.detailsReadOnly){
                    containers = {
                        "info": {
                            "name": t("Info"),
                            "component": React.cloneElement(<ProjectDetailsContainer/>, {...this.props, detailsReadOnly: this.state.detailsReadOnly})
                        }
                    };
                }

                let tabs = [];

                let activeTab = params.tabName;
                if(activeTab === null || activeTab === undefined){
                    activeTab = "info";
                }

                for (var con in containers) {
                    tabs.push(
                        <Tab key={con} eventKey={con} title={containers[con].name}>
                            <TabCheck eventKey={con} activeKey={activeTab}>
                                {containers[con].component}
                            </TabCheck>
                        </Tab>
                    );
                }

                body = (
                    <PageWrapper dimensions={this.props.dimensions} noScroll={false}>


                        <div className="row">
                            <div className="col-lg-8">

                                        <div className="tabs-container">
                                            <Tabs id="object-tabs" activeKey={activeTab}
                                                  onSelect={this.props.selectTab}>
                                                {tabs}
                                            </Tabs>
                                        </div>
                            </div>

                            <div className="col-lg-4">

                                <div className="ibox float-e-margins" style={{marginTop:40}}>

                                    <ProjectActionStatus
                                        status={this.props.project_status}
                                        loading={this.props.isStatusLoading}
                                        actionClick={this.actionClick}
                                        readOnly={this.props.readOnly}/>


                                </div>

                                <div className="panel panel-default d-none">
                                    <div className="panel-heading"><h3>Actions</h3></div>
                                    <div className="panel-body p-lg text-left">

                                        <EButton type="report" onClick={this.props.editReport}> Impact & Risk
                                            Assessment</EButton><br/>
                                        <EButton type="report"
                                                 onClick={this.props.editControlsSummaryReport}> {t("Controls Summary")}</EButton><br/>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </PageWrapper>
                )
            }


        }

        return (
            <div>
                <ContainerHeader title={title}
                                 subtitle={subtitle}
                                 crumbs={['/','/projects',t('Project')]}
                                 dimensions={this.props.dimensions}/>
                {body}
            </div>
        )
    }
}

export default withTranslation()(ProjectView)
