/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnformattedText
 */
export interface UnformattedText {
    /**
     * 
     * @type {string}
     * @memberof UnformattedText
     */
    text?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UnformattedText
     */
    attributes?: { [key: string]: string; };
}

/**
 * Check if a given object implements the UnformattedText interface.
 */
export function instanceOfUnformattedText(value: object): value is UnformattedText {
    return true;
}

export function UnformattedTextFromJSON(json: any): UnformattedText {
    return UnformattedTextFromJSONTyped(json, false);
}

export function UnformattedTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnformattedText {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'] == null ? undefined : json['text'],
        'attributes': json['attributes'] == null ? undefined : json['attributes'],
    };
}

export function UnformattedTextToJSON(value?: UnformattedText | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'attributes': value['attributes'],
    };
}

