/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkRequest
 */
export interface LinkRequest {
    /**
     * 
     * @type {string}
     * @memberof LinkRequest
     */
    linkType?: LinkRequestLinkTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LinkRequest
     */
    objectType?: LinkRequestObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LinkRequest
     */
    objectId?: string;
}


/**
 * @export
 */
export const LinkRequestLinkTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type LinkRequestLinkTypeEnum = typeof LinkRequestLinkTypeEnum[keyof typeof LinkRequestLinkTypeEnum];

/**
 * @export
 */
export const LinkRequestObjectTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type LinkRequestObjectTypeEnum = typeof LinkRequestObjectTypeEnum[keyof typeof LinkRequestObjectTypeEnum];


/**
 * Check if a given object implements the LinkRequest interface.
 */
export function instanceOfLinkRequest(value: object): value is LinkRequest {
    return true;
}

export function LinkRequestFromJSON(json: any): LinkRequest {
    return LinkRequestFromJSONTyped(json, false);
}

export function LinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'linkType': json['link_type'] == null ? undefined : json['link_type'],
        'objectType': json['object_type'] == null ? undefined : json['object_type'],
        'objectId': json['object_id'] == null ? undefined : json['object_id'],
    };
}

export function LinkRequestToJSON(value?: LinkRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'link_type': value['linkType'],
        'object_type': value['objectType'],
        'object_id': value['objectId'],
    };
}

