/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEvent
 */
export interface UserEvent {
    /**
     * 
     * @type {string}
     * @memberof UserEvent
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEvent
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEvent
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEvent
     */
    lastName?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserEvent
     */
    eventDate?: Date;
}

/**
 * Check if a given object implements the UserEvent interface.
 */
export function instanceOfUserEvent(value: object): value is UserEvent {
    return true;
}

export function UserEventFromJSON(json: any): UserEvent {
    return UserEventFromJSONTyped(json, false);
}

export function UserEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'username': json['username'] == null ? undefined : json['username'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'eventDate': json['eventDate'] == null ? undefined : (new Date(json['eventDate'])),
    };
}

export function UserEventToJSON(value?: UserEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'username': value['username'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'eventDate': value['eventDate'] == null ? undefined : ((value['eventDate']).toISOString()),
    };
}

