import EButton from "./EButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faUpload} from "@fortawesome/pro-light-svg-icons";
import React, {ReactElement} from "react";

interface checkInObject {
    checkoutUserId: string;
}

interface CheckInButtonProps {
    checkInObj: checkInObject;
    locationFlag: "ObjectView" | "HomeView";
    checkinObjectClick: (event: any, info: any, flag: any) => void | undefined;
    checkoutObjectClick: (event: any, info: any, flag: any) => void | undefined;
    currUserId?: string;
}

// React.FC<CheckInButtonProps>
export const CheckInButton:React.FC<CheckInButtonProps> = (props: CheckInButtonProps): ReactElement | null  => {
    let checkedStatus = Boolean(props.checkInObj.checkoutUserId);
    let objectInfo = props.checkInObj;

    let checkInButton = <EButton
        className="btn btn-outline-secondary"
        style={{fontSize: '13px'}} role="button"
        onClick={(event: any) => !!props.checkinObjectClick ? props.checkinObjectClick(event, objectInfo, props.locationFlag) : {}}>
        <FontAwesomeIcon icon={faUpload} size="lg" />
        {/*<i className="fa fa-upload"></i> */}
        &nbsp;&nbsp;Check In
    </EButton>;
    let checkOutButton = <EButton
        variant="primary"
        style={{fontSize: '13px'}} role="button"
        onClick={(event: any) =>!!props.checkoutObjectClick ? props.checkoutObjectClick(event, objectInfo, props.locationFlag) : {}}>
        {/*<i className="far fa-download"></i>*/}
        <FontAwesomeIcon icon={faDownload} size="lg" />
        &nbsp;&nbsp;Check Out
    </EButton>;

    if (checkedStatus && props.currUserId !== objectInfo.checkoutUserId) {
        return <></>
    }

    return (
        checkedStatus ? checkInButton : checkOutButton
    );
}

// export default CheckInButton = CheckInButton;