import React, {useEffect, useState} from 'react';
import { smoothlyMenu } from '../layouts/Helpers';
import AlertsContainer from "../containers/AlertsContainer"
import jq from "../../utils/jq"
import {useTranslation} from 'react-i18next';
import ebase_lite_logo from '../../img/ebase-lite-logo.png';
import banner from '../../img/banner-flag.png';

const TopHeader = React.forwardRef((props, ref) => {

    const [miniNav, setMiniNav] = useState(!!props.isLite);

    useEffect(()=>{
        if (miniNav) {
            jq.find("body").addClass("mini-navbar");
        } else {
            jq.find("body").removeClass("mini-navbar");
        }
        smoothlyMenu();
    },[miniNav]);

    const toggleNavigation = (e) => {
        e.preventDefault();
        setMiniNav(!miniNav);
    }

    const logout = (e) => {
        e.preventDefault();
        props.onLogout();
    }

    const {t} = useTranslation();

    // console.log("TopHeader:: ",props);
    let alert = React.cloneElement(<AlertsContainer/>, props);

    let name = props.title;
    if(name === null || name  === undefined){
        name = "";
    }

    if(props.isLite === true){

        let banHeight = 86;

        return (

            <div className="row">


                <nav className="navbar navbar-static-top" role="navigation" style={{marginBottom: 0}}>

                    <div style={{height:banHeight, width:'100%',display:'flex',justifyContent:'space-between',background: 'linear-gradient(to bottom, #283543 0%,#283543 50%, #ffffff 51%, #ffffff 100%)'}}>

                        <div style={{height:banHeight/2,display:'flex',alignItems:"center"}}>
                            <img alt="Logo" src={ebase_lite_logo} style={{maxHeight:banHeight/2}} onClick={logout}/>
                        </div>
                        <div style={{marginRight:15,width:155, display:'flex',justifyContent:'space-between'}}>
                            <a target='_blank' rel="noopener noreferrer" href="https://ebase.com.au/#contact" style={{color:'white', marginTop:10, fontSize:10}}>{t("CONTACT US")}</a>
                            <img alt="Banner" src={banner} width={76}/>
                        </div>

                    </div>

                </nav>
            </div>

        );
    }

    return (
        <div ref={ref} className="row border-bottom">

            <nav className="debug navbar navbar-expand-lg navbar-static-top" role="navigation" style={{marginBottom: 0, height: '62px'}} >

                <div className="" style={{display:'flex',flexDirection:'row',justifyContent:'space-between', width:'100%', height:"100%", paddingLeft: '20px', gap: '20px'}}>
                    <div className=" eb-div-ctr" style={{}}>
                        <a className="btn btn-primary " onClick={toggleNavigation}
                           href="#"><i className="fa fa-bars"></i> </a>
                    </div>
                    <div className="eb-div-l" style={{flexGrow: 1}}>
                        <span style={{fontSize: '1.5em'}}>{name}</span>
                    </div>
                    <div className="eb-div-r" style={{}}>
                        <ul className="  nav navbar-top-links navbar-right navbar-nav mr-auto mt-2 mt-lg-0">
                            {alert}

                            <li className="nav-item">
                                <></>
                                {/*  This is where the absolute position button appears  New Object New Company */}
                            </li>
                        </ul>
                    </div>
                </div>

            </nav>
        </div>
    )
});

export default TopHeader;
