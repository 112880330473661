/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnvidPublishingInfo } from './EnvidPublishingInfo';
import {
    EnvidPublishingInfoFromJSON,
    EnvidPublishingInfoFromJSONTyped,
    EnvidPublishingInfoToJSON,
} from './EnvidPublishingInfo';
import type { EnvidDetails } from './EnvidDetails';
import {
    EnvidDetailsFromJSON,
    EnvidDetailsFromJSONTyped,
    EnvidDetailsToJSON,
} from './EnvidDetails';
import type { EnvidLink } from './EnvidLink';
import {
    EnvidLinkFromJSON,
    EnvidLinkFromJSONTyped,
    EnvidLinkToJSON,
} from './EnvidLink';
import type { EnvidDocumentInfo } from './EnvidDocumentInfo';
import {
    EnvidDocumentInfoFromJSON,
    EnvidDocumentInfoFromJSONTyped,
    EnvidDocumentInfoToJSON,
} from './EnvidDocumentInfo';
import type { UnformattedText } from './UnformattedText';
import {
    UnformattedTextFromJSON,
    UnformattedTextFromJSONTyped,
    UnformattedTextToJSON,
} from './UnformattedText';
import type { VersionDetailsSmallResult } from './VersionDetailsSmallResult';
import {
    VersionDetailsSmallResultFromJSON,
    VersionDetailsSmallResultFromJSONTyped,
    VersionDetailsSmallResultToJSON,
} from './VersionDetailsSmallResult';

/**
 * 
 * @export
 * @interface EnvidObjectPublic
 */
export interface EnvidObjectPublic {
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    sharedId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    dataset?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    type?: EnvidObjectPublicTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    category?: EnvidObjectPublicCategoryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EnvidObjectPublic
     */
    userFacing?: boolean;
    /**
     * 
     * @type {EnvidDetails}
     * @memberof EnvidObjectPublic
     */
    details?: EnvidDetails;
    /**
     * 
     * @type {EnvidLink}
     * @memberof EnvidObjectPublic
     */
    link?: EnvidLink;
    /**
     * 
     * @type {EnvidDocumentInfo}
     * @memberof EnvidObjectPublic
     */
    documentInfo?: EnvidDocumentInfo;
    /**
     * 
     * @type {EnvidPublishingInfo}
     * @memberof EnvidObjectPublic
     */
    publishingInfo?: EnvidPublishingInfo;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnvidObjectPublic
     */
    references?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    deleteState?: EnvidObjectPublicDeleteStateEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    checkoutUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    checkoutUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    checkoutUserDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    ownerId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectPublic
     */
    ownerName?: string;
    /**
     * 
     * @type {VersionDetailsSmallResult}
     * @memberof EnvidObjectPublic
     */
    versionDetails?: VersionDetailsSmallResult;
    /**
     * 
     * @type {{ [key: string]: UnformattedText; }}
     * @memberof EnvidObjectPublic
     */
    plainTextDetails?: { [key: string]: UnformattedText; };
}


/**
 * @export
 */
export const EnvidObjectPublicTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type EnvidObjectPublicTypeEnum = typeof EnvidObjectPublicTypeEnum[keyof typeof EnvidObjectPublicTypeEnum];

/**
 * @export
 */
export const EnvidObjectPublicCategoryEnum = {
    EnvidData: 'EnvidData',
    ProjectData: 'ProjectData',
    StakholderData: 'StakholderData'
} as const;
export type EnvidObjectPublicCategoryEnum = typeof EnvidObjectPublicCategoryEnum[keyof typeof EnvidObjectPublicCategoryEnum];

/**
 * @export
 */
export const EnvidObjectPublicDeleteStateEnum = {
    DeleteFlagged: 'DELETE_FLAGGED',
    Deleted: 'DELETED'
} as const;
export type EnvidObjectPublicDeleteStateEnum = typeof EnvidObjectPublicDeleteStateEnum[keyof typeof EnvidObjectPublicDeleteStateEnum];


/**
 * Check if a given object implements the EnvidObjectPublic interface.
 */
export function instanceOfEnvidObjectPublic(value: object): value is EnvidObjectPublic {
    return true;
}

export function EnvidObjectPublicFromJSON(json: any): EnvidObjectPublic {
    return EnvidObjectPublicFromJSONTyped(json, false);
}

export function EnvidObjectPublicFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvidObjectPublic {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['objectId'] == null ? undefined : json['objectId'],
        'sharedId': json['sharedId'] == null ? undefined : json['sharedId'],
        'dataset': json['dataset'] == null ? undefined : json['dataset'],
        'type': json['type'] == null ? undefined : json['type'],
        'category': json['category'] == null ? undefined : json['category'],
        'userFacing': json['userFacing'] == null ? undefined : json['userFacing'],
        'details': json['details'] == null ? undefined : EnvidDetailsFromJSON(json['details']),
        'link': json['link'] == null ? undefined : EnvidLinkFromJSON(json['link']),
        'documentInfo': json['documentInfo'] == null ? undefined : EnvidDocumentInfoFromJSON(json['documentInfo']),
        'publishingInfo': json['publishingInfo'] == null ? undefined : EnvidPublishingInfoFromJSON(json['publishingInfo']),
        'references': json['references'] == null ? undefined : json['references'],
        'deleteState': json['deleteState'] == null ? undefined : json['deleteState'],
        'checkoutUserId': json['checkoutUserId'] == null ? undefined : json['checkoutUserId'],
        'checkoutUserName': json['checkoutUserName'] == null ? undefined : json['checkoutUserName'],
        'checkoutUserDisplayName': json['checkoutUserDisplayName'] == null ? undefined : json['checkoutUserDisplayName'],
        'ownerId': json['ownerId'] == null ? undefined : json['ownerId'],
        'ownerName': json['ownerName'] == null ? undefined : json['ownerName'],
        'versionDetails': json['versionDetails'] == null ? undefined : VersionDetailsSmallResultFromJSON(json['versionDetails']),
        'plainTextDetails': json['plainTextDetails'] == null ? undefined : (mapValues(json['plainTextDetails'], UnformattedTextFromJSON)),
    };
}

export function EnvidObjectPublicToJSON(value?: EnvidObjectPublic | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'objectId': value['objectId'],
        'sharedId': value['sharedId'],
        'dataset': value['dataset'],
        'type': value['type'],
        'category': value['category'],
        'userFacing': value['userFacing'],
        'details': EnvidDetailsToJSON(value['details']),
        'link': EnvidLinkToJSON(value['link']),
        'documentInfo': EnvidDocumentInfoToJSON(value['documentInfo']),
        'publishingInfo': EnvidPublishingInfoToJSON(value['publishingInfo']),
        'references': value['references'],
        'deleteState': value['deleteState'],
        'checkoutUserId': value['checkoutUserId'],
        'checkoutUserName': value['checkoutUserName'],
        'checkoutUserDisplayName': value['checkoutUserDisplayName'],
        'ownerId': value['ownerId'],
        'ownerName': value['ownerName'],
        'versionDetails': VersionDetailsSmallResultToJSON(value['versionDetails']),
        'plainTextDetails': value['plainTextDetails'] == null ? undefined : (mapValues(value['plainTextDetails'], UnformattedTextToJSON)),
    };
}

