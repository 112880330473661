import {
    loadAdminReportTemplatesFailed,
    loadAdminReportTemplatesStart,
    loadAdminReportTemplatesSuccess,
} from '../redux/actions/actions';
import ebase from './ajax';
import SessionManager from './SessionManager';

const TemplateManager = {

    loadReportTemplates: () => {
        SessionManager.shared().dispatch(loadAdminReportTemplatesStart());

        SessionManager.shared().getEbaseDS().loadReportTemplates().then(result => {
            SessionManager.shared().dispatch(loadAdminReportTemplatesSuccess(result));
        }).catch(err => {
            SessionManager.shared().dispatch(loadAdminReportTemplatesFailed(err));
        })
    },

    deleteTemplate: (template_id) => {
        SessionManager.shared().dispatch(loadAdminReportTemplatesStart());
        SessionManager.shared().getEbaseDS().deleteTemplate(template_id).then(result => {
            SessionManager.shared().dispatch(loadAdminReportTemplatesSuccess(result));
        }).catch(err => {
            SessionManager.shared().dispatch(loadAdminReportTemplatesFailed(err));
        })
    },

    updateTemplate: (template, templateFile) => {
        return new Promise((resolve, reject) => {
            let form = new FormData();
            for (const fieldName in template) {
                if (template.hasOwnProperty(fieldName) && template[fieldName]) {
                    form.set(fieldName, template[fieldName]);
                }
            }
            if (templateFile) {
                form.set('fileData', templateFile);
            }
            ebase.ajax({
                url: '/ebase/core/write-system-report-template',
                data: form,
                enctype: 'multipart/form-data',
                contentType: false,
                processData: false,
                type: 'POST',
                success: (result) => {
                    if (result.success) {
                        resolve();
                        SessionManager.shared().dispatch(loadAdminReportTemplatesSuccess(result.data));
                    } else {
                        reject(result.error);
                        SessionManager.shared().dispatch(loadAdminReportTemplatesFailed(result.error));
                    }
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    SessionManager.shared().dispatch(
                        loadAdminReportTemplatesFailed('Error contacting server'));
                    reject(errorThrown);
                }
            }, false);
        });
    },

    updateClientTemplates: (clientId, datasetId, templateIds) => {
        return new Promise((resolve, reject) => {

            let data = {
                templateIds: templateIds,
            }

            ebase.ajax({
                url: '/ebase/core/write-system-update-client/' + clientId + '/dataset/' + datasetId + '/config',
                data: data,
                type: 'POST',
                success: (result) => {
                    if (result.success) {
                        resolve();
                    } else {
                        reject(result.error);
                    }
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    reject(errorThrown);
                }
            });
        });
    },

    downloadTemplate: (template_id) => {
        return '/ebase/core/read-system-report-template/' + template_id + '/data';
    },
}


export {TemplateManager};
