import React from 'react';
import ContainerHeader from '../components/common/ContainerHeader'
import PageWrapper from '../components/common/PageWrapper'
import EbasePanel from '../components/common/EbasePanel'
import {Table} from 'react-bootstrap'
import Spinner from "../components/common/Spinner"
import EButton from "../components/common/EButton"
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import sitedata from "../utils/sitedata";
import {Trans, withTranslation} from "react-i18next";
import EBSwitch from "../components/common/EBSwitch";


class ProjectsView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filters: {
                regionID: "",
                type: "",
                searchStr: "",
                showArchived: false,
            },
        }
    }

    componentWillMount()
    {
        this.props.load();
    }


    projectClicked(project_id, type, e)
    {
        e.stopPropagation();
        this.props.projectClicked(project_id, type)
    }

    render() {

        const {t} = this.props;

        let header = null;
        let body = null;
        let addButton = null;
        let readOnly = !sitedata.checkWriteAccess(this.props, "project");
        let regions = new Map();
        let types = new Map();

        if (this.props.isLoading || this.props.doLoad) {
            body = <Spinner/>
        } else {
            let projects = this.props.list;

            //Populate dropdown menus
            projects.map((project) => {
                if (!!project.region) {
                    regions.set(project.region.sharedId, project.region.name);
                }
                if (!!project.project_categories) {
                    project.project_categories.map((category) => {
                        types.set(category.sharedId, category.name);
                    })
                }
            });

            //Sort project list
            projects.sort((a, b) => {
                return a.name.localeCompare(b.name)
            });
            projects.sort((a, b) => {
                if (!a.region) {
                    return 1;
                }
                if (!b.region) {
                    return -1;
                }
                if (a.region && b.region) {
                    return a.region.name.localeCompare(b.region.name);
                }
            });

            body = <tbody></tbody>;

            if (projects == null || projects.length === 0) {
                body = <tbody>
                <tr>
                    <td>No Projects</td>
                </tr>
                </tbody>
            } else {
                header = <thead>
                <tr>
                    <th className="text-start px-3"></th>
                    <th className="text-start">{t("Name")}</th>
                    <th className="text-start">{t("Client Name")}</th>
                    <th className="text-start">{t("Description")}</th>
                    <th className="text-end px-4"></th>
                </tr>
                </thead>

                const rows = projects.map((project) => {
                    if (!this.state.filters.showArchived && project.archived) {
                        return null;
                    }
                    if (!!this.state.filters.searchStr &&
                        !(project.name ?? "").toLowerCase().includes(this.state.filters.searchStr.toLowerCase()) &&
                        !(project.details.proponent_name ?? "").toLowerCase().includes(this.state.filters.searchStr.toLowerCase())
                    ) {
                        return null;
                    }
                    if (!!this.state.filters.regionID && !(this.state.filters.regionID === (project.region?.sharedId ?? ""))) {
                        return null;
                    }
                    if (!!this.state.filters.type && !(project.project_categories.length > 0 && project.project_categories.some(type => type.sharedId === this.state.filters.type))) {
                        return null;
                    }

                    let reportExists = project.details != null && project.details.reportExists === true;
                    let onEdit = this.projectClicked.bind(this, project.object_id, reportExists ? "report" : "edit");

                    return (
                        <tr key={project.object_id}
                            onClick={ onEdit} style={{cursor: 'pointer'}}>
                            <td className="project-status">
                                {project.archived ?
                                    <span className="label label-warning">{t("Archived")}</span> :
                                    <span className="label label-primary">{t("Active")}</span>
                                }
                            </td>
                            <td className="project-title" style={{whiteSpace: "nowrap"}}><a
                                onClick={onEdit}>{project.name}</a>
                                <br/><EnvidObjectIcon type="Region"
                                                      showName={false}/> {(project.region == null) ? t("Unknown") : project.region.name}
                            </td>
                            <td className="">{project.details?.proponent_name ?? ""}</td>
                            <td className="project-text">{project.desc}</td>
                            <td className="project-actions" style={{whiteSpace: "nowrap"}}>
                                <EButton type={readOnly ? "standard" : "edit"} isTable={true}
                                         onClick={onEdit}>{readOnly ? t("View") : t("Edit")}</EButton>&nbsp;
                            </td>
                        </tr>)
                });

                body = <tbody key="body">{rows}</tbody>
            }

            if (!readOnly) {
                addButton = <div><EButton type="add" onClick={this.props.newProject}>{t("New Project")}</EButton></div>;
            }


            body = <Table className="table table-hover">{header}{body}</Table>
        }

        let archiveBox = <div className='row m-b-sm d-flex justify-content-end'>
            <div className="d-flex gap-2 w-auto">
                <div>Show Archived</div>
                <EBSwitch checked={this.state.showArchived}
                          onChange={(checked) => this.setState({filters: {...this.state.filters, showArchived: checked}})}/>
            </div>
        </div>

        //Populate options
        let regionOptions = [];
        regions.forEach((val, key) => {
            regionOptions.push(<option key={key} value={key}>{val}</option>)
        })
        let typeOptions = [];
        types.forEach((val, key) => {
            typeOptions.push(<option key={key} value={key}>{val}</option>)
        })

        let filterBox = <div className='row m-b-sm'>
            <div className="col-sm-3 m-b-sm">
                <select id="publishSelect"
                        className="form-select h-100"
                        value={this.state.filters.regionID}
                        onChange={(ev) => {
                            this.setState({filters: {...this.state.filters, regionID: ev.target.value}});
                        }}>
                    <option key="all" value="">{t('All Regions')}</option>
                    {regionOptions}
                </select>
            </div>
            <div className="col-sm-3 m-b-sm">
                <select className="form-select h-100"
                        value={this.state.filters.type}
                        onChange={(ev) => {
                            this.setState({ filters:
                                    {...this.state.filters, type: ev.target.value}
                            });
                        }}>
                    <option key="all" value="">{t('All Types')}</option>
                    {typeOptions}
                </select>
            </div>
            <div className="col-sm-5 m-b-sm">
                <input className="form-control h-100"
                       type="text" placeholder={t("Filter by name or client name")}
                       value={this.state.filters.searchStr}
                       onChange={(ev) => {
                           this.setState({filters: {...this.state.filters, searchStr: ev.target.value}})
                       }}
                />
            </div>
            <div className="col-sm-1 m-b-xs">
                <div className="input-group">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            this.setState({filters: {...this.state.filters, searchStr: "", regionID: "", type: ""}})
                        }}>
                        {t('Clear all')}
                    </button>
                </div>
            </div>

        </div>


        return (
            <div>
                <ContainerHeader title={t("Project Hub")}
                                 crumbs={['/','/projects']}
                                 dimensions={this.props.dimensions}
                                 rightComponent={addButton}/>
                <PageWrapper dimensions={this.props.dimensions}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="ibox float-e-margins">
                                <div className="ibox-content text-center p-md">
                                    <Trans i18nKey="project-hub-welcome">
                                        <h2><span className="text-navy">Welcome to your</span> eBase <span className="text-navy">Project Hub</span>.</h2>
                                        <p>Begin by creating your Project OR edit existing Projects below.</p>
                                    </Trans>
                                </div>
                            </div>
                        </div>
                    </div>

                    <EbasePanel>
                        {archiveBox}
                        {filterBox}
                        {body}

                    </EbasePanel>
                </PageWrapper>
            </div>
        )
    }

}

export default withTranslation()(ProjectsView)
