/**
 * Created by lewellyn on 28/9/18.
 */
import React, {useEffect, useRef, useState} from 'react';
import {
  FormControl,
  FormGroup,
  Form,
} from 'react-bootstrap';
import ContainerHeader from '../components/common/ContainerHeader';
import PageWrapper from '../components/common/PageWrapper';
import EbasePanel from '../components/common/EbasePanel';
import EButton from '../components/common/EButton';
import Spinner from '../components/common/Spinner';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import SessionManager from '../utils/SessionManager';
import EBDropDown from '../components/common/EBDropDown';
import {TemplateManager} from '../utils/TemplateManager';

const ReportTaskTypes = [
  'AspectActivity',
  'Receptor',
  'Impact',
  'Control',
  'Risk',
  'EnvironmentalInputs',
  'TechnicalInputs',

  // Control Summary Tasks
  'Activity',

  // Compliance Tasks
  'Compliance',

  // Stakeholder tasks
  'Stakeholder',
];

const TemplateEditView = (props) => {

  const {t} = useTranslation();

  const templates = useSelector((state) => state.templates.templates);
  const dimensions = useSelector((state) => state.session.dimensions);

  const [template, setTemplate] = useState(
    {name: '', description: '', reportType: '', type: '', requiredTasks: [], isDefault: false});
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [templateFile, setTemplateFile] = useState();

  const uploadFileRef = useRef(null);

  const DROPDOWNS = {
    report_type: {
      fieldName: 'reportType',
      title: t('Select Report Type'),
      options: [
        {title: t('Impact Assessment'), key: 'ImpactAssessment'},
        {title: t('Controls Summary'), key: 'ControlsSummary'},
        {title: t('Compliance'), key: 'Compliance'},
        {title: t('Stakeholder'), key: 'Stakeholder'},
        {title: t('Region Stakeholder'), key: 'RegionStakeholder'},
      ],
    },
    template_type: {
      fieldName: 'type',
      title: t('Select Template Type'),
      options: [
        {title: t('Word'), key: 'WORD_DOCX'},
        {title: t('Excel'), key: 'EXCEL_XLSX'},
        {title: t('CSV'), key: 'CSV'},
        {title: t('ZIP'), key: 'ZIP'},
      ],
    },
  };

  const TemplateDropdown = (props) => {
    let active = !!template && props.dataset.options.find((option) => {
      return option.key === template[props.dataset.fieldName];
    });
    return (
      <EBDropDown id={props.id}
                  title={!!active ? active.title : props.dataset.title}
                  options={props.dataset.options}
                  activeKey={active?.key}
                  onSelect={(val) => {
                    updateTemplateFieldValue(props.dataset.fieldName, val);
                  }}>
      </EBDropDown>
    );
  };

  useEffect(() => {
    if (!templates) {
      if (!loading) {
        setLoading(true);
        TemplateManager.loadReportTemplates();
      }
    } else {
      setLoading(false);
    }
  }, [templates, loading]);

  useEffect(() => {
    let templateId = props.match?.params?.template_id;
    let newTemplate;

    if (templateId === 'new') {
      templateId = undefined;
    }

    if (templateId && !!templates &&
      (!template || template.templateId !== templateId)) {
      newTemplate = templates.find(t => t.templateId === templateId);
      if (!newTemplate.requiredTasks) {
        newTemplate.requiredTasks = [];
      }
    }


    if (!!newTemplate) {
      setTemplate(newTemplate);
    }
  }, [templates]);

  useEffect(() => {
    setCanUpdate(
      isTemplateFieldValid('name') &&
      isTemplateFieldValid('reportType') &&
      isTemplateFieldValid('type') &&
      isTemplateFieldValid('description') &&
      (!!template?.templateId || !!templateFile));
  }, [template, templateFile]);

  const reportFileChanged = (event) => {
    if (event?.target?.files?.length) {
      let file = event.target.files[0];
      setTemplateFile(file);
    }
  };

  const downloadTemplate = () => {
    let url =  TemplateManager.downloadTemplate(template.templateId);
    let link = document.createElement("a");
    link.href = url;
    link.click();
  }

  const isRequiredTask = (task) => {
    return !!template.requiredTasks.find(t => t === task);
  }

  const setRequiredTask = (task, on) => {
    const newTemplate = {...template};
    newTemplate.requiredTasks = newTemplate.requiredTasks.filter(t => t !== task);
    if (on) {
      newTemplate.requiredTasks.push(task);
    }
    setTemplate(newTemplate);
  }

  const updateTemplateField = (fieldName, event) => {
    updateTemplateFieldValue(fieldName, event.target.value);
  };

  const updateTemplateCheckField = (fieldName, checked) => {
    updateTemplateFieldValue(fieldName, checked);
  };

  const updateTemplateFieldValue = (fieldName, val) => {
    const newTemplate = {...template};
    newTemplate[fieldName] = val;
    setTemplate(newTemplate);
  };

  const isTemplateFieldValid = (fieldName) => {
    if (fieldName === 'templateFile') {
      return !!template?.templateId || !!templateFile  ? 'success' : null;
    }
    return !!template[fieldName] ? 'success' : null;
  };

  const saveTemplate = () => {
    setSaving(true);
    TemplateManager.updateTemplate(template, templateFile).then(()=>{
      setUpdated(true);
    }).catch((error)=>{
      SessionManager.shared().errorHandler(t('Error Saving Template') +" - " + error, error);
    }).finally(() => {
      setSaving(false);
    });
  };

  const fieldWidth = '65%';

  let content;
  let isNew = !template?.templateId;

  if (saving || loading) {
    content = <Spinner/>;
  } else if (updated) {

    content = (
      <div>
        <div>
          <h3>{t(isNew
            ? 'Template Created Successfully'
            : 'Template Updated Successfully')}</h3>
        </div>
        <div style={{
          width: 300,
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
          <EButton onClick={() => {SessionManager.shared().goto('/templates');}}
                   type="done">
            {t('Done')}
          </EButton>
        </div>
      </div>
    );

  } else {

    let clientInput = (
        <Form.Group style={{ width: fieldWidth }}>
          <h3>{t('Template Details')}</h3>

          {/* Name Field */}
          <Form.Group className={`mb-3 ${isTemplateFieldValid('name') ? 'is-valid' : 'is-invalid'}`}>
            <Form.Label>{t('Name')}</Form.Label>
            <Form.Control type="text"
                          style={{ width: '95%', fontSize: '13px' }}
                          value={template.name}
                          onChange={(e) => updateTemplateField('name', e)}
                          isInvalid={!isTemplateFieldValid('name')} />
          </Form.Group>

          {/* Description Field */}
          <Form.Group className={`mb-3 ${isTemplateFieldValid('description') ? 'is-valid' : 'is-invalid'}`}>
            <Form.Label>{t('Description')}</Form.Label>
            <Form.Control type="text"
                          style={{ width: '95%', fontSize: '13px' }}
                          value={template.description}
                          onChange={(e) => updateTemplateField('description', e)}
                          isInvalid={!isTemplateFieldValid('description')} />
          </Form.Group>

          {/* Report Type Dropdown */}
          <Form.Group className={`mb-3 ${isTemplateFieldValid('reportType') ? 'is-valid' : 'is-invalid'}`}>
            <Form.Label>{t('Report Type')}</Form.Label>
            <TemplateDropdown id="report_type" dataset={DROPDOWNS.report_type} />
          </Form.Group>

          {/* Template Type Dropdown */}
          <Form.Group className={`mb-3 ${isTemplateFieldValid('type') ? 'is-valid' : 'is-invalid'}`}>
            <Form.Label>{t('Template Type')}</Form.Label>
            <TemplateDropdown id="template_type" dataset={DROPDOWNS.template_type} />
          </Form.Group>

          {/* Prerequisites Section */}
          {
              !template.builtIn &&
              <Form.Group className="mb-3">
                <Form.Label>{t('Prerequisites')}</Form.Label>
                <div className="ebase-4-col-grid">
                  {
                    ReportTaskTypes.map(tt => (
                        <Form.Check
                            type="checkbox"
                            id={`prerequisite_${tt}`}
                            className="icheckbox_minimal-grey"
                            checked={isRequiredTask(tt)}
                            label={<span className="control-label">&nbsp;{t(tt)}</span>}
                            onChange={(ev) => setRequiredTask(tt, ev.target.checked)}
                        />
                    ))
                  }
                </div>
              </Form.Group>
          }

          {/* Options Section */}
          <Form.Group className="mb-3">
            <Form.Label>{t('Options')}</Form.Label>
            <Form.Check
                type="checkbox"
                id="default_report"
                className="icheckbox_minimal-grey"
                checked={template?.isDefault}
                label={<span className="control-label">&nbsp;{t('Project Default')}</span>}
                onChange={(ev) => updateTemplateCheckField('isDefault', ev.target.checked)}
            />
          </Form.Group>

          {/* Template File Upload */}
          <Form.Group className={`mb-3 ${isTemplateFieldValid('templateFile') ? 'is-valid' : 'is-invalid'}`}>
            <Form.Label>{t('Template File')}</Form.Label>
            <div>
              <Form.Control type="text"
                            readOnly
                            value={templateFile ? templateFile.name : ''}
                            style={{ width: '95%', fontSize: '13px', marginBottom: 10 }} />
              <EButton onClick={() => uploadFileRef?.current?.click()} type="add">{t('Select')}</EButton>
              <input type="file" ref={uploadFileRef}
                     style={{ display: 'none' }}
                     onChange={(e) => reportFileChanged(e)} />
            </div>
          </Form.Group>
        </Form.Group>
    )

    let buttons = (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 10,
        borderTop: '1px solid #eaeaea',
      }}>
        <EButton onClick={() => SessionManager.shared().goto('/templates')}
                 type="cancel">{t('Cancel')}</EButton>
        <div style={{marginLeft: 25}}>
          <EButton onClick={saveTemplate} type={isNew ? 'add' : 'done'}
                   disabled={!canUpdate}>{t(
            isNew ? 'Create Template' : 'Update Template')}</EButton>
        </div>
        <div style={{marginLeft: 25}}>
          <EButton onClick={()=>downloadTemplate()} disabled={isNew} type="download">
            {t('Download Template')}
          </EButton>
        </div>
      </div>
    );

    content = (
      <div>
        {clientInput}
        {buttons}
      </div>
    );

    // let dropDown = (
    //     <DropdownButton title={selected}>
    //         {options}
    //     </DropdownButton>
    // );

  }

  return (

    <div>
      <ContainerHeader title={t('Edit Template')}
                       crumbs={['/', '/templates', isNew ? t('New Template') : t('Edit Template')]}
                       dimensions={dimensions}
                       subtitle={t(
                         saving ? 'Saving' : (loading ? 'Loading' : ''))}/>
      <PageWrapper dimensions={dimensions}>
        <EbasePanel>
          {content}
        </EbasePanel>
      </PageWrapper>
    </div>

  );

};

export {TemplateEditView};
