/**
 * Created by lewellyn on 16/10/18.
 */
import React from 'react';
import EbasePanel from "../components/common/EbasePanel";
import Spinner from "../components/common/Spinner"
import ebase from "../utils/ajax"
import sitedata from '../utils/sitedata'
import EButton from "../components/common/EButton"
import {Modal, ModalBody, ModalHeader, ModalTitle, ModalFooter,FormControl} from 'react-bootstrap'
import {Trans, withTranslation} from "react-i18next";

class ProjectManageView extends React.Component {


    constructor(props){
        super(props);

        this.loadConnectedObjects = this.loadConnectedObjects.bind(this);
        this.onRealDelete = this.onRealDelete.bind(this);


        this.state={connectedObjects:null,loading:true,asking:false,confirmValue:""};

        this.archiveClick = this.archiveClick.bind(this);

    }


    loadConnectedObjects(){

        let params = sitedata.getParams(this.props);
        ebase.ajax({
            url:"/ebase/envid-admin/read-enviddata-deletecheck",
            data:{objectId:params.objectId},
            success: (result) => {
                // console.log("ProjectManageView:: delete check done");
                // console.log(result)
                this.setState({connectedObjects:result.data.linked,loading:false});
            },
            error: (result) => {
                // console.log("ProjectManageView:: delete check error");
                this.setState({loading:false});
            }
        });



    }

    onClose(){
        this.setState({asking:false,confirmValue:""});
    }

    onRealDelete(){
        // console.log("delete project");
        // console.log(this.props);

        this.setState({loading:true});

        this.props.deleteProject(this.props.currentObject.object.objectId);
    }

    onFieldChange(event){

        this.setState({confirmValue:event.target.value});
    }

    archiveClick(){
        const nextArchive = !this.props.currentObject.object.details.archived;
        this.props.updateProjectArchived(this.props.currentObject.object.sharedId, nextArchive);
    }

    render() {
        const {t} = this.props;

        // console.log("ProjectManageView:: render");
        // console.log(this.props.project_status);

        if(this.state.connectedObjects == null){
            this.loadConnectedObjects();
        }

        if(this.state.loading){
            return <Spinner/>;
        }

        let haveRisk = false;
        let haveStakeholdersComm = false;
        let haveCompliance = false;

        haveRisk =  this.props.project_status.ImpactRiskAssessment !== "UNAVAILABLE";

        for(let i=0;i<this.state.connectedObjects.length;i++){

            let obj = this.state.connectedObjects[i];
            let type = obj.type;
            if(type === "ComplianceDefn" || type === "ComplianceAction"){
                haveCompliance = true;
            }
            if(type === "StakeholderEvent" || type === "StakeholderObjection" || type === "StakeholderAction"){
                haveStakeholdersComm = true;
            }
        }

        // console.log("haveRisk: "+haveRisk);
        // console.log("haveStakeholdersComm: "+haveStakeholdersComm);
        // console.log("haveCompliance: "+haveCompliance);

        let info = "";

        if(!haveRisk && !haveStakeholdersComm && !haveCompliance){
            info = <p>{t("Are you sure you want to delete this project? Deleting will deleting it for all users.")}</p>
        }else{

            let items = [];
            if(haveCompliance){
                items.push(<li>{t("Compliance Records")}</li>)
            }
            if(haveStakeholdersComm){
                items.push(<li>{t("Stakeholder Communications")}</li>)
            }
            if(haveRisk){
                items.push(<li>{t("Impact Assessment Data")}</li>)
            }

            info = (
                <div>
                    <p>{t("This Project has associated")}:</p>
                    <ul>
                        {items}
                    </ul>
                </div>
            )

        }


        // console.log(this.state.connectedObjects);
        //console.log(this.props);

        let project = this.props.currentObject.object;
        console.log(project);

        const project_name = project.details.name;

        let modal = (


            <Modal show={this.state.asking} onHide={this.onClose.bind(this)}>
                <ModalHeader closeButon>
                    <ModalTitle>
                        {t("Delete Project")} - {project.details.name}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>

                    <Trans i18nKey="project-are-you-sure">
                        <p>Are you sure you want to delete this project: <span style={{fontWeight:'bold'}}>{{project_name}}</span>. This is permanent and cannot be undone</p>
                        <p>Enter delete password below to enable delete button.</p>
                    </Trans>
                    <FormControl
                        type={"password"}
                        onChange={this.onFieldChange.bind(this)}
                        value={this.state.confirmValue}
                    />

                </ModalBody>
                <ModalFooter>
                    <EButton isTable={true} type="cancel" onClick={this.onClose.bind(this)}>{t("Cancel")}</EButton>
                    <EButton isTable={true} type="delete" onClick={this.onRealDelete} disabled={this.state.confirmValue !== "projectDelete456"}>{t("Delete")}</EButton>
                </ModalFooter>

            </Modal>

        );



        return (
            <EbasePanel>
                {modal}
                <div className="row" style={{height:500}}>
                    <div className="col-lg-6">
                        <div className={"card card-default"}>
                            <div className="card-header"><h3>{t("Delete Project")} - {project.details.name}</h3></div>
                            <div className="card-body p-lg text-left">
                                {info}
                                <Trans i18nKey="delete-project-warning">
                                    <p>Deleting it will delete this for all users.</p>
                                    <p>Are you sure you want to delete this project?</p>
                                </Trans>

                                <div className="text-right">
                                    <EButton type="delete" onClick={() => this.setState({asking:true})}>{t("Delete Project")}</EButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className={"card card-default"}>
                            <div className="card-header"><h3>{t("Archive Project")} - {project.details.name}</h3></div>
                            <div className="card-body p-lg text-left">
                                {info}

                                <div className="text-right">
                                    {project.details.archived ?
                                        <EButton type="restore" onClick={() => this.archiveClick()}>{t("Restore Project")}</EButton> :
                                        <EButton type="archive" onClick={() => this.archiveClick()}>{t("Archive Project")}</EButton>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </EbasePanel>
        );
    }

}


export default withTranslation()(ProjectManageView)
