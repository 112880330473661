import React from "react";
import {AuditEventPublic} from "../../fetchapi";
import EbaseDS from "../../utils/EbaseDS";
import SessionManager from "../../utils/SessionManager";
import EbasePanel from "../common/EbasePanel";
import LibraryAuditView from "../../views/LibraryAuditView";
import Spinner from "../common/Spinner";

interface LibraryAuditContainerProps {
    height: number | null;
}

interface LibraryAuditContainerState {
    objectHistory: AuditEventPublic[];
    isLoading: boolean;
    tableLoading: boolean;
    allLogsLoaded: boolean;
    currentPage: number;
}

class LibraryAuditContainer extends React.Component<LibraryAuditContainerProps, LibraryAuditContainerState> {
    scrollableDivRef: React.RefObject<HTMLDivElement>; // Define the type of the ref

    constructor(props: LibraryAuditContainerProps)
    {
        super(props);
        this.state = {
            objectHistory: [],
            isLoading: false,
            tableLoading: false,
            allLogsLoaded: false,
            currentPage: 0,
        };

        this.scrollableDivRef = React.createRef();
    }


    handleScroll = (e: any) => {
        // console.log(e)
        if (this.scrollableDivRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = this.scrollableDivRef.current;

            if (scrollTop + clientHeight >= scrollHeight && !this.state.tableLoading && !this.state.allLogsLoaded) {
                this.setState({ tableLoading: true });
                this.loadNext(this.state.currentPage + 1);
            }
        }
    };

    componentDidMount() {
        this.scrollableDivRef.current?.addEventListener("scroll", this.handleScroll);

        // console.log('ChangeHistoryContainer:: didmount', this.props);
        const ebaseDs: EbaseDS = SessionManager.shared().getEbaseDS();
        this.setState({isLoading: true});

        ebaseDs.searchAuditEvents(null, null, 50, 0).then((result: AuditEventPublic[]) => {
            this.setState({objectHistory: result});
            this.setState({isLoading: false});
        }).catch(reason => {
            console.log(reason);
        })
    }

    componentWillUnmount() {
        this.scrollableDivRef.current?.removeEventListener("scroll", this.handleScroll);
    }

    loadNext(n: number) {
        const ebaseDs: EbaseDS = SessionManager.shared().getEbaseDS();
        this.setState({
            currentPage: n,
        });

        ebaseDs.searchAuditEvents(null, null, 50, n).then((result: AuditEventPublic[]) => {
            if (result.length > 0) {
                this.setState({
                    objectHistory: this.state.objectHistory.concat(result),
                    tableLoading: false,
                });
            } else {
                this.setState({
                    allLogsLoaded: true,
                    tableLoading: false,
                })
            }
        }).catch(reason => {
            console.log(reason);
        })
    }

    render() {
        return (
            <EbasePanel>
                <h3>{"Library Audit Log"}</h3>
                <div className="overflow-y-scroll" style={(this.props.height ? {height: this.props.height} : {})} id="auditScrollablePanel" ref={this.scrollableDivRef}>
                    <LibraryAuditView
                        logs={this.state.objectHistory}
                        loadNext={this.loadNext}
                        height={this.props.height}
                    ></LibraryAuditView>
                    { this.state.tableLoading ? <Spinner /> : null}
                </div>
            </EbasePanel>
        );
    }
}

export default LibraryAuditContainer;