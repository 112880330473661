import React from "react";
import EbasePanel from "../components/common/EbasePanel";
import {withTranslation} from "react-i18next";
import {Button, Form,} from "react-bootstrap";
import {NotifyTemplateResult} from "../fetchapi";

interface EmailTemplateViewProps {
    templates: NotifyTemplateResult[];
    setTemplates: (code: string, template: NotifyTemplateResult) => void;
}

interface EmailTemplateViewState {
    [key: string]: any;
    selectedTemplateCode: string;
    subject: string;
    text: string;
    htmlText: string;
}

class EmailTemplateView extends React.Component<EmailTemplateViewProps, EmailTemplateViewState> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedTemplateCode: '',
            subject: '',
            text: '',
            htmlText: '',
        };
    }

    handleChange = (e: any) => {
        const { name, value } = e.target;
        this.setState({
            [name] : value
        })
    };

    changeSelectedTemplate = (e: any) => {
        const { name, value } = e.target;
        let template: NotifyTemplateResult | undefined = this.props.templates.find(val => (val.templateCode === value));
        if ( !!template ) {
            this.setState({
                selectedTemplateCode: value,
                subject: template.subject ?? '',
                text: template.plainContent ?? '',
                htmlText: template.htmlContent ?? '',
            });
        } else {
            console.log("Template not found.");
            this.setState({
                selectedTemplateCode: '',
                subject: '',
                text: '',
                htmlText: '',
            });
        }
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (!!this.state.selectedTemplateCode) {
            let temp: NotifyTemplateResult = {
                templateCode: this.state.selectedTemplateCode,
                subject: this.state.subject,
                plainContent: this.state.text,
                htmlContent: this.state.htmlText,
                templateType: "Email",
            }
            this.props.setTemplates(this.state.selectedTemplateCode, temp)
        } else {
            console.log("Error in submission", this.state);
        }
    };

    render() {

        return <EbasePanel>
            <h3>Edit Email templates</h3>
            <Form onSubmit={this.handleSubmit}>
                {/* Templates Dropdown */}
                <Form.Group controlId="formTemplates">
                    <Form.Label>Templates</Form.Label>
                    <Form.Control
                        as="select"
                        name="selectedTemplate"
                        value={this.state.selectedTemplateCode}
                        onChange={this.changeSelectedTemplate}
                    >
                        <option value="">Select a template</option>
                        {this.props.templates.map((template: NotifyTemplateResult, index: number) => (
                            <option key={index} value={template.templateCode}>
                                {template.templateCode}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>

                {/* Subject Input */}
                <Form.Group controlId="formSubject" className="mt-3">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                        type="text"
                        name="subject"
                        value={this.state.subject}
                        onChange={this.handleChange}
                        placeholder="Enter subject"
                    />
                </Form.Group>

                {/* Text Multiline Input */}
                <Form.Group controlId="formText" className="mt-3">
                    <Form.Label>Text</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={10}
                        name="text"
                        value={this.state.text}
                        onChange={this.handleChange}
                        placeholder="Enter text"
                    />
                </Form.Group>

                {/* HTML Text Multiline Input */}
                <Form.Group controlId="formHtmlText" className="mt-3">
                    <Form.Label>HTML Text</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={10}
                        name="htmlText"
                        value={this.state.htmlText}
                        onChange={this.handleChange}
                        placeholder="Enter HTML text"
                    />
                </Form.Group>
                <Button type="submit" className="mt-3">Update template</Button>
            </Form>


        </EbasePanel>
    }
}

export default withTranslation()(EmailTemplateView)
