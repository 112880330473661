/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormattedText
 */
export interface FormattedText {
    /**
     * 
     * @type {string}
     * @memberof FormattedText
     */
    text?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormattedText
     */
    images?: Array<string>;
}

/**
 * Check if a given object implements the FormattedText interface.
 */
export function instanceOfFormattedText(value: object): value is FormattedText {
    return true;
}

export function FormattedTextFromJSON(json: any): FormattedText {
    return FormattedTextFromJSONTyped(json, false);
}

export function FormattedTextFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormattedText {
    if (json == null) {
        return json;
    }
    return {
        
        'text': json['text'] == null ? undefined : json['text'],
        'images': json['images'] == null ? undefined : json['images'],
    };
}

export function FormattedTextToJSON(value?: FormattedText | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'images': value['images'],
    };
}

