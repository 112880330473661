import React from 'react'
import {connect} from "react-redux";
import ebase from '../../utils/ajax'
import ReactTimeout from 'react-timeout'
import sitedata from '../../utils/sitedata'
import DataHomeView from "../../views/DataHomeView";
import DataObjectView from "../../views/DataObjectView";
import SessionManager from "../../utils/SessionManager";
import {withTranslation} from 'react-i18next';
import SessionHelper from "../../utils/SessionHelper";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>{

    return {
        dimensions:state.session.dimensions,
        siteData:state.session.siteData,
        showLoadError:SessionManager.shared().showLoadError,
        search:state.search.search,
        results:state.search.results,
        currentObject:state.search.currentObject,
        currentCheckedOutObject: state.manage.currentCheckedOutObject,
        changeTime: state.search.changeTime,
        savedStatus: state.search.savedStatus,
    }

};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        searchEnvidObjects: (criteria) => searchEnvidObjects(criteria,dispatch,ownProps),
        updateTerm: (term) => updateSearchTerm(term,dispatch),
        updateSearchType: (type, doSearch, criteria) => updateSearchType(type,doSearch,criteria,dispatch,ownProps),
        addPage: (props) => addPage(props, dispatch, ownProps),
        gotoObject: (obj_id) => gotoObject(obj_id,dispatch,ownProps),
        loadObject: (obj_id) => loadObject(obj_id,dispatch,ownProps),
        updateSavedStatus: (obj_id,status) => updateSavedStatus(obj_id,status,dispatch),
        updateObject: (object, moreState) => updateObject(object,moreState,dispatch),
        onAddEnvidObject: (type,link) => onAddEnvidObject(type,link, dispatch,ownProps),
        updatePublishState: (state, criteria) => updatePublishState(state,criteria,dispatch,ownProps),
        updateCheckedState: (state, criteria) => updateCheckedState(state,criteria,dispatch,ownProps),
        resetSearch: (doSearch) => resetSearch(doSearch, dispatch,ownProps),
        checkoutObject: (sharedId, location) => checkoutObject(sharedId, dispatch,ownProps, location),
        checkinObject: (sharedId, location) => checkinObject(sharedId, dispatch,ownProps, location),
    }
};

const searchEnvidObjects = (criteria, dispatch,ownProps) => {
    // console.log('searchEnvidObjects - ',criteria);
    if (criteria == null){
        return;
    }

    const term = criteria.term;
    const objecttype = !criteria.type ? null : criteria.type;
    const max = criteria.max;
    const page = criteria.page;
    const pubStates = criteria.publish;
    const scenario = criteria.checked;

      // 'ALL',
      // 'ONLY_CHECKED_IN',
      // 'ONLY_CHECKED_OUT',
      // 'ONLY_USER_CHECKED_OUT'

    dispatch({type:"SEARCH_START", data:criteria})

    ebase.ajax({
            url: "/ebase/envid-admin/read-enviddata-search",
            data:{filterString:term,maxResults:max,type:objecttype, page,publishStates:pubStates, checkoutScenario: scenario},
            success: (result) => {
                // console.log('search done',result);
                if (result.success){
                    dispatch({type:"SEARCH_UPDATE_RESULTS",data:result.data,t:ownProps.t})
                }else{
                    SessionManager.shared().showLoadError("Error searching objects", result)
                }
            },
            error: (result) => {
                SessionManager.shared().showLoadError("Error searching objects", result);
            }
        }
    );
}

const updateSearchTerm = (term, dispatch) => {
    dispatch({type:"SEARCH_UPDATE_CRITERIA_FIELD",data:{field:"term",value:term}})
}

const updateSearchType = (freshType, doSearch,criteria, dispatch,ownProps) => {
    // console.log("updateSearchType",ownProps);
    let search = Object.assign({},criteria);
    search.type = freshType;
    search.page = 0;
    search.max = 50;
    dispatch({type:"SEARCH_SET_CRITERIA",data:search})

    if (doSearch === true)
    {
        searchEnvidObjects(search,dispatch,ownProps);
    }
}

const updatePublishState = (nextSate, search, dispatch, ownProps) => {

    if (nextSate == null){
        search.publish = [];
    }else{
        search.publish=[nextSate];
    }
    search.page = 0;
    search.max = 50;
    searchEnvidObjects(search,dispatch,ownProps);
};

const updateCheckedState = (nextState, search, dispatch, ownProps) => {
    search.checked=nextState;
    search.page = 0;
    search.max = 50;
    searchEnvidObjects(search,dispatch,ownProps);
};

const addPage = (props, dispatch,ownProps) => {
    if (!props.results?.searching && props.results?.more) {
        let search = Object.assign({},props.search);
        let page = search.page;
        page = page == null ? 0 : page + 1;
        search.page = page;

        searchEnvidObjects(search,dispatch,ownProps);

    }
};

const gotoObject = (objectId, dispatch, ownProps) => {
    // console.log('DataHomeContainer:: go to object: ', objectId);
    ebase.ajax({
        url:"/ebase/envid-admin/read-enviddata-object",
        data:{objectId: objectId},
        success:(result) =>{
            if(result.success){
                // this.setState({currentObject: result.data, savedStatus:"unchanged"});
                dispatch({type:"SEARCH_SET_CURRENT_OBJECT",data:result.data})
                if (SessionHelper.shared().isObjectCheckedOutToSessionUser(result.data.object)){
                    dispatch({type:"SET_CURRENT_CHECKOUT_OBJECT",data:result.data})
                }else{
                    dispatch({type:"SET_CURRENT_CHECKOUT_OBJECT",data: null})
                }
                SessionManager.shared().goto("/enviddata/object/"+result.data.object.sharedId+"/det");
            }else{
                SessionManager.shared().showLoadError(result.error, result);

            }
        },
        error:(result) => {
            SessionManager.shared().showLoadError("Error Loading Object", result);
        }
    });
};

const loadObject = (objectId,dispatch, ownProps) => {
    // console.log('DataHomeContainer:: loadObject object: ', objectId);
    ebase.ajax({
        url:"/ebase/envid-admin/read-enviddata-object",
        data:{objectId:objectId},
        success:(result) =>{
            if(result.success){
                dispatch({type:"SEARCH_SET_CURRENT_OBJECT",data:result.data})
                if (SessionHelper.shared().isObjectCheckedOutToSessionUser(result.data.object)){
                    dispatch({type:"SET_CURRENT_CHECKOUT_OBJECT",data:result.data})
                }else{
                    dispatch({type:"SET_CURRENT_CHECKOUT_OBJECT",data: null})
                }
                //this.setState({currentObject: result.data, savedStatus:"unchanged", changeTime:null});
            }else{
                SessionManager.shared().showLoadError("Error Loading Object", result);
            }
        },
        error:(result) => {
            SessionManager.shared().showLoadError("Error Loading Object", result);
        }

    });
};

const updateSavedStatus =  (sharedId, status, dipatch) => {
    // for now, we'll just take whatever it says, probably won't have more then one
    // tba updating this at a time.
    dipatch({type:"UPDATE_SAVED_STATUS",data:status});
    // this.setState({savedStatus:status});
}

const updateObject = (object, moreState,dispatch) => {

    dispatch({type:"UPDATE_OBJECT", data:{object: object, moreState: moreState}});
}

const onAddEnvidObject = (type, link,dispatch,ownProps) =>{
        // need to add the object, then go to the load page

        //browserHistory.push('/#/enviddata/object/abc/det');
        //this.props.router.push('/enviddata/object/abc/det')
        //this.transitionTo("/enviddata/object/abc/det");
        //this.context.router.transitionTo('select', {objectId:"test"});

        var data = {type:type};
        if (link != null)
        {
            data.withLink = link;
        }

        //console.log("Adding Object " + JSON.stringify(data));
        ebase.ajax({
            url:"/ebase/envid-admin/write-enviddata-newobject",
            data:data,
            success:(result) => {
                if(result.success){
                    //console.log("Added Object " + JSON.stringify(result.data));
                    //console.log("Added Object " + result.data.object.object_id);
                    dispatch({type:"SEARCH_SET_CURRENT_OBJECT",data:result.data});
                    dispatch({type:'SET_CURRENT_CHECKOUT_OBJECT', data:result.data});
                    SessionManager.shared().goto("/enviddata/object/"+result.data.object.sharedId+"/det");
                    // this.setState({currentObject: result.data, linkInfo:null, savedStatus:"unchanged"});
                }else{
                    if(result.error !== null && result.error !== undefined){
                        SessionManager.shared().showLoadError("Error Creating Object. "+result.error, result);
                    }else {
                        SessionManager.shared().showLoadError("Error Creating Object", result);
                    }
                }
            },
            error:(result) => {
                SessionManager.shared().showLoadError("Error Creating Object", result);
            }

        });
    }

const resetSearch = (doSearch, dispatch,ownProps) =>{
    dispatch({type: "SEARCH_RESET"});
    if (doSearch) {
        searchEnvidObjects({term: "", type: "", page: 0, max: 50, publish: []},dispatch,ownProps);
    }
}

const checkoutObject = (sharedId, dispatch, ownProps, location) => {

    // console.log(' checkoutObject ', sharedId, ownProps);
    dispatch({type: 'CHECKOUT_OBJECT_START'});

    let ebaseDS = SessionManager.shared().getEbaseDS();
    ebaseDS.checkoutObject(sharedId, dispatch, ownProps).then(result => {
        // console.log('checkoutObject', result);

        dispatch({type:"SEARCH_SET_CURRENT_OBJECT",data:result})
        dispatch({type: 'CHECKOUT_OBJECT_DONE', data: result});
        dispatch({type: "SEARCH_UPDATE_CHECKOUT_CHANGED", data: result});

        if (location !== 'ObjectView') {
            gotoObject(result.object.sharedId, dispatch, ownProps);
        }

    }).catch(error => {
        console.log('check in error: ',error);
        SessionManager.shared().showToast("Check out Failed", error, 'danger');
    });
}

const checkinObject = (sharedId, dispatch, ownProps, location) => {

    dispatch({type: 'CHECKIN_OBJECT_START'});

    // ebaseDS checkin call.
    let ebaseDS = SessionManager.shared().getEbaseDS();
    ebaseDS.checkinObject(sharedId).then(result => {
        console.log('Checkin result', result);
        dispatch({type:"SEARCH_SET_CURRENT_OBJECT",data:result})
        dispatch({type: 'CHECKIN_OBJECT_DONE', data: result});
        dispatch({type: "SEARCH_UPDATE_CHECKOUT_CHANGED", data: result});
    }).catch(error => {
        console.log('checkout error: ',error);
        SessionManager.shared().showToast("Checkin Failed", error, 'danger');
    });
}

class DataHomeBase extends React.Component{

    constructor(props) {
        super(props);
        this.selectTab = this.selectTab.bind(this);
    }

    selectTab(tabKey,event){
        // this.context.router.push("/enviddata/object/"+this.state.currentObject.object.object_id+"/"+tabKey);
        SessionManager.shared().goto("/enviddata/object/"+this.props.currentObject.object.sharedId+"/"+tabKey);

    }

    componentWillUnmount() {
        this.props.resetSearch(false);
    }

    render() {
        // console.log("DataHomeBase:: ",this.props);

        let canRead = sitedata.checkReadAccess(this.props,"enviddata");
        let canWrite = sitedata.checkWriteAccess(this.props,"enviddata");

        let isReadOnly = canRead && !canWrite;

        // console.log("readOnly: "+isReadOnly);

        let callbacks = {
            add:this.props.onAddEnvidObject,
            search:() => this.props.searchEnvidObjects(this.props.search),
            addPage: () => this.props.addPage(this.props),
            updateTerm:this.props.updateTerm,
            updateSearchType: (type, doSearch) => this.props.updateSearchType(type, doSearch,this.props.search),
            clickedObject:this.props.gotoObject,
            loadObject:this.props.loadObject,
            clickedTab:this.selectTab,
            updateObject : this.props.updateObject,
            updateSavedStatus : this.props.updateSavedStatus,
            updatePublishState: (state) => this.props.updatePublishState(state, this.props.search),
            updateCheckedState: (state) => this.props.updateCheckedState(state, this.props.search),
            resetSearch: this.props.resetSearch,
            checkoutObject:this.props.checkoutObject,
            checkinObject:this.props.checkinObject,
        };

        // console.log("DataHomeContainer ",this.props);

        //var props = Object.assign({callbacks:callbacks,  dimensions:this.props.dimensions, siteData:this.props.siteData,readOnly:isReadOnly, location:this.props.location, match:this.props.match}, this.state );
        var props = Object.assign(
          {
            callbacks: callbacks,
            dimensions: this.props.dimensions,
            siteData: this.props.siteData,
            readOnly: isReadOnly,
            location: this.props.location,
            match: this.props.match,
            search: this.props.search,
            results: this.props.results,
            currentObject: this.props.currentObject,
            changeTime: this.props.changeTime,
            savedStatus: this.props.savedStatus,
            currentCheckedOutObject: this.props.currentCheckedOutObject,
          },
          {},
        );

        let pathname = this.props.location.pathname;

        if (pathname === "/enviddata"){
            return (
                <div>
                    {React.cloneElement(<DataHomeView />, props)}
                </div>
            )
        }

        if (pathname.startsWith("/enviddata/object")){
            return (
                <div>
                    {React.cloneElement(<DataObjectView />, props)}
                </div>
            )
        }

        return (
            <div>

            </div>
        )
    }
}

const DataHomeContainer = connect(mapStateToProps,mapDispatchToProps)(ReactTimeout(DataHomeBase));
export default withTranslation()(DataHomeContainer);







