import React from 'react';
import EnvidObjectIcon from "./EnvidObjectIcon"
import {Button} from 'react-bootstrap'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocketLaunch, faTrashArrowUp, faArchive} from "@fortawesome/pro-solid-svg-icons";

class EButton extends React.Component {
    render() {


        var icon = null;
        if (this.props.type === "cancel")
        {
            icon = <i className="fa fa-times"></i>
            //style={...style, color:"#ed5565"};
        }
        else if (this.props.type === "add")
        {
            icon = <i className="fa fa-plus" ></i>
        }
        else if (this.props.type === "standard")
        {
            icon = null
            //style={...style, color:"white"};
        }
        else if (this.props.type === "done")
        {
            icon = <i className="fa fa-check" ></i>
           // style = {...style, color:"#1ab394"};
        }
        else if (this.props.type === "selectall")
        {
            icon = <i className="fa fa-check-square-o" ></i>
        }
        else if (this.props.type === "selectnone")
        {
            icon = <i className="fa fa-square-o" ></i>
        }
        else if (this.props.type === "create")
        {
            icon = <i className="fa fa-plus" ></i>
        }
        else if (this.props.type === "remove")
        {
            icon = <i className="fa fa-times" ></i>
            //style={...style, color:"#ed5565"};
        }
        else if (this.props.type === "back")
        {
            icon = <i className="fa fa-arrow-left" ></i>
        }
        else if (this.props.type === "delete")
        {
            icon = <i className="fa fa-times" ></i>
            //style={...style, color:"#ed5565"};
        }
        else if (this.props.type === "edit")
        {
            icon = <i className="fa fa-pencil"></i>
        }
        else if (this.props.type === "link")
        {
            icon = <i className="fa fa-link"></i>
        }
        else if (this.props.type === "unlink")
        {
            icon = <i className="fa fa-unlink"></i>
        }
        else if (this.props.type === "report")
        {
            icon = <i className="fa fa-file-text-o"></i>
        }
        else if (this.props.type === "download")
        {
            icon = <i className="fa fa-download"></i>
        }
        else if (this.props.type === "popout")
        {
            icon = <i className="fa fa-expand"></i>
        }
        else if (this.props.type === "restore") {
            icon = <i><FontAwesomeIcon icon={faTrashArrowUp} /></i>;
        } else if (this.props.type === "publish") {
            icon = <i><FontAwesomeIcon icon={faRocketLaunch} /></i>;
        } else if (this.props.type === "archive") {
            icon = <i><FontAwesomeIcon icon={faArchive} /></i>;
        }
        else if (this.props.type != null)
        {
            icon = <span style={{marginRight:"3px"}}><EnvidObjectIcon type={this.props.type} showName={false} style={{color:"inherit"}}/></span>
        }

        var btnClass = "primary";
        var btnSize = null;
        if (this.props.isTable === true)
        {
            btnClass = "default";
            btnSize = "sm";
        }

        if (this.props.type === "delete")
        {
            btnClass = "danger";
        }
        else if (this.props.type === "done")
        {
            btnClass = "primary";
        }
        else if (this.props.type === "standard")
        {
            btnClass = "default";
        }
        else if (this.props.type === "outline")
        {
            btnClass = "outline";
        }

        if (this.props.type === "next" || this.props.type === "prev")
        {
            btnSize = "sm";
            icon = null;
        }
        if(this.props.ebSize !== null && this.props.ebSize !== undefined){
            btnSize = this.props.ebSize;
        }


        let classes = 'text-nowrap';
        if (!!this.props.className){
            classes += ' ' + this.props.className;
            btnClass = 'btn';
        }

        return (
            <Button className={classes} onClick={this.props.onClick}
                    disabled={this.props.disabled}
                    style={this.props.style}
                    variant={btnClass}
                    size={btnSize}>

                {icon}{icon!=null?<span>&nbsp;</span>:null}{this.props.children}
            </Button>
        )
    }
}
/*
<Button onClick={this.props.onClick}
        disabled={this.props.disabled}
        className={this.props.className}>
    {icon}&nbsp;{this.props.children}
</Button>
*/
export default EButton
