import { combineReducers, createStore} from 'redux'
import users from './users'
import projects from './projects'
import summary from './summary'
import reports from './reports'
import templates from './templates'
import compliance from './compliance'
import stakeholder from './stakeholder'
import comms from './comms'
import clients from './clients'
import session from "./session";
import search from "./search";
import manage from "./manage";

const ebaseApp = combineReducers({
    projects,
    users,
    reports,
    templates,
    summary,
    compliance,
    stakeholder,
    comms,
    clients,
    session,
    search,
    manage,
});

export default ebaseApp
