/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvidObjectSmallResult
 */
export interface EnvidObjectSmallResult {
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    objectId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    sharedId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    extra?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    type?: EnvidObjectSmallResultTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    coreType?: EnvidObjectSmallResultCoreTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    publishState?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnvidObjectSmallResult
     */
    published?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof EnvidObjectSmallResult
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EnvidObjectSmallResult
     */
    updated?: Date;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    updateUser?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    displayedObjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    displayedObjectType?: EnvidObjectSmallResultDisplayedObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    checkoutUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    checkoutUserName?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    checkoutUserDisplayName?: string;
    /**
     * 
     * @type {number}
     * @memberof EnvidObjectSmallResult
     */
    internalVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    versionId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    versionState?: EnvidObjectSmallResultVersionStateEnum;
    /**
     * 
     * @type {string}
     * @memberof EnvidObjectSmallResult
     */
    deleteState?: EnvidObjectSmallResultDeleteStateEnum;
}


/**
 * @export
 */
export const EnvidObjectSmallResultTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type EnvidObjectSmallResultTypeEnum = typeof EnvidObjectSmallResultTypeEnum[keyof typeof EnvidObjectSmallResultTypeEnum];

/**
 * @export
 */
export const EnvidObjectSmallResultCoreTypeEnum = {
    Project: 'Project',
    User: 'User',
    Client: 'Client'
} as const;
export type EnvidObjectSmallResultCoreTypeEnum = typeof EnvidObjectSmallResultCoreTypeEnum[keyof typeof EnvidObjectSmallResultCoreTypeEnum];

/**
 * @export
 */
export const EnvidObjectSmallResultDisplayedObjectTypeEnum = {
    Activity: 'Activity',
    ProjectCategory: 'ProjectCategory',
    Aspect: 'Aspect',
    Receptor: 'Receptor',
    Control: 'Control',
    TechnicalInput: 'TechnicalInput',
    EnvironmentalInput: 'EnvironmentalInput',
    Impact: 'Impact',
    Region: 'Region',
    Reference: 'Reference',
    ProjectActivityLink: 'ProjectActivityLink',
    AspectActivityLink: 'AspectActivityLink',
    ImpactReceptorLink: 'ImpactReceptorLink',
    ReceptorRegionLink: 'ReceptorRegionLink',
    ControlReceptorLink: 'ControlReceptorLink',
    ControlActivityLink: 'ControlActivityLink',
    ImpactType: 'ImpactType',
    ControlType: 'ControlType',
    ReceptorClassification: 'ReceptorClassification',
    ControlName: 'ControlName',
    ControlSource: 'ControlSource',
    ControlReceptorActivityLink: 'ControlReceptorActivityLink',
    Stakeholder: 'Stakeholder',
    StakeholderRegionLink: 'StakeholderRegionLink',
    ReceptorGisLink: 'ReceptorGisLink',
    Project: 'Project',
    ProjectMilestone: 'ProjectMilestone',
    ProjectRole: 'ProjectRole',
    ComplianceDefn: 'ComplianceDefn',
    ComplianceAction: 'ComplianceAction',
    ProjectStakeholderLink: 'ProjectStakeholderLink',
    StakeholderEvent: 'StakeholderEvent',
    StakeholderObjection: 'StakeholderObjection',
    StakeholderAction: 'StakeholderAction',
    StakeholderEventObjectionLink: 'StakeholderEventObjectionLink',
    GisWrapper: 'GisWrapper'
} as const;
export type EnvidObjectSmallResultDisplayedObjectTypeEnum = typeof EnvidObjectSmallResultDisplayedObjectTypeEnum[keyof typeof EnvidObjectSmallResultDisplayedObjectTypeEnum];

/**
 * @export
 */
export const EnvidObjectSmallResultVersionStateEnum = {
    Working: 'Working',
    Published: 'Published',
    Historical: 'Historical',
    Deleted: 'Deleted'
} as const;
export type EnvidObjectSmallResultVersionStateEnum = typeof EnvidObjectSmallResultVersionStateEnum[keyof typeof EnvidObjectSmallResultVersionStateEnum];

/**
 * @export
 */
export const EnvidObjectSmallResultDeleteStateEnum = {
    DeleteFlagged: 'DELETE_FLAGGED',
    Deleted: 'DELETED'
} as const;
export type EnvidObjectSmallResultDeleteStateEnum = typeof EnvidObjectSmallResultDeleteStateEnum[keyof typeof EnvidObjectSmallResultDeleteStateEnum];


/**
 * Check if a given object implements the EnvidObjectSmallResult interface.
 */
export function instanceOfEnvidObjectSmallResult(value: object): value is EnvidObjectSmallResult {
    return true;
}

export function EnvidObjectSmallResultFromJSON(json: any): EnvidObjectSmallResult {
    return EnvidObjectSmallResultFromJSONTyped(json, false);
}

export function EnvidObjectSmallResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvidObjectSmallResult {
    if (json == null) {
        return json;
    }
    return {
        
        'objectId': json['objectId'] == null ? undefined : json['objectId'],
        'sharedId': json['sharedId'] == null ? undefined : json['sharedId'],
        'name': json['name'] == null ? undefined : json['name'],
        'extra': json['extra'] == null ? undefined : json['extra'],
        'type': json['type'] == null ? undefined : json['type'],
        'coreType': json['core_type'] == null ? undefined : json['core_type'],
        'publishState': json['publishState'] == null ? undefined : json['publishState'],
        'published': json['published'] == null ? undefined : json['published'],
        'created': json['created'] == null ? undefined : (new Date(json['created'])),
        'updated': json['updated'] == null ? undefined : (new Date(json['updated'])),
        'author': json['author'] == null ? undefined : json['author'],
        'updateUser': json['update_user'] == null ? undefined : json['update_user'],
        'displayedObjectId': json['displayed_object_id'] == null ? undefined : json['displayed_object_id'],
        'displayedObjectType': json['displayed_object_type'] == null ? undefined : json['displayed_object_type'],
        'checkoutUserId': json['checkoutUserId'] == null ? undefined : json['checkoutUserId'],
        'checkoutUserName': json['checkoutUserName'] == null ? undefined : json['checkoutUserName'],
        'checkoutUserDisplayName': json['checkoutUserDisplayName'] == null ? undefined : json['checkoutUserDisplayName'],
        'internalVersion': json['internalVersion'] == null ? undefined : json['internalVersion'],
        'versionId': json['versionId'] == null ? undefined : json['versionId'],
        'version': json['version'] == null ? undefined : json['version'],
        'versionState': json['versionState'] == null ? undefined : json['versionState'],
        'deleteState': json['deleteState'] == null ? undefined : json['deleteState'],
    };
}

export function EnvidObjectSmallResultToJSON(value?: EnvidObjectSmallResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'objectId': value['objectId'],
        'sharedId': value['sharedId'],
        'name': value['name'],
        'extra': value['extra'],
        'type': value['type'],
        'core_type': value['coreType'],
        'publishState': value['publishState'],
        'published': value['published'],
        'created': value['created'] == null ? undefined : ((value['created']).toISOString()),
        'updated': value['updated'] == null ? undefined : ((value['updated']).toISOString()),
        'author': value['author'],
        'update_user': value['updateUser'],
        'displayed_object_id': value['displayedObjectId'],
        'displayed_object_type': value['displayedObjectType'],
        'checkoutUserId': value['checkoutUserId'],
        'checkoutUserName': value['checkoutUserName'],
        'checkoutUserDisplayName': value['checkoutUserDisplayName'],
        'internalVersion': value['internalVersion'],
        'versionId': value['versionId'],
        'version': value['version'],
        'versionState': value['versionState'],
        'deleteState': value['deleteState'],
    };
}

