import React, {useCallback, useEffect, useRef, useState} from 'react';
import ContainerHeader from '../components/common/ContainerHeader';
import PageWrapper from '../components/common/PageWrapper';
import EbasePanel from '../components/common/EbasePanel';
import Spinner from '../components/common/Spinner';
import EButton from '../components/common/EButton';
import NewObjectButton from '../components/common/NewObjectButton';
import EnvidObjectIcon from '../components/common/EnvidObjectIcon';
import {Table} from 'react-bootstrap';
import envid from '../utils/envid';
import {Trans, useTranslation, withTranslation} from 'react-i18next';
import Measure from 'react-measure';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faDownload, faUpload} from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import {CheckInButton} from "../components/common/CheckInButton";

library.add(faDownload, faUpload);

const DataHomeView = (props) => {

  // console.log('DataHomeView:: ',props);

  const [needsSearch, setNeedsSearch] = useState(true);
  const [tableHeight, setTableHeight] = useState(200);

  const scrollBoundaryRef = useRef(null);

  useEffect(() => {
    if (needsSearch) {
      props.callbacks.search();
      setNeedsSearch(false);
    }
  }, [needsSearch]);

  const scrollObserver = useCallback(node => {
    //console.log("NEW OBS ");
    const obs = new IntersectionObserver(entries => {
      entries.forEach(en => {
        //console.log("ADVANCE PAGE ", en);
        if (en.intersectionRatio > 0 || en.isIntersecting) {
          props.callbacks.addPage();
        }
      })
    });
    obs.observe(node);
    return () => {
      obs.disconnect();
    }
  }, [props.callbacks.search]);

  useEffect(()=>{
    if (scrollBoundaryRef.current) {
      scrollObserver(scrollBoundaryRef.current);
    }
  }, [scrollObserver, scrollBoundaryRef.current])

  const onSearchClick = (e) => {
    //console.log("search click");
    e.preventDefault();
    props.callbacks.search();
  };

  const searchValueChanged = (event) => {
    // console.log("search value: "+event.target.value);
    props.callbacks.updateTerm(event.target.value);
  };

  const onAddSelect = (type) => {
    props.callbacks.add(type);
  };

  const onTypeSelect = (event) => {
    //console.log('value: ' + event.target.value);
    props.callbacks.updateSearchType(event.target.value, true);
  };

  const onPubTypeSelect = (event) =>{
    // console.log('pub select',event.target.value);
    let val = event.target.value;
    if (val === 'all'){
      val = null;
    }
    props.callbacks.updatePublishState(val);
  }

  const onStateSelect = (event) => {
    props.callbacks.updateCheckedState(event.target.value);
  }

  const createdInfo = (objectInfo) => {
    var date = new Date(objectInfo.created);
    const show_date = date.toLocaleDateString();
    const author = objectInfo.author;
    var info = (<td><Trans i18nKey="data-created-by" show_date={show_date}
                           author={author}>Created {{show_date}}
        <br/> by {{author}}</Trans></td>

    );
    return info;
  };

  const updatedInfo = (objectInfo) => {

    var date = new Date(objectInfo.updated);
    const show_date = date.toLocaleDateString();
    const author = objectInfo.update_user;

    return (<td style={{minWidth: '200px'}}><Trans i18nKey="data-updated-by-user" show_date={show_date} author={author}>
          Last Updated {{show_date}} <br/> by {{author}}
    </Trans></td>
    );
  };

  const checkoutObjectClick = (event,info,location) => {
    event.stopPropagation();
    // console.log(' checkout object ', info.sharedId);
    props.callbacks.checkoutObject(info.sharedId,location);
  }

  const checkinObjectClick = (event,info,location) => {
    event.stopPropagation();
    // console.log(' checkin object ', info.sharedId);
    props.callbacks.checkinObject(info.sharedId,location);
  }

  const checkedInfo = (objectInfo) => {
    const checkedStatus = Boolean(objectInfo.checkoutUserId);
    const author = objectInfo.checkoutUserDisplayName;


    // const checkedInMsg = "This object is checked in and is available to be checked out. " + objectInfo.sharedId + " | v:" + objectInfo.internalVersion;
    // const checkedOutMsg = "This object was checked out by " + author + ". " + objectInfo.sharedId + " | v:" + objectInfo.internalVersion;
    const checkedInMsg = "This object is checked in and is available to be checked out. "
    const checkedOutMsg = "This object is checked out by " + author + "."
    const checkedOutSelfMsg = "This object is checked out to you."
    const forceCheckOut = <span> You can choose to&nbsp;<a className="text-primary" onClick={() => {}}>force a Check In</a></span>

    return (<td style={{minWidth: '200px'}}>{checkedStatus ? ((objectInfo.checkoutUserId === props.siteData.user.userId) ? checkedOutSelfMsg : checkedOutMsg) : checkedInMsg}</td>
    );
  }

  const searchEnter = (event) => {
    if (event.keyCode === 13) {
      props.callbacks.search();
    }
  };

  const {t} = useTranslation();

  let readOnly = props.readOnly;

  const things = [
    'ProjectCategory',
    'Activity',
    'Aspect',
    'Receptor',
    'EnvironmentalInput',
    'TechnicalInput',
    'Region',
    'Control',
    'Impact',
    'Reference',
    'Stakeholder'];

  const pubStates = envid.getPublishStateEntries();

  const options = things.map((type) => {
        return <option key={type} value={type}>{envid.getObjectName(type)}</option>;
      }
  );

  const pubOptions = pubStates.map(([key,value], i) => {
    return <option key={i + key} value={key}>{value.displayName ?? key}</option>
  });


  const addButton = <NewObjectButton onAddSelect={(ev) => onAddSelect(ev)}/>;

  let tbody = <tbody/>;

  const results = props.results;

  if (!!results && !!results.data) {

    const data = results.data;

    if (data.length === 0) {
      tbody = <tbody>
      <tr>
        <td>{t('No Results')}</td>
      </tr>
      </tbody>;
    } else {

      const rows = data.map((res,index) => {
          const ps = res.publishState;
          const psStyle = envid.getPublishTextStyle(ps);
          return (
            <tr key={res.objectId} onClick={() => props.callbacks.clickedObject(res.sharedId)} style={{cursor: 'pointer'}}>
              <td className="project-status">
                <span className="label text-nowrap" style={psStyle}>{t(envid.getPublishStateName(ps))}</span>
              </td>
              <td className="project-title">
                      <span style={{fontSize: 'normal', fontWeight: 'bold',}}>
                          {res.name}</span>
                <br/>
                <EnvidObjectIcon type={res.type} showName={true}/>
              </td>
              {/*{readOnly ? '' : createdInfo(res)}*/}
              {readOnly ? '' : updatedInfo(res)}
              {readOnly ? '' : checkedInfo(res)}
              {/*<td className="project-actions">*/}
              {/*    <EButton type={readOnly ? 'standard' : 'edit'} isTable={true}>*/}
              {/*        {readOnly ? t('View') : t('Edit')}*/}
              {/*    </EButton>*/}
              {/*</td>*/}
              <td>
                  {readOnly ? '' :  <CheckInButton checkInObj={res} checkinObjectClick={checkinObjectClick} checkoutObjectClick={checkoutObjectClick} locationFlag={"HomeView"} currUserId={props.siteData.user.userId} />}
              </td>
            </tr>);
        }
      );

      if (results.searching || results.more) {

        let key = results.searching ? 'searching-row' : 'more-row';
        let indicatorRow = <tr key={key} ref={scrollBoundaryRef}>
            <td colSpan="5" style={{borderWidth: 0}}>
              {/*{t('Loading') + '...'}*/}
              <Spinner style={{
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 'auto',
                marginRight: 'auto'
              }}/>
            </td>
          </tr>;
        rows.push(indicatorRow);
      }

      tbody = <tbody>{rows}</tbody>;
    }

  }

  let resultComponent = {};

  var style = {};
  style.overflow = 'auto';
  //style.height = props.dimensions.window.height -  props.dimensions.extras.height - 50;
  style.height = tableHeight + 'px';

  if (!props.results?.data) {
    resultComponent = <Spinner/>;
  } else {
    resultComponent =
      (
        <Measure bounds onResize={(dimensions) => {
          const height = props.dimensions.window.height -
            props.dimensions.footerHeight -
            dimensions.bounds.top - 50;
          setTableHeight(height);
        }}>
          {({measureRef}) => (
            <>
            <div ref={measureRef} className="project-list" style={style}>

              <Table className="table table-hover">
                {tbody}
              </Table>
              {props.results?.more === false && props.results?.data?.length > 8 &&
                <span>{props.results?.status ?? ''}</span>}
            </div>
            </>
          )}
        </Measure>
      );

  }

  return (
    <div>
      <ContainerHeader title={t('Environmental Impact Assessment Data')}
                       crumbs={['/', '/enviddata']}
                       rightComponent={readOnly ? null : addButton}
                       dimensions={props.dimensions}/>
      <PageWrapper dimensions={props.dimensions} noScroll={true}>
        <div className="row">
          <div className="col-sm-12">
            <EbasePanel>
              <div className={'row'}>
                <div className="col-sm-3 m-b-xs">
                  <select className="form-select inline"
                          value={props.search.type}
                          onChange={(ev) => onTypeSelect(ev)}>
                    <option key="all" value="">{t('All Objects')}</option>
                    {options}
                  </select>
                </div>
                <div className="col-sm-3 m-b-xs">
                  <select id="publishSelect"
                          className="form-select"
                          value={(props.search.publish == null || props.search.publish.length === 0) ? 'all' : props.search.publish[0]}
                          onChange={onPubTypeSelect}>
                    <option key="all" value="all">{t('All Publish Statuses')}</option>
                    {pubOptions}
                  </select>
                </div>
                <div className="col-sm-2 m-b-xs">
                  <select className="form-select inline"
                          value={props.search.checked}
                          onChange={(ev) => onStateSelect(ev)}>
                    <option key="all" value="ALL">{"All Checked States"}</option>
                    <option key="checkedIn" value="ONLY_CHECKED_IN">{"Checked In"}</option>
                    <option key="checkedOut" value="ONLY_CHECKED_OUT">{"Checked Out"}</option>
                    <option key="checkedOutMe" value="ONLY_USER_CHECKED_OUT">{"Checked Out By Me"}</option>
                  </select>
                </div>
                <div className="col-sm-4 m-b-xs">
                  <div className="input-group">
                    <input type="text" placeholder={t("Search")}
                           className="form-control m-b-xs"
                           value={props.search.term}
                           onChange={(ev) => searchValueChanged(ev)}
                           onKeyUp={(ev) => searchEnter(ev)}
                    />
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(ev) => onSearchClick(ev)}>
                      <i className="fa fa-search"/>
                    </button>
                    <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => props.callbacks.resetSearch(true)}>
                      {t('Reset')}
                    </button>
                  </div>
                </div>
              </div>
              <div className="m-b-sm">
                <span className="">{props.results?.status ?? ''}</span>
              </div>
              {resultComponent}
            </EbasePanel>
          </div>
        </div>
      </PageWrapper>
    </div>
  );

};

export default withTranslation()(DataHomeView);
