import React from 'react';
import {Tabs, Tab} from 'react-bootstrap'
import ContainerHeader from '../components/common/ContainerHeader'
import details from '../utils/details'
import sitedata from '../utils/sitedata'
import PageWrapper from "../components/common/PageWrapper"
import DataDetailsContainer from '../components/containers/DataDetailsContainer'
import DataLinkContainer from '../components/containers/DataLinkContainer'
import DataManageContainer from '../components/containers/DataManageContainer'
import DataPublishingContainer from '../components/containers/DataPublishingContainer'
import DataSummaryContainer from '../components/containers/DataSummaryContainer'
import DataReferencesContainer from '../components/containers/DataReferencesContainer'
import EnvidObjectIcon from "../components/common/EnvidObjectIcon"
import Spinner from "../components/common/Spinner"
import NewObjectButton from '../components/common/NewObjectButton'
import DataDetailsReadOnly from "./DataDetailsReadOnly";
import {withTranslation} from "react-i18next";
import envid from '../utils/envid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRocketLaunch} from "@fortawesome/pro-solid-svg-icons";
import {faHardHat} from "@fortawesome/pro-light-svg-icons";
import {CheckInButton} from "../components/common/CheckInButton"
import ChangeHistoryContainer from "../components/containers/ChangeHistoryContainer";

let versionInfo = (object) => {

    let publishState = object.publishingInfo.state;
    let versionState = object.versionDetails.state;
    let deleteState = object.deleteState;

    return <div className="position-absolute bg-white rounded border border-secondary-subtle row w-auto debug pt-1 pb-1" style={{height: '45px', top: '-15px', right: '10px' }}>
        <div className="col-sm" style={{marginRight: "2px"}}>
            <div className="col-sm text-end" style={{fontSize: "12px"}}>Status</div>
            <div className="col-sm text-end" style={{fontSize: "12px"}}>Version</div>
        </div>
        <div className="col-sm" style={{marginRight: "2px"}}>
            {!!deleteState ?
              <div className="eb-div-ctr rounded-pill text-nowrap"
                   style={{
                       marginBottom: "2px",
                       paddingLeft: "8px",
                       paddingRight: "8px",
                       minWidth: "fit-content",
                       height: "16px",
                       fontSize: "10px",
                       backgroundColor: envid.getPublishStateColour('Draft'),
                       color: envid.getPublishTextColour('Draft')
                   }}>
                  Deleted
              </div>
              :
              <div className="eb-div-ctr rounded-pill text-nowrap"
                   style={{
                       marginBottom: "2px",
                       paddingLeft: "8px",
                       paddingRight: "8px",
                       minWidth: "fit-content",
                       height: "16px",
                       fontSize: "10px",
                       backgroundColor: envid.getPublishStateColour(publishState),
                       color: envid.getPublishTextColour(publishState)
                   }}>
                  {envid.getPublishStateName(publishState)}
              </div>
            }

            <div className="eb-div-ctr rounded-pill"
                 style={{
                     paddingLeft: "8px",
                     paddingRight: "8px",
                     minWidth: "fit-content",
                     height: "16px",
                     fontSize: "10px",
                     backgroundColor: envid.getVersionStateColour(versionState),
                     color: envid.getVersionTextColour(versionState)
                 }}>
                {(versionState === "Published" ? <FontAwesomeIcon icon={faRocketLaunch} /> :  <FontAwesomeIcon icon={faHardHat} /> )} &nbsp;
                {versionState}
            </div>
        </div>
    </div>
}

/**
 * Provides the view of a data object.
 *
 * params
 *   tabName
 *   objectId
 *
 * props
 *   currentObject
 *   savedStatus
 *   dimensions
 *   sitedata
 *   callbacks
 *      - updateObject
 *      - updateSavedStatus
 *      - clickedObject
 *      - clickedTab
 *      - any the children use, which i will have to go through later
 */
class DataObjectView extends React.Component {

    constructor(props) {
        super(props);

    }

    formatDate(d)
    {
        var dt = new Date(d);
        return dt.toLocaleDateString();
    }

    checkoutObjectClick = (event,info, location) => {
        event.stopPropagation();
        // console.log(' checkout object ', info.sharedId);
        this.props.callbacks.checkoutObject(info.sharedId, location);
    }

    checkinObjectClick = (event,info,location) => {
        event.stopPropagation();
        // console.log(' checkin object ', info.sharedId);
        this.props.callbacks.checkinObject(info.sharedId, location);
    }


    render() {

        // console.log('DataObjectView::',this.props);
        // console.log('DataObjectView:: checkedout object',this.props.currentCheckedOutObject);
        const {t} = this.props;

        try {
            let params = sitedata.getParams(this.props);
            let objectId = params.objectId;
            let object = this.props.currentObject;
            let user = sitedata.getUser(this.props);

            if (object == null || object.object.sharedId !== objectId) {
                // ask the parent to load the object
                this.props.callbacks.loadObject(objectId);
                return(
                    <div>
                        <ContainerHeader dimensions={this.props.dimensions} />
                        <PageWrapper dimensions={this.props.dimensions} noScroll={true}>
                            <Spinner/>
                        </PageWrapper>
                    </div>
                );
            }

            let type = this.props.currentObject.object.type;
            let typeName = this.props.currentObject.object.type;
            //const title = "".concat(this.props.currentObject.type," - ",this.props.currentObject.details.name);
            //<EnvidObjectIcon type={typeName} showName={false} style={{marginRight:"10px", fontSize:'large', display:'inline-block'}}/>
            var title = (<span>{this.props.currentObject.object.details.name}</span>);
            if(type === "Reference"){
                title = (<span>{t("Reference")}</span>);
            }
            var subtitle = "";

            if (this.props.savedStatus === "unchanged") {
                subtitle = t("Last Saved") + " " + this.formatDate(this.props.currentObject.object.documentInfo.lastUpdateTime ?? this.props.currentObject.object.documentInfo.last_update_time);
            }
            else if (this.props.savedStatus === "saved") {
                subtitle = t("All Changes Saved");
            }
            else if (this.props.savedStatus === "saving") {
                subtitle = t("Saving") + "...";
            }
            else {
                subtitle = t("Saving") + "...";
            }

            subtitle = "(" + subtitle + ")";

            let publishState = this.props.currentObject.object.publishingInfo.state;
            let publishColour = envid.getPublishStateColour(publishState);

            //console.log("PUBLISH STATE TAB RENDER ", publishState);

            var publishing_title = <span><i className="fa fa-circle m-r-xs" style={{color: publishColour}}/>{t("Publishing")}</span>

            let last_crumb = (<EnvidObjectIcon type={typeName} showName={true}/>);
            let template = details.getObjectTemplate(type);
            if(this.props.readOnly){
                template = details.getObjectReadOnlyTemplate(type);
            }

            var content_style = {};
            content_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height - 70;
            content_style.overflow = "scroll";

            var link_content_style = {};
            link_content_style.height = this.props.dimensions.window.height - this.props.dimensions.extras.height - 70;
            link_content_style.overflow = "hidden";

            var tabs = [];
            for (var i in template) {
                let t = template[i];
                let title = t.tabName;
//<DataDetailsReadOnly fields={template[0].fields} currentObject={this.props.selectedData}/>
                var content = null;
                if (t.tabType === "det") {
                    content = <div className="ibox-content" style={content_style}>
                        {this.props.readOnly ?
                            <DataDetailsReadOnly fields={t.fields} currentObject={this.props.currentObject}/> :
                            <DataDetailsContainer siteData={this.props.siteData}
                                                  updateObject={this.props.callbacks.updateObject}
                                                  updateSavedStatus={this.props.callbacks.updateSavedStatus}
                                                  currentObject={this.props.currentObject}
                                                  currentCheckedOutObject={this.props.currentCheckedOutObject}
                                                  template={t}
                                                  fields={t.fields}/>
                        }
                    </div>

                } else if (t.tabType === "sum") {
                    content = <div className="ibox-content" style={content_style}>
                        <DataSummaryContainer currentObject={this.props.currentObject}
                                              siteData={this.props.siteData}
                                              callbacks={this.props.callbacks}
                        />
                    </div>

                } else if (t.tabType === "pub") {
                    content = <div className="ibox-content" style={content_style}>
                        <DataPublishingContainer currentObject={this.props.currentObject}
                                                 updateObject={this.props.callbacks.updateObject}
                                                 siteData={this.props.siteData}
                                                 updateSavedStatus={this.props.callbacks.updateSavedStatus}
                                                 checkinObjectClick={this.checkinObjectClick}
                        />
                    </div>

                    title = publishing_title;
                } else if (t.tabType === "ref") {
                    content = <div className="ibox-content" style={content_style}>
                            <DataReferencesContainer currentObject={this.props.currentObject}
                                                     currentUser={user}
                                                   updateObject={this.props.callbacks.updateObject}
                                                   updateSavedStatus={this.props.callbacks.updateSavedStatus}
                />
                </div>

                } else if (t.tabType === "link" || t.tabType === "link-1" || t.tabType === "link-2") {
                    content = <div className="ibox-content" style={link_content_style}>
                        <DataLinkContainer siteData={this.props.siteData}
                                           updateObject={this.props.callbacks.updateObject}
                                           updateSavedStatus={this.props.callbacks.updateSavedStatus}
                                           addObject={this.props.callbacks.add}
                                           currentObject={this.props.currentObject}
                                           currentCheckedOutObject={this.props.currentCheckedOutObject}
                                           template={t}
                                           changeTime={this.props.changeTime}
                                           changeLinkTime={this.props.changeLinkTime}
                                           height={content_style.height}
                        />
                    </div>

                } else if (t.tabType === "man") {
                    content = <div className="ibox-content" style={link_content_style}>
                        <DataManageContainer siteData={this.props.siteData}
                                           updateObject={this.props.callbacks.updateObject}
                                           updateSavedStatus={this.props.callbacks.updateSavedStatus}
                                           addObject={this.props.callbacks.add}
                                           currentObject={this.props.currentObject}
                                           currentUser={user}
                                           template={t}
                                           height={content_style.height}/>
                    </div>

                }else if (t.tabType === 'log'){
                    content = <div className="ibox-content" style={content_style}>
                        <ChangeHistoryContainer currentObject={this.props.currentObject}></ChangeHistoryContainer>
                    </div>
                } else {
                    content = <div>ERROR</div>
                }

                // tabs.push((
                //     <Tab key={t.tabType} eventKey={t.tabType} title={title}>
                //         <TabCheck eventKey={t.tabType} activeKey={params.tabName}>
                //             {content}
                //         </TabCheck>
                //     </Tab>
                // ));
                tabs.push((
                  <Tab key={t.tabType} eventKey={t.tabType} title={title} mountOnEnter={true} unmountOnExit={['log','pub'].includes(t.tabType)}>
                      {content}
                  </Tab>
                ));

            }

            const addButton = <NewObjectButton onAddSelect={this.props.callbacks.add}/>;
            const checkButton= <CheckInButton checkInObj={this.props.currentObject.object} checkinObjectClick={this.checkinObjectClick} checkoutObjectClick={this.checkoutObjectClick} locationFlag={"ObjectView"} currUserId={this.props.siteData.user.userId} />

            return (
                <div>
                    <ContainerHeader title={title}
                                     subtitle={subtitle}
                                     crumbs={['/', '/enviddata', last_crumb]}
                                     rightComponent={this.props.readOnly ? null : addButton}
                                     dimensions={this.props.dimensions}
                                     checkButton={this.props.readOnly || !!this.props.currentObject.object.deleteState ? null : checkButton}
                    />
                    <PageWrapper dimensions={this.props.dimensions} noScroll={true}>
                        {/*<pre>current checked out: {this.props.currentCheckedOutObject?.object.sharedId} | {this.props.currentCheckedOutObject?.object.objectId} - {this.props.currentCheckedOutObject?.object.details?.name}</pre>*/}
                        <div className="tabs-container position-relative" >
                            {versionInfo(this.props.currentObject.object)}
                            <Tabs className="" id="object-tabs" activeKey={params.tabName} onSelect={this.props.callbacks.clickedTab}>
                                {tabs}
                            </Tabs>
                        </div>
                    </PageWrapper>
                </div>
            )
        } catch (e)
        {
            console.log(e);
            return <div>{t("Caught Exception")}</div>
        }
    }

}

export default withTranslation()(DataObjectView)
