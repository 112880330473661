/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EbaseResultEnvidObjectResult,
  EbaseResultMapStringNumber,
  EbaseResultSummaryResult,
  SummaryRequest,
} from '../models/index';
import {
    EbaseResultEnvidObjectResultFromJSON,
    EbaseResultEnvidObjectResultToJSON,
    EbaseResultMapStringNumberFromJSON,
    EbaseResultMapStringNumberToJSON,
    EbaseResultSummaryResultFromJSON,
    EbaseResultSummaryResultToJSON,
    SummaryRequestFromJSON,
    SummaryRequestToJSON,
} from '../models/index';

export interface GetEnvidDataRequest {
    summaryRequest?: SummaryRequest;
}

export interface LoadSummaryRequest {
    summaryRequest?: SummaryRequest;
}

/**
 * EbaseDataSummaryWSApi - interface
 * 
 * @export
 * @interface EbaseDataSummaryWSApiInterface
 */
export interface EbaseDataSummaryWSApiInterface {
    /**
     * 
     * @param {SummaryRequest} [summaryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseDataSummaryWSApiInterface
     */
    getEnvidDataRaw(requestParameters: GetEnvidDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>>;

    /**
     */
    getEnvidData(summaryRequest?: SummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseDataSummaryWSApiInterface
     */
    loadLibraryPublishSummaryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultMapStringNumber>>;

    /**
     */
    loadLibraryPublishSummary(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultMapStringNumber>;

    /**
     * 
     * @param {SummaryRequest} [summaryRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EbaseDataSummaryWSApiInterface
     */
    loadSummaryRaw(requestParameters: LoadSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSummaryResult>>;

    /**
     */
    loadSummary(summaryRequest?: SummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSummaryResult>;

}

/**
 * 
 */
export class EbaseDataSummaryWSApi extends runtime.BaseAPI implements EbaseDataSummaryWSApiInterface {

    /**
     */
    async getEnvidDataRaw(requestParameters: GetEnvidDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultEnvidObjectResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/summary/read-summary-object`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SummaryRequestToJSON(requestParameters['summaryRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultEnvidObjectResultFromJSON(jsonValue));
    }

    /**
     */
    async getEnvidData(summaryRequest?: SummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultEnvidObjectResult> {
        const response = await this.getEnvidDataRaw({ summaryRequest: summaryRequest }, initOverrides);
        return await response.value();
    }

    /**
     */
    async loadLibraryPublishSummaryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultMapStringNumber>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/summary/read-summary-libstatus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultMapStringNumberFromJSON(jsonValue));
    }

    /**
     */
    async loadLibraryPublishSummary(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultMapStringNumber> {
        const response = await this.loadLibraryPublishSummaryRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async loadSummaryRaw(requestParameters: LoadSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EbaseResultSummaryResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/summary/read-summary-datasummary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SummaryRequestToJSON(requestParameters['summaryRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EbaseResultSummaryResultFromJSON(jsonValue));
    }

    /**
     */
    async loadSummary(summaryRequest?: SummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EbaseResultSummaryResult> {
        const response = await this.loadSummaryRaw({ summaryRequest: summaryRequest }, initOverrides);
        return await response.value();
    }

}
