/**
 * Created by lewellyn on 2/6/22.
 */
import React from 'react';
import Spinner from "../components/common/Spinner";

const ScriptHelpView = (props) => {

    // console.log('ScriptHelpView::',props)

    if (props.loading === true){
        return <div><Spinner/></div>
    }

    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: props.helpData}}></div>
        </div>
    );


}


export default ScriptHelpView
