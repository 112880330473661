/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotifyTemplateResult
 */
export interface NotifyTemplateResult {
    /**
     * 
     * @type {string}
     * @memberof NotifyTemplateResult
     */
    templateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyTemplateResult
     */
    templateType?: NotifyTemplateResultTemplateTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotifyTemplateResult
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyTemplateResult
     */
    plainContent?: string;
    /**
     * 
     * @type {string}
     * @memberof NotifyTemplateResult
     */
    htmlContent?: string;
}


/**
 * @export
 */
export const NotifyTemplateResultTemplateTypeEnum = {
    Email: 'Email'
} as const;
export type NotifyTemplateResultTemplateTypeEnum = typeof NotifyTemplateResultTemplateTypeEnum[keyof typeof NotifyTemplateResultTemplateTypeEnum];


/**
 * Check if a given object implements the NotifyTemplateResult interface.
 */
export function instanceOfNotifyTemplateResult(value: object): value is NotifyTemplateResult {
    return true;
}

export function NotifyTemplateResultFromJSON(json: any): NotifyTemplateResult {
    return NotifyTemplateResultFromJSONTyped(json, false);
}

export function NotifyTemplateResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotifyTemplateResult {
    if (json == null) {
        return json;
    }
    return {
        
        'templateCode': json['templateCode'] == null ? undefined : json['templateCode'],
        'templateType': json['templateType'] == null ? undefined : json['templateType'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'plainContent': json['plainContent'] == null ? undefined : json['plainContent'],
        'htmlContent': json['htmlContent'] == null ? undefined : json['htmlContent'],
    };
}

export function NotifyTemplateResultToJSON(value?: NotifyTemplateResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'templateCode': value['templateCode'],
        'templateType': value['templateType'],
        'subject': value['subject'],
        'plainContent': value['plainContent'],
        'htmlContent': value['htmlContent'],
    };
}

