import React from 'react';
import Form from 'react-bootstrap/Form';

interface EBSwitchProps {
    id?: string;
    label?: string;
    checked?: boolean;
    disabled?: boolean;
    // size?: 'sm' | 'md' | 'lg'
    onChange?: (checked: boolean) => void;
}


interface EBSwitchState {
    isChecked: boolean;
}

class EBSwitch extends React.Component<EBSwitchProps, EBSwitchState> {
    constructor(props: EBSwitchProps) {
        super(props);

        this.state = {
            isChecked: props.checked || false, // default to false if not provided
        };
    }

    handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        this.setState({ isChecked });

        // Call the passed onChange handler, if provided
        if (this.props.onChange) {
            this.props.onChange(isChecked);
        }
    };

    render() {

        // default md is 1.5
        // let scale = 'scale(1.5) translate(22px, 2px)';
        // if (this.props.size === 'sm') {
        //     scale = 'scale(1)';
        // }
        // if (this.props.size === 'lg') {
        //     scale = 'scale(2) translate(33px, 2.5px)';;
        // }
        // const transform = {transform: scale};

        return (
            <Form>
                <Form.Check
                    type="switch"
                    id={this.props.id ?? ""}
                    label={this.props.label ?? ""}
                    checked={this.state.isChecked}
                    disabled={this.props.disabled}
                    onChange={this.handleSwitchChange}
                />
            </Form>
        );
    }
}

export default EBSwitch;
