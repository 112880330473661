import React, {Fragment}  from 'react'
import SessionManager from "../../utils/SessionManager";
import { connect } from 'react-redux';
import Spinner from "../common/Spinner";
import { withRouter } from 'react-router-dom';
import i18n from "../../i18n";

class SessionCheck extends React.Component{

    constructor(props) {
        super(props);

        this.checkSession = this.checkSession.bind(this);
        this.doneCheckSession = this.doneCheckSession.bind(this);

        this.state = {initCheckSession: false};

        this.lastPath = null;

    }

    checkSession(){
        SessionManager.shared().checkSession(this.doneCheckSession);
    }

    doneCheckSession(data){
        console.log("SessionCheck:: doneCheckSession ",data);

        if (data == null){
            //checksession failed.
            SessionManager.shared().goto('/');
            this.setState({initCheckSession: true});
        }
        else
        {
            if (data.dataset != null && data.dataset.languageCode != null)
            {
                // we might need to set the language here
                i18n.changeLanguage(data.dataset.languageCode);
            }

            if (SessionManager.shared().isLite()){
                SessionManager.shared().goto('/impactsummary');
            }else {

                let paths = ['/libraries', '/select', '/login', '/'];
                if (!paths.includes(this.lastPath)) {
                    //takes us to the path that started load.
                    // console.log("SessionCheck: will got to last path ",this.lastPath);
                    SessionManager.shared().goto(this.lastPath);
                } else {

                    if (data.dataset == null) {
                        // console.log("SessionCheck: will got to /libraries ",this.lastPath);
                        SessionManager.shared().goto("/libraries")
                    } else {
                        // console.log("SessionCheck: will got to /select ",this.lastPath);
                        SessionManager.shared().goto("/select")
                    }
                }
            }

            this.setState({initCheckSession: true});
        }
    }

    componentDidMount() {
        // console.log("SessionCheck:: didMount ",this.props.location);
        this.lastPath = this.props.location.pathname;
        SessionManager.shared().setHistory(this.props.history);

        this.checkSession();
    }

    render() {

        // console.log("SessionCheck:: Render ",this.props);

        if (this.props.isLoading || !this.state.initCheckSession){
            return (
                <div>
                    <Spinner/>
                </div>
            )
        }

        return (
            <Fragment>
                {this.props.children}
            </Fragment>
        )
    }

}

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) => {

    return {
        isLoading: state.session.isLoading,
        isAuthenticated: state.session.isAuthenticated,
    };

};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        sessionFailed : () => {
            dispatch({ type: 'AUTH_LOGOUT' });
        }
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SessionCheck));
