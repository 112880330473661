import React, {useEffect, useState} from 'react';
import ScriptHelpView from "../../views/ScriptHelpView";
import ebase from '../../utils/ajax'
import SessionManager from "../../utils/SessionManager";

const ScriptHelpContainer = (props) => {

    const [loading, setLoading] = useState(false);
    const [helpData, setHelpData] = useState();


    const getScriptHelp = () => {
        // console.log('load script help');
        setLoading(true);
        ebase.ajax({
            type:'GET',
            url:'/ebase/help/read-enviddata-help?code='+props.helpCode,
            success: (result) => {
                // console.log('get script-admin result ',result);
                setLoading(false)
                if (result.success === true){
                    setHelpData(result.data.formattedHelp.text)
                }else{
                    setHelpData("<h2 class='text-danger'>Problem loading help - "+result.error+"</h2>")
                }
            },
            error: (err) => {
                setLoading(false)
                setHelpData("<h2 class='text-danger'>Error loading help</h2>")
                SessionManager.shared().showLoadError("Error loading help", err,false, null)
            }
        })
    }

    useEffect( () => {
        getScriptHelp();
    },[])

    // return (
    //     [errorData]
    // )

    return (
        <ScriptHelpView loading={loading} helpData={helpData}/>
    )
}



export default ScriptHelpContainer;
