import React, {Fragment}  from 'react'
import {Switch, Route, Redirect, useLocation} from 'react-router-dom';
import LiteImpactSummaryView from "../../views/LiteImpactSummaryView";
import SessionCheck from "../login/SessionCheck";
import LoginContainer from "../login/LoginContainer";
import LiteLayout from "../layouts/LiteLayout";
import SessionManager from "../../utils/SessionManager";
import NavigationLayout from "../layouts/NavigationLayout";
import ClientLibraryHomeContainer from "../containers/ClientLibraryHomeContainer";
import SelectView from "../../views/SelectView";
import DataHomeContainer from "../containers/DataHomeContainer";
import UsersHomeContainer from "../containers/UsersHomeContainer";
import ProjectsHomeContainer from "../containers/ProjectsHomeContainer";
import {TemplatesAdminContainer} from '../containers/TemplatesAdminContainer';
import ControlsCatalogueContainer from "../containers/ControlsCatalogueContainer";
import KnowledgeBaseContainer from "../containers/KnowledgeBaseContainer";
import StakeholderSummaryContainer from "../containers/StakeholderSummaryContainer";
import ImpactSummaryView from "../../views/ImpactSummaryView";
import RegionSummaryView from "../../views/RegionSummaryView";
import ReportHomeContainer from "../containers/ReportHomeContainer";
import ClientManageContainer from "../containers/ClientManageContainer";
import ControlsSummaryContainer from "../containers/ControlsSummaryContainer";
import ComplianceHomeContainer from "../containers/ComplianceHomeContainer";
import StakeholderHomeContainer from "../containers/StakeholderHomeContainer";
import {TemplateEditContainer} from '../containers/TemplateEditContainer';
import TemplatesAssignView from '../../views/TemplatesAssignView';
import ReceptorSummaryView from '../../views/ReceptorSummaryView';
import PublishingHomeContainer from "../containers/PublishingHomeContainer";
import EmailTemplateContainer from "../containers/EmailTemplateContainer";
import EmailTemplateView from "../../views/EmailTemplateView";

const Routes = (props) => {

    const location = useLocation();

    // console.log("location ",location);


    if (SessionManager.shared().isLite()){
        return (
            <Fragment>
                <SessionCheck>
                    <Switch location={location}>
                        <Redirect exact from="/" to="/login" />
                        <Route exact path={"/login"} component={LoginContainer} />
                        <Route path={"/impactsummary"}>
                            <LiteLayout>
                                <LiteImpactSummaryView/>
                            </LiteLayout>
                        </Route>
                    </Switch>
                </SessionCheck>
            </Fragment>
        );
    }


    return (

        <Fragment>
            <SessionCheck>
                <Switch location={location}>
                    <Redirect exact from="/" to="/login" />
                    <Route exact path={"/login"} component={LoginContainer} />
                    <Route path={["/select","/client","/clients","/libraries","/publishing","/projects","/templates","/users","/enviddata","/controlscatalogue","/knowledgebase","/stakeholdersummary","/impactsummary","/regionsummary","/receptorsummary","/webmap","/report-defn","/report-controlssummary","/compliance","/stakeholder","/email-templates"]}>
                        <NavigationLayout>
                            <Switch location={location}>
                            <Route path="/libraries" component={ClientLibraryHomeContainer}/>
                            <Route path="/publishing" component={PublishingHomeContainer}/>
                            <Route exact path="/client/:clientId" component={ClientManageContainer} />
                            <Route exact path="/client/:clientId/templates/:dataSetId" component={TemplatesAssignView} />
                            <Route path="/select" component={SelectView} />
                            <Route exact path={["/users","/users/manage/:userId"]} component={UsersHomeContainer} />
                            <Route exact path={["/projects","/projects/project/:objectId/:tabName"]} component={ProjectsHomeContainer} />
                            <Route exact={true} path={["/report-defn/:projectId/summary/:tabIndex","/report-defn/:projectId/task/:task"]} component={ReportHomeContainer}/>
                            <Route exact={true} path={["/report-controlssummary/:projectId/summary/:tabIndex","/report-controlssummary/:projectId/task"]} component={ControlsSummaryContainer}/>
                            <Route exact={true} path={["/compliance/:projectId/home/:tabIndex","/compliance/:projectId/manage/:filter/:controlId/:tabIndex"]} component={ComplianceHomeContainer}/>
                            <Route exact={true} path={["/stakeholder/:projectId/home/:tabIndex","/stakeholder/:projectId/manage/:filter/:stakeholderId/:tabIndex","/stakeholder/:projectId/comms/:listType/:filter/:objectId","/stakeholder/:projectId/objections","/stakeholder/:projectId/actions"]} component={StakeholderHomeContainer}/>
                            <Route exact path={["/enviddata","/enviddata/object/:objectId/:tabName"]} component={DataHomeContainer} />
                            <Route path="/controlscatalogue" component={ControlsCatalogueContainer}/>
                            <Route path="/templates/manage/:template_id" component={TemplateEditContainer}/>
                            <Route path="/templates" component={TemplatesAdminContainer}/>
                            <Route path="/knowledgebase" component={KnowledgeBaseContainer}/>
                            <Route exact path="/stakeholdersummary" component={StakeholderSummaryContainer}/>
                            <Route path="/impactsummary" component={ImpactSummaryView}/>
                            <Route path="/regionsummary" component={RegionSummaryView}/>
                            <Route path="/receptorsummary" component={ReceptorSummaryView}/>
                            <Route exact path="/email-templates" component={EmailTemplateContainer}/>
                            </Switch>
                        </NavigationLayout>
                    </Route>
                </Switch>
            </SessionCheck>
        </Fragment>
    )



};


export default Routes;
