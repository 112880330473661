/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UpdateFieldResult } from './UpdateFieldResult';
import {
    UpdateFieldResultFromJSON,
    UpdateFieldResultFromJSONTyped,
    UpdateFieldResultToJSON,
} from './UpdateFieldResult';

/**
 * 
 * @export
 * @interface EbaseResultArrayListUpdateFieldResult
 */
export interface EbaseResultArrayListUpdateFieldResult {
    /**
     * 
     * @type {boolean}
     * @memberof EbaseResultArrayListUpdateFieldResult
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EbaseResultArrayListUpdateFieldResult
     */
    error?: string;
    /**
     * 
     * @type {Array<UpdateFieldResult>}
     * @memberof EbaseResultArrayListUpdateFieldResult
     */
    data?: Array<UpdateFieldResult>;
}

/**
 * Check if a given object implements the EbaseResultArrayListUpdateFieldResult interface.
 */
export function instanceOfEbaseResultArrayListUpdateFieldResult(value: object): value is EbaseResultArrayListUpdateFieldResult {
    return true;
}

export function EbaseResultArrayListUpdateFieldResultFromJSON(json: any): EbaseResultArrayListUpdateFieldResult {
    return EbaseResultArrayListUpdateFieldResultFromJSONTyped(json, false);
}

export function EbaseResultArrayListUpdateFieldResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EbaseResultArrayListUpdateFieldResult {
    if (json == null) {
        return json;
    }
    return {
        
        'success': json['success'] == null ? undefined : json['success'],
        'error': json['error'] == null ? undefined : json['error'],
        'data': json['data'] == null ? undefined : ((json['data'] as Array<any>).map(UpdateFieldResultFromJSON)),
    };
}

export function EbaseResultArrayListUpdateFieldResultToJSON(value?: EbaseResultArrayListUpdateFieldResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'success': value['success'],
        'error': value['error'],
        'data': value['data'] == null ? undefined : ((value['data'] as Array<any>).map(UpdateFieldResultToJSON)),
    };
}

