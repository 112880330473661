/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnvidObjectSmallResult } from './EnvidObjectSmallResult';
import {
    EnvidObjectSmallResultFromJSON,
    EnvidObjectSmallResultFromJSONTyped,
    EnvidObjectSmallResultToJSON,
} from './EnvidObjectSmallResult';

/**
 * 
 * @export
 * @interface DeleteCheckResult
 */
export interface DeleteCheckResult {
    /**
     * 
     * @type {boolean}
     * @memberof DeleteCheckResult
     */
    canDelete?: boolean;
    /**
     * 
     * @type {Array<EnvidObjectSmallResult>}
     * @memberof DeleteCheckResult
     */
    linked?: Array<EnvidObjectSmallResult>;
}

/**
 * Check if a given object implements the DeleteCheckResult interface.
 */
export function instanceOfDeleteCheckResult(value: object): value is DeleteCheckResult {
    return true;
}

export function DeleteCheckResultFromJSON(json: any): DeleteCheckResult {
    return DeleteCheckResultFromJSONTyped(json, false);
}

export function DeleteCheckResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteCheckResult {
    if (json == null) {
        return json;
    }
    return {
        
        'canDelete': json['canDelete'] == null ? undefined : json['canDelete'],
        'linked': json['linked'] == null ? undefined : ((json['linked'] as Array<any>).map(EnvidObjectSmallResultFromJSON)),
    };
}

export function DeleteCheckResultToJSON(value?: DeleteCheckResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'canDelete': value['canDelete'],
        'linked': value['linked'] == null ? undefined : ((value['linked'] as Array<any>).map(EnvidObjectSmallResultToJSON)),
    };
}

