/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EnvidObjectSmallResult } from './EnvidObjectSmallResult';
import {
    EnvidObjectSmallResultFromJSON,
    EnvidObjectSmallResultFromJSONTyped,
    EnvidObjectSmallResultToJSON,
} from './EnvidObjectSmallResult';
import type { VersionDetailsSmallResult } from './VersionDetailsSmallResult';
import {
    VersionDetailsSmallResultFromJSON,
    VersionDetailsSmallResultFromJSONTyped,
    VersionDetailsSmallResultToJSON,
} from './VersionDetailsSmallResult';

/**
 * 
 * @export
 * @interface DatasetPublishingInfoResult
 */
export interface DatasetPublishingInfoResult {
    /**
     * 
     * @type {Array<EnvidObjectSmallResult>}
     * @memberof DatasetPublishingInfoResult
     */
    changedObjects?: Array<EnvidObjectSmallResult>;
    /**
     * 
     * @type {Array<VersionDetailsSmallResult>}
     * @memberof DatasetPublishingInfoResult
     */
    versionHistory?: Array<VersionDetailsSmallResult>;
    /**
     * 
     * @type {VersionDetailsSmallResult}
     * @memberof DatasetPublishingInfoResult
     */
    publishedVersion?: VersionDetailsSmallResult;
}

/**
 * Check if a given object implements the DatasetPublishingInfoResult interface.
 */
export function instanceOfDatasetPublishingInfoResult(value: object): value is DatasetPublishingInfoResult {
    return true;
}

export function DatasetPublishingInfoResultFromJSON(json: any): DatasetPublishingInfoResult {
    return DatasetPublishingInfoResultFromJSONTyped(json, false);
}

export function DatasetPublishingInfoResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatasetPublishingInfoResult {
    if (json == null) {
        return json;
    }
    return {
        
        'changedObjects': json['changedObjects'] == null ? undefined : ((json['changedObjects'] as Array<any>).map(EnvidObjectSmallResultFromJSON)),
        'versionHistory': json['versionHistory'] == null ? undefined : ((json['versionHistory'] as Array<any>).map(VersionDetailsSmallResultFromJSON)),
        'publishedVersion': json['publishedVersion'] == null ? undefined : VersionDetailsSmallResultFromJSON(json['publishedVersion']),
    };
}

export function DatasetPublishingInfoResultToJSON(value?: DatasetPublishingInfoResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'changedObjects': value['changedObjects'] == null ? undefined : ((value['changedObjects'] as Array<any>).map(EnvidObjectSmallResultToJSON)),
        'versionHistory': value['versionHistory'] == null ? undefined : ((value['versionHistory'] as Array<any>).map(VersionDetailsSmallResultToJSON)),
        'publishedVersion': VersionDetailsSmallResultToJSON(value['publishedVersion']),
    };
}

