import React, {ReactElement, useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import DiffViewerContainer from "./DiffViewerContainer";
import styles from './DiffModal.module.scss'

export interface DiffModalProps {
  show: boolean;
  sharedId: string;
  onHide: () => void;
  customName?: string;
}

function DiffModal(props: DiffModalProps): ReactElement {
  const [showDifference, setShowDifference] = useState<boolean>(true);

  const closeModal = () => {
      setShowDifference(true);
      props.onHide();
  }

  let radioSwitch =
      <div className="w-50 d-flex gap-4 justify-content-start">
        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" className="btn-check" name="btnradio" id="diffViewRadio" autoComplete="off"
                 onClick={() => setShowDifference(true)} checked={showDifference} readOnly/>
          <label className="btn btn-outline-secondary" htmlFor="diffViewRadio">Difference View</label>

          <input type="radio" className="btn-check" name="btnradio" id="rendViewRadio" autoComplete="off"
                 onClick={() => setShowDifference(false)} checked={!showDifference} readOnly/>
          <label className="btn btn-outline-secondary" htmlFor="rendViewRadio">Standard View</label>
        </div>
      </div>;

  let diffInfo =
      <div className="w-50 d-flex gap-4 justify-content-center">
        <div className="d-flex gap-1 align-items-center">
          <div className="highlight-changed" style={{width: '24px', height: '24px'}}></div>
          <div style={{color: "black"}}>New/Changed content</div>
        </div>
        <div className="d-flex gap-1 align-items-center">
          <div className="highlight-deleted" style={{width: '24px', height: '24px'}}></div>
          <div style={{color: "black"}}>Deleted Content</div>
        </div>
      </div>;

  return <Modal show={props.show} dialogClassName={`'modal-xl rounded-2' ${styles.diffModalParent}`}
                onHide={closeModal}>
    <Modal.Header closeButton className="bg-muted">
      {radioSwitch}
      {showDifference ? diffInfo : <div className="w-50"></div>}
    </Modal.Header>
    <Modal.Body className="bg-muted p-3">
      {/*<p>Shared ID: {props.sharedId}</p>*/}
      <DiffViewerContainer sharedId={props.sharedId} showDifference={showDifference} customName={props.customName}/>
    </Modal.Body>
    <Modal.Footer className="bg-muted p-1">
      <Button onClick={closeModal}>Close</Button>
    </Modal.Footer>
  </Modal>
}

export default DiffModal;
