import React from 'react';
import ProjectRoles from '../../views/ProjectRoles'

import ebase from '../../utils/ajax'
import { connect } from 'react-redux'

import {
    loadProjectObjectsDone, loadProjectObjectsStart, loadProjectObjectsError,
    updateProjectObjectField
} from '../../redux/actions/actions'
import {startProjectObjectEdit}  from '../../redux/actions/actions'
import {newProjectObjectDone, loadSummaryObjectDone, newProjectObjectError} from '../../redux/actions/actions'
import SessionManager from "../../utils/SessionManager";

const object_type = "ProjectRole";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>
{
    let parent = state.projects.ProjectRole;
    if (parent == null)
    {
        parent = {isLoading:false};
    }
    //console.log(parent);
    return {
        isLoading: parent.isLoading,
        editing_id: parent.editing_id,
        roles: parent.objects,
        users: (parent.objectLists==null)?[]:parent.objectLists.User,
        project:state.projects.currentObject.object
    }
}

const newObject = function(props, dispatch)
{
    let object_type = "ProjectRole";
    let project_id = props.currentObject.object.sharedId;

    //dispatch(loadProjectObjectsStart(object_type));

    // console.log("New Object");
    // console.log(props);
    ebase.ajax({
        url:"/ebase/core/write-project-newobject",
        data:{project_id:project_id, type:object_type},
        success:(result) => {
            console.log(result);
            if (result.success) {
                dispatch(newProjectObjectDone(object_type, result.data));
            } else {
                dispatch(newProjectObjectError(object_type, result.error));
                props.showLoadError("Error Loading Roles", result);
            }
        },
        error :(result) => {
            dispatch(newProjectObjectError(object_type, result.error));
            props.showLoadError("Error Loading Roles", result);
        }

    });


}
const loadObjects = function(props, dispatch)
{

    let object_type = "ProjectRole";

    let project_id = props.currentObject.object.sharedId;
    dispatch(loadProjectObjectsStart(object_type));

    //  console.log(props);
    ebase.ajax({
        url:"/ebase/core/read-project-objects",
        data:{project_id : project_id, type:object_type},
        success:(result) => {
            if (result.success) {
                dispatch(loadProjectObjectsDone(object_type, result.data));
            } else {
                dispatch(loadProjectObjectsError(object_type, result.error));
                props.showLoadError("Error Loading Roles", result);
            }
        },
        error :(result) => {
            dispatch(loadProjectObjectsError(object_type, result.error));
            props.showLoadError("Error Loading Roles", result);
        }

    });

}

const mapDispatchToProps = (dispatch, ownProps) =>
{
    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {
        loadObjects : () => {

            loadObjects(ownProps, dispatch);
        },

        newObject : () => {
            newObject(ownProps, dispatch);
        },

        startEdit : (object_id) => {
            dispatch(startProjectObjectEdit(object_type, object_id));
        },

        endEdit : (object_id) => {
            dispatch(startProjectObjectEdit(null));
        },

        updateField : (object_id, fieldName, fieldValue) =>
        {
            dispatch(updateProjectObjectField(object_type, object_id, fieldName, fieldValue));
        },


        saveObject : (object) => {
            let displayed_object_id = object.objectId;

            ebase.ajax({
                url:"/ebase/core/write-project-saveobject",
                data:{objectId : displayed_object_id},
                success:(result) => {
                    if (result.success) {
                        dispatch(loadSummaryObjectDone(displayed_object_id, result.data));
                    } else {
                        ownProps.showLoadError("Error Loading Summary", result);
                    }
                },
                error :(result) => {
                    ownProps.showLoadError("Error Loading Summary", result);
                }

            });


        }


    }
}

class ProjectRoleVw extends React.Component{
    render() {


        return  <div>
            {React.cloneElement(<ProjectRoles/>, this.props)}
        </div>

    }
    componentDidMount()
    {
        this.props.loadObjects();
    }
};

const ProjectRoleContainer = connect(mapStateToProps, mapDispatchToProps)(ProjectRoleVw)

export default ProjectRoleContainer
