import React from 'react';

export type ColumnDef = {
  key: string;
  display: string | React.ReactNode;
};

export type EBCommonTableProps<T> = {
  rowData: T[];
  columnDefinitions: ColumnDef[];
  renderRow: (item: T, columns: ColumnDef[]) => React.ReactNode;
  renderHeader?: (columns: ColumnDef[]) => React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

const combineClassNames = (...classNames: (string | undefined)[]): string => {
  return classNames.filter(Boolean).join(' ');
};

const EBCommonTable = <T,>({
  rowData,
  columnDefinitions,
  renderRow,
  renderHeader,
  style,
  className,
}: EBCommonTableProps<T>): React.ReactElement => {

  const tableClasses = 'table table-striped';

  return (
    <table style={style} className={combineClassNames(tableClasses, className)}>
      {renderHeader && <thead>{renderHeader(columnDefinitions)}</thead>}
      <tbody>
        {rowData.map((item, index) => (
          <React.Fragment key={index}>{renderRow(item, columnDefinitions)}</React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default EBCommonTable;
