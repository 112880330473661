/**
 * Created by lewellyn on 18/10/18.
 */
import React from 'react';
import StakeholderSummaryView from '../../views/StakeholderSummaryView';
import { connect } from 'react-redux';
import ebase from "../../utils/ajax";
import {loadSummaryObjectDone, loadSummaryObjectStart, loadTreeDone, loadTreeStart} from "../../redux/actions/actions";
import SessionManager from "../../utils/SessionManager";

/**
 * @param {EbaseAppState} state - The current state.
 */
const mapStateToProps = (state, ownProps) =>{

    return {

        isLoading: state.summary.isLoading,
        children: state.summary.children,
        objects: state.summary.objects,
        loadState: state.summary.loadState,
        selectedIsLoading:state.summary.selectedIsLoading,
        selectedData:state.summary.selectedData,
        dimensions:state.session.dimensions,
    };
};

const mapDispatchToProps = (dispatch, ownProps) =>{

    ownProps.showLoadError = SessionManager.shared().showLoadError;
    return {


        loadSummary : () => {
            // console.log("STakeholder summary:: load summary");

            dispatch(loadTreeStart());
            ebase.ajax({
                url:"/ebase/stakeholder/read-stakeholder-summary",
                data:{objectId : null, summaryType:"Stakeholder"},
                success:(result) => {
                    if (result.success) {
                        dispatch(loadTreeDone(result.data));
                    } else {
                        ownProps.showLoadError("Error Loading Summary", result);
                    }
                },
                error :(result) => {
                    ownProps.showLoadError("Error Loading Summary", result);
                }

            });
        },

        loadObject : (object,parent) => {
            if (!object) {
                return;
            }
            let object_id = object.sharedId;
            let displayed_object_id = (object.displayed_object_id == null ) ? object.sharedId : object.displayed_object_id;
            dispatch(loadSummaryObjectStart(object_id, displayed_object_id));

            //if there's a parent object send object id up as <parent_id>::<object_id>
            //used to get link info
            var objectInfo = displayed_object_id;
            if(parent !== null && parent !== undefined){
                objectInfo = parent.sharedId + "::"+displayed_object_id;
            }
            console.log("objectInfo: " + objectInfo);

            ebase.ajax({
                url:"/ebase/stakeholder/read-stakeholder-summaryobject",
                data:{objectId : objectInfo},
                success:(result) => {
                    if (result.success) {
                        dispatch(loadSummaryObjectDone(displayed_object_id, result.data));
                    } else {
                        ownProps.showLoadError("Error Loading Object", result);
                    }
                },
                error :(result) => {
                    ownProps.showLoadError("Error Loading Object", result);
                }
            });
        }
    };
};

class StakeholderSmryView extends React.Component{

    render() {

        return (

            <div>
                {React.cloneElement(<StakeholderSummaryView/>,this.props)}
            </div>
        );
    }

    componentWillMount()
    {

        this.props.loadSummary();


    }

};

const StakeholderSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(StakeholderSmryView);
export default StakeholderSummaryContainer
