/* tslint:disable */
/* eslint-disable */
/**
 * eBase REST api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    position?: string;
}

/**
 * Check if a given object implements the Contact interface.
 */
export function instanceOfContact(value: object): value is Contact {
    return true;
}

export function ContactFromJSON(json: any): Contact {
    return ContactFromJSONTyped(json, false);
}

export function ContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contact {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'email': json['email'] == null ? undefined : json['email'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'address': json['address'] == null ? undefined : json['address'],
        'position': json['position'] == null ? undefined : json['position'],
    };
}

export function ContactToJSON(value?: Contact | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'email': value['email'],
        'phone': value['phone'],
        'address': value['address'],
        'position': value['position'],
    };
}

